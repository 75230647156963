import cn from 'classnames';
import { useFormik } from 'formik';
import pick from 'lodash/pick';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdditionalSummaryForm from '../../components/forms/AdditionalSummaryForm/AdditionalSummaryForm';
import {
  DELAYED_SHIPPING_EXPIRATION,
  INITIAL_SUMMARY_FORM_STATE,
  INTERNAL_NOTES,
  SEND_ON_HOLD,
} from '../../constants/bucket';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import { addBucketValue } from '../../store/actions/bucket';
import { selectIsDelayedShipping, selectIsPYGEnabled, selectPYGCampaignsList } from '../../store/selectors/bucket';
import { ISummaryFormFields, TEngagementCandidate } from '../../types/bucket';
import { IOneLink } from '../../types/oneLink';
import { IReport } from '../../types/reports';
import { isDigitalBox } from '../../utils/campaigns';

import styles from './AdditionalSummaryContainer.module.scss';

interface IProps {
  className?: string;
  engagement: TEngagementCandidate | IReport | IOneLink | null | undefined;
  viewMode?: boolean;
  isEditAllowed?: boolean;
  isOneLink?: boolean;
  sendId?: string;
}

const AdditionalSummaryContainer = ({ className, viewMode, engagement, isEditAllowed, isOneLink, sendId }: IProps) => {
  const dispatch = useDispatch();
  const isPYGFlow = useSelector(selectIsPYGEnabled);
  const pygCampaigns = useSelector(selectPYGCampaignsList);
  const isDelayedShipping = useSelector(selectIsDelayedShipping);

  const formValues = React.useMemo(
    () => pick(engagement, [BUSINESS_REASON_ID, INTERNAL_NOTES, SEND_ON_HOLD, DELAYED_SHIPPING_EXPIRATION]),
    [engagement],
  );

  const form = useFormik<ISummaryFormFields>({
    initialValues: formValues ? formValues : INITIAL_SUMMARY_FORM_STATE,
    enableReinitialize: true,
    // tslint:disable-next-line:no-empty
    onSubmit: () => {},
  });

  const handleFieldChange = React.useCallback(
    (name: keyof ISummaryFormFields, value: string | boolean) => {
      dispatch(addBucketValue({ [name]: value }));
    },
    [dispatch],
  );

  const isNotDigitalSend = React.useMemo(() => {
    if (isPYGFlow) {
      const items = pygCampaigns?.map((campaign) => campaign.items).flat();
      return !isDigitalBox({ items });
    }
    return !isDigitalBox(engagement);
  }, [engagement, isPYGFlow, pygCampaigns]);

  return (
    <div className={cn(className, styles.additionalSummaryContainer)}>
      <AdditionalSummaryForm
        form={form}
        onChange={handleFieldChange}
        readOnly={viewMode}
        onHoldIsAllowed={isNotDigitalSend}
        isEditAllowed={isEditAllowed}
        isOneLink={isOneLink}
        sendId={sendId}
        isDelayedShipping={isDelayedShipping}
      />
    </div>
  );
};

export default AdditionalSummaryContainer;
