import * as yup from 'yup';
import {
  EmailTemplateTypesEnum,
  TCollectiveEmailTemplate,
  TCollectiveNoteTemplate,
  TemplateFilterTypesEnum,
  TemplateTypesEnum,
} from '../types/templates';
import { NO_DEPARTMENT } from './organizations';
import { URL_VARS } from './routing';
import {
  RECEIVER_COMPANY_NAME,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  SENDER_COMPANY_NAME,
  SENDER_EMAIL,
  SENDER_FIRST_NAME,
  SENDER_LAST_NAME,
  SENDER_PHONE,
} from './shipping';
import { SIGNATURE } from './signature';

export const TEMPLATE_LABEL = 'Template';

export const ADD_NOTE_FORM = 'add-note-form';
export const ADD_EMAIL_FORM = 'add-email-form';

export const MESSAGE = 'note_message';
export const NOTE_TEMPLATE_ID = 'note_template_id';
export const NO_TEMPLATE = 'no_template';

export const EMAIL_MESSAGE = 'email_message';
export const EMAIL_SUBJECT = 'email_subject';
export const EMAIL_TEMPLATE_ID = 'email_template_id';
export const RECEIVER_EMAIL = 'receiver_email';

export const DIGITAL_GIFT_NAMES = 'digital_gift_names';
export const DEPARTMENT_IDS = 'department_ids';

export const TITLE = 'title';
export const BODY = 'body';
export const SUBJECT = 'subject';
export const TYPE = 'type';
export const IS_DEFAULT = 'is_default';
export const ITEM_ID = 'item_id';
export const ORG_ID = 'org_id';
export const TEMPLATE_TYPE = 'templateType';

export const INITIAL_ADD_NOTE_FORM_STATE = {
  [MESSAGE]: '',
  [NOTE_TEMPLATE_ID]: '',
};

export const INITIAL_ADD_EMAIL_FORM_STATE = {
  [EMAIL_TEMPLATE_ID]: '',
  [EMAIL_MESSAGE]: '',
  [EMAIL_SUBJECT]: '',
  [RECEIVER_EMAIL]: '',
};

export const EMPTY_NOTE_TEMPLATE = {
  item_id: NO_TEMPLATE,
  body: '',
  title: 'No Template',
};

export const EMPTY_EMAIL_TEMPLATE = {
  item_id: NO_TEMPLATE,
  body: '',
  subject: 'No template',
};

export const MAX_NEW_NOTE_LENGTH = 300;
export const MAX_NOTE_LENGTH = 250;
export const MAX_BULK_NOTE_LENGTH = 300;
export const MAX_ONE_LINK_NOTE_LENGTH = 300;
export const WARNING_RANGE = 20;

export const ADD_NOTE_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    [MESSAGE]: yup.string().max(MAX_NOTE_LENGTH),
  })
  .required();

export const ADD_BULK_NOTE_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    [MESSAGE]: yup.string().max(MAX_BULK_NOTE_LENGTH),
  })
  .required();

export const ADD_ONE_LINK_NOTE_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    [MESSAGE]: yup.string().max(MAX_ONE_LINK_NOTE_LENGTH),
  })
  .required();

export const EMAIL_MESSAGE_REGEX = /\[\bDelayedShippingURLs\]|\[DigitalGiftURLs\b\]/g;
export const EMAIL_DIGITAL_GIFT_MESSAGE_REGEX = /\[DigitalGiftURLs]/g;
export const EMAIL_DELAYED_SHIPPING_MESSAGE_REGEX = /\[DelayedShippingURLs]/g;

export const NO_REQUIRED_PHYSICAL_TAG_ERROR_MESSAGE = 'Your Email text must contain [DelayedShippingURLs] token';
export const NO_REQUIRED_DIGITAL_TAG_ERROR_MESSAGE = 'Your Email text must contain [DigitalGiftURLs] token';

export const emailMessageValidation = (isDigital: boolean) =>
  yup
    .string()
    .required()
    .matches(
      EMAIL_MESSAGE_REGEX,
      isDigital ? NO_REQUIRED_DIGITAL_TAG_ERROR_MESSAGE : NO_REQUIRED_PHYSICAL_TAG_ERROR_MESSAGE,
    );

export const ADD_EMAIL_VALIDATION_SCHEMA = (isDigital: boolean) =>
  yup
    .object()
    .shape({
      [EMAIL_MESSAGE]: emailMessageValidation(isDigital),
      [RECEIVER_EMAIL]: yup.string().email().required(),
      [EMAIL_SUBJECT]: yup.string().required(),
    })
    .required();

export const ADD_BULK_EMAIL_VALIDATION_SCHEMA = (isDigital: boolean) =>
  yup
    .object()
    .shape({
      [EMAIL_MESSAGE]: emailMessageValidation(isDigital),
      [EMAIL_SUBJECT]: yup.string().required(),
    })
    .required();

export const ADD_NOTE_TEMPLATE_VALIDATION_SCHEMA = yup.object().shape({
  [BODY]: yup.string().max(MAX_NEW_NOTE_LENGTH).required(),
  [DEPARTMENT_IDS]: yup.array().of(yup.string()).required(),
  [TITLE]: yup.string().required(),
  [IS_DEFAULT]: yup.boolean(),
  [TEMPLATE_TYPE]: yup.string().oneOf([TemplateTypesEnum.Note, TemplateTypesEnum.Email]).required(),
});

export const ADD_EMAIL_TEMPLATE_VALIDATION_SCHEMA = yup.object().shape({
  [BODY]: yup
    .string()
    .required()
    .when(TYPE, {
      is: EmailTemplateTypesEnum.DELAYED_SHIPPING,
      then: (sc) => sc.matches(EMAIL_DELAYED_SHIPPING_MESSAGE_REGEX),
      otherwise: (sc) => sc.matches(EMAIL_DIGITAL_GIFT_MESSAGE_REGEX),
    }),
  [DEPARTMENT_IDS]: yup.array().of(yup.string()).required(),
  [SUBJECT]: yup.string().required(),
  [TYPE]: yup.string().oneOf([EmailTemplateTypesEnum.DELAYED_SHIPPING, EmailTemplateTypesEnum.DIGITAL_GIFT]).required(),
  [IS_DEFAULT]: yup.boolean(),
  [TEMPLATE_TYPE]: yup.string().oneOf([TemplateTypesEnum.Note, TemplateTypesEnum.Email]).required(),
});

export const INITIAL_TEMPLATE_NOTE_FORM_STATE: Partial<TCollectiveNoteTemplate> = {
  [BODY]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [TITLE]: '',
  [IS_DEFAULT]: false,
  [TEMPLATE_TYPE]: TemplateTypesEnum.Note,
};

export const INITIAL_TEMPLATE_EMAIL_FORM_STATE: Partial<TCollectiveEmailTemplate> = {
  [BODY]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [SUBJECT]: '',
  [TYPE]: EmailTemplateTypesEnum.DELAYED_SHIPPING,
  [IS_DEFAULT]: false,
  [TEMPLATE_TYPE]: TemplateTypesEnum.Email,
};

export const TEMPLATE_TOKENS_REGEX = /\[.*?\]/gm;

export const TEMPLATE_TOKENS = {
  ReceiverFirstName: 'Receiver.FirstName',
  ReceiverLastName: 'Receiver.LastName',
  ReceiverCompanyName: 'Receiver.CompanyName',
  SenderFirstName: 'Sender.FirstName',
  SenderLastName: 'Sender.LastName',
  SenderCompanyName: 'Sender.CompanyName',
  SenderEmail: 'Sender.Email',
  SenderPhone: 'Sender.Phone',
  SenderSignature: 'Sender.Signature',
  DigitalGiftNames: 'DigitalGiftNames',
  DigitalGiftURLs: 'DigitalGiftURLs',
  DelayedShippingURLs: 'DelayedShippingURLs',
};

// This order is important
export const TEMPLATE_TOKEN_LIST_FOR_TEMPLATE_CREATING = [
  TEMPLATE_TOKENS.SenderFirstName,
  TEMPLATE_TOKENS.SenderLastName,
  TEMPLATE_TOKENS.SenderPhone,
];

export const TOKENS_MAP = {
  [TEMPLATE_TOKENS.ReceiverFirstName]: RECEIVER_FIRST_NAME,
  [TEMPLATE_TOKENS.ReceiverLastName]: RECEIVER_LAST_NAME,
  [TEMPLATE_TOKENS.ReceiverCompanyName]: RECEIVER_COMPANY_NAME,
  [TEMPLATE_TOKENS.SenderFirstName]: SENDER_FIRST_NAME,
  [TEMPLATE_TOKENS.SenderLastName]: SENDER_LAST_NAME,
  [TEMPLATE_TOKENS.SenderCompanyName]: SENDER_COMPANY_NAME,
  [TEMPLATE_TOKENS.SenderEmail]: SENDER_EMAIL,
  [TEMPLATE_TOKENS.SenderPhone]: SENDER_PHONE,
  [TEMPLATE_TOKENS.SenderSignature]: SIGNATURE,
  [TEMPLATE_TOKENS.DigitalGiftNames]: DIGITAL_GIFT_NAMES,
};

export const TEMPLATE_FILTERS = [
  {
    label: 'All',
    tabId: TemplateFilterTypesEnum.All,
  },
  {
    label: 'Custom message',
    tabId: TemplateFilterTypesEnum.Note,
  },
  {
    label: 'Email',
    tabId: TemplateFilterTypesEnum.Email,
  },
];

export const TemplateSuccessToastMessages = {
  [URL_VARS.NEW]: 'New template was added',
  [URL_VARS.EDIT]: 'Template was edited',
};

export const EMAIL_TEMPLATE_TYPES = [
  {
    label: 'Delayed Shipping',
    value: EmailTemplateTypesEnum.DELAYED_SHIPPING,
  },
  {
    label: 'Digital Gift',
    value: EmailTemplateTypesEnum.DIGITAL_GIFT,
  },
];
