import { IS_RECEIVER_ADDRESS_FIXED } from '../constants/shipping';
import { IPromiseCallbacks } from './redux';
import { FilterTypesEnum, IApiError } from './shell';

import { ICampaignItem, ICampaignSummary, IFetchCampaignSummariesResponsePayload } from './campaigns';
import { ICommonAddress, ShippingOptionsEnum } from './shipping';

//#region OneLink enums

export enum OneLinkStatusEnum {
  Active = 'active',
  NotActive = 'not_active',
}

//#endregion

export interface IOneLinkCreatedSend {
  uid: string;
  first_name: string;
  last_name: string;
  order_id: string;
  tracking_url: string | null;
  digital_gift_url: string | null;
}

export interface IOneLink {
  created_at: string;
  org_id: string;
  pre_created_engagement_id: string;
  department_id: string;
  uid: string;
  shipping_option: ShippingOptionsEnum;
  business_reason_id: string;
  internal_notes: string;
  status: OneLinkStatusEnum;
  disable_shipment_tracking: boolean;
  disable_email_notifications: boolean;
  created_by: string;
  expiration_date: string;
  url: string;
  pick_campaign_ids: string[] | null;
  items: ICampaignItem[] | null;
  user_id: string;
  sender_address1: string;
  sender_address2: string;
  sender_city: string;
  sender_company_name: string;
  sender_country: string;
  sender_email: string;
  sender_first_name: string;
  sender_last_name: string;
  sender_phone: string;
  sender_province: string;
  sender_state: string;
  sender_zip: string;
  send_on_behalf_the_user: {
    avatar_url: string;
    uid: string;
    firstname: string;
    lastname: string;
    email: string;
  };
  note_message: string;
  email_message: string;
  email_subject: string;
  email_template_id: string | null;
  created_sends: IOneLinkCreatedSend[];
  on_hold_until?: string;
  password?: string;
  allowed_domain?: string;
  max_submit_count: number;
  enabled_2fa?: boolean;
  recipients_count?: number | null;
  recipients?: undefined;
  receiver_fixed_address?: ICommonAddress;
  [IS_RECEIVER_ADDRESS_FIXED]: boolean;
  org_address_ids?: string[];
}

export interface IOneLinkWithCampaignSummaries extends IOneLink {
  pick_campaigns?: ICampaignSummary[];
}

export interface IOneLinksState {
  [OneLinkStatusEnum.Active]: { [key: string]: IOneLinkWithCampaignSummaries } | null;
  [OneLinkStatusEnum.NotActive]: { [key: string]: IOneLinkWithCampaignSummaries } | null;
  isLoading: boolean;
  isLoadingSingle: boolean;
  error: IApiError | null;
  isFinal: boolean;
  totalCount: number;
  recipients?: IOneLinkRecipient[] | null;
}

export interface IOneLinkSelectorMetadata {
  filter?: FilterTypesEnum;
  status: OneLinkStatusEnum;
}

export interface IOneLinkSelectorMetadataWithUserId extends IOneLinkSelectorMetadata {
  userId?: string;
}

//#region Request interfaces

export interface IFetchOneLinkSendsRequestPayload extends IPromiseCallbacks {
  page_size: number;
  page: number;
  status?: OneLinkStatusEnum; // Enum. Not required. All if empty
  isOrgSearch?: boolean;
  isDeptSearch?: boolean;
  department_ids?: string[];
  range?: {
    from: string;
    to: string;
  };
}

export interface IFetchOneLinkSendsSuccessPayload {
  one_link_sends: IOneLink[] | null;
  status: OneLinkStatusEnum;
  total_count: number;
  enabled_2fa?: boolean;
  recipients_count?: number;
}

export interface IFetchOneLinkByIdRequestPayload extends IPromiseCallbacks {
  itemId: string;
  status: OneLinkStatusEnum;
  enabled_2fa?: boolean;
  recipients_count?: number;
}

export interface IFetchOneLinkByIdResponsePayload {
  one_link_send: IOneLink;
}

export interface IFetchOneLinkByIdSuccessPayload extends IFetchOneLinkByIdResponsePayload {
  status: OneLinkStatusEnum;
}

export interface IPopulateOneLinkCampaignSummariesRequestPayload {
  campaign_ids: string[];
  itemId: string;
  status: OneLinkStatusEnum;
}

export interface IPopulateOneLinkCampaignSummariesResponsePayload extends IFetchCampaignSummariesResponsePayload {
  itemId: string;
  status: OneLinkStatusEnum;
}

export interface IDisableOneLinkSendRequestPayload extends IPromiseCallbacks {
  uid: string;
}

export interface IDisableOneLinkSendSuccessPayload {
  uid: string;
}

export interface IOneLinkRecipient {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  is_submitted?: boolean;
}

export interface IOneLinkRecipientWithId extends IOneLinkRecipient {
  uid: string;
  parent_uid: string;
  authorized_at: string;
  parent_id: string;
}

export type TOneLinkRecipient = IOneLinkRecipient &
  // ExistingOneLinkRecipient
  (| {
        uid?: string;
        id?: never;
        error?: never;
      }
    // NewOneLinkRecipient
    | {
        id?: string;
        uid?: never;
        error?: never;
      }
    // InvalidOneLinkRecipient
    | {
        error?: string;
        id?: never;
        uid?: never;
      }
  );

export interface IOneLinkRecipients {
  successfulRecipients?: TOneLinkRecipient[];
  failedRecipients?: TOneLinkRecipient[];
}

export interface IAddRecipientsPayload {
  valid_recipients: IOneLinkRecipientWithId[];
  invalid_recipients: IOneLinkRecipientWithId[];
}

export interface IGetOneLinkRecipientsRequestPayload extends IPromiseCallbacks {
  one_link_id: string;
}

export interface IGetOneLinkRecipientsSuccessPayload {
  data: IOneLinkRecipient[] | null;
}

//#endregion
