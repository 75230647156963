import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as InformationIcon } from '../../../assets/images/icon-information.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/icon-warning-round.svg';
import { InfoMessage } from '../../../components';
import { OrgAddressFilterTypesEnum, ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { selectAddressBook } from '../../../store/selectors/addressBook';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  selectValue: ShippingFlowSelectorOptionsEnum;
  addresses?: string[];
}

const Message: React.FC<IProps> = ({ selectValue, addresses }) => {
  const addressBook = useSelector(selectAddressBook);

  const recipientDecides = selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecides;
  const recipientDecidesFromList =
    selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList && addresses && addresses.length >= 2;
  const notEnoughAddressesSelected =
    selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList && (!addresses || addresses.length < 2);
  const isFixedSelectorValue = selectValue === ShippingFlowSelectorOptionsEnum.Fixed;

  const hasNonActiveAddresses = useMemo(() => {
    return addresses?.some((addressUID) => {
      const orgAddress = addressBook?.find(({ uid }) => uid === addressUID);
      return orgAddress?.status === OrgAddressFilterTypesEnum.NotActive;
    });
  }, [addresses, addressBook]);

  return useMemo(() => {
    switch (true) {
      case hasNonActiveAddresses:
        return (
          <InfoMessage icon={<WarningIcon />} text={'Please remove inactive addresses.'} className={styles.warning} />
        );
      case recipientDecides:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to enter address manually or choose one of the selected addresses.'}
          />
        );
      case notEnoughAddressesSelected:
        return (
          <InfoMessage
            icon={<WarningIcon />}
            text={'Please select at least two addresses to proceed.'}
            className={styles.warning}
          />
        );
      case recipientDecidesFromList:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={'Recipient will have an option to choose one of the selected addresses.'}
          />
        );
      case isFixedSelectorValue:
        return (
          <InfoMessage
            icon={<InformationIcon />}
            text={"The package will be delivered to the specified address. The recipient won't be able to change it."}
          />
        );
      default:
        return null;
    }
  }, [
    hasNonActiveAddresses,
    recipientDecides,
    notEnoughAddressesSelected,
    recipientDecidesFromList,
    isFixedSelectorValue,
  ]);
};

export default Message;
