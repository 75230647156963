import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BookIcon } from '../../../assets/images/icon-book.svg';

import {
  ActionButton,
  AddressBookSidebar,
  AddressList,
  LabelWithEditButton,
  Loader,
  Selector,
  SingleAddress,
  Toggle,
} from '../../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { ORG_ADDRESS_IDS } from '../../../constants/campaigns';
import {
  INITIAL_SHIPPING_ADDRESS_FORM_STATE,
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_ADDRESS,
  SHIP_ORDER_STATUS,
} from '../../../constants/shipping';
import { fetchAddressBookRequest } from '../../../store/actions/addressBook';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { BUTTON_BUTTON } from '../../../types/forms';
import { ISelectorValue, UISizeEnum } from '../../../types/shell';
import { IBulkPayloadProps, ICommonAddress, IShippingDetails } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';
import { AddressBookSidebarContainer, ReceiverFixedAddressSidebarContainer } from '../../index';
import { Message } from '../common';
import { SidebarModesEnum } from '../types';
import { getSelectOptions } from '../utils';
import { ActionTypesEnum, changeAB, changeFixedAddress, changeSelect, TAction } from './actions';
import { getInitialState, IState, reducer } from './reducer';

import styles from '../ShippingDetailsContainer.module.scss';

export interface IShippingValueProps {
  [SHIP_ORDER_STATUS]?: string;
  [ORG_ADDRESS_IDS]?: (string | undefined)[];
  [IS_RECEIVER_ADDRESS_FIXED]?: boolean;
  [RECEIVER_ADDRESS]?: {
    address1?: string;
    address2?: string;
    city?: string;
    country?: string;
    state?: string;
    zip?: string;
  };
}

interface IProps {
  value: IShippingValueProps | null;
  isReadOnly?: boolean;
  onSubmit: (v: IBulkPayloadProps) => Promise<void>;
  onEdit: () => void;
  isEditable?: boolean;
  isForcedDS?: boolean;
  onCancel?: () => void;
  isLoading?: boolean;
  isAvailableDS?: boolean;
}

const ReportFlow: React.FC<IProps> = ({
  value,
  onSubmit,
  onEdit,
  isReadOnly,
  isLoading,
  onCancel,
  isForcedDS,
  isEditable,
  isAvailableDS,
}) => {
  const reduxDispatch = useDispatch();
  const addressBook = useSelector(selectAddressBook);

  const initialState = useMemo(() => getInitialState(value as IShippingDetails), [value]);

  const [{ isDelayedShipping, isAddressFixed, addresses, receiverAddress, selectorValue }, dispatch] = React.useReducer<
    React.Reducer<IState, TAction>
  >(reducer, initialState);

  const [sidebarMode, setSidebarMode] = React.useState<SidebarModesEnum>(SidebarModesEnum.None);

  const isFixedSelectorValue = useMemo(() => {
    return selectorValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectorValue]);

  const isRecipientDecidesFromListSelectorValue = useMemo(
    () => selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList,
    [selectorValue],
  );

  const isRecipientDecidesSelectorValue = useMemo(
    () => selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecides,
    [selectorValue],
  );

  const handleSelectInputChange = React.useCallback(({ value: selected }: ISelectorValue) => {
    dispatch(changeSelect(selected as ShippingFlowSelectorOptionsEnum));
  }, []);

  const options = useMemo(
    () => getSelectOptions(isDelayedShipping!, isDelayedShipping && !isForcedDS),
    [isForcedDS, isDelayedShipping],
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectorValue),
    [options, selectorValue],
  );

  const isEmptyRecipientAddress = useMemo(
    () => isObjectEmpty(receiverAddress) || (receiverAddress && isObjectEmpty(receiverAddress)),
    [receiverAddress],
  );

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const handleResetSingleAddress = React.useCallback(() => {
    return dispatch(changeFixedAddress(INITIAL_SHIPPING_ADDRESS_FORM_STATE));
  }, []);

  const handleSubmit = React.useCallback(async () => {
    const payload = {
      is_receiver_address_fixed: isAddressFixed,
      org_address_ids: addresses,
      receiver_address: receiverAddress,
    };

    await onSubmit(payload);
  }, [onSubmit, isAddressFixed, addresses, receiverAddress]);

  const actionButtonTitle = useMemo(() => {
    const hasAddresses = addresses && addresses.length !== 0;

    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientAddress ? 'Edit' : 'Enter'} Fixed Address`;
      case isRecipientDecidesFromListSelectorValue || isRecipientDecidesSelectorValue:
        return `${hasAddresses ? 'Edit' : 'Select'} Address Book Options`;
      default:
        return 'Select Address Book Option';
    }
  }, [
    isEmptyRecipientAddress,
    isRecipientDecidesSelectorValue,
    isFixedSelectorValue,
    isRecipientDecidesFromListSelectorValue,
    addresses,
  ]);

  const isAddressLabelShown = React.useMemo(
    () =>
      !isReadOnly && !isRecipientDecidesFromListSelectorValue && (!isEmptyRecipientAddress || isEmptyRecipientAddress),
    [isEmptyRecipientAddress, isReadOnly, isRecipientDecidesFromListSelectorValue],
  );

  const isShippingValid = useMemo(() => {
    const invalidFixedAddress = isFixedSelectorValue && isEmptyRecipientAddress;
    const invalidRDFLO = isRecipientDecidesFromListSelectorValue && (!addresses || addresses?.length < 2);

    return !(invalidFixedAddress || invalidRDFLO);
  }, [isEmptyRecipientAddress, isFixedSelectorValue, isRecipientDecidesFromListSelectorValue, addresses]);

  const addressList = useMemo(() => {
    return addressBook?.filter((address) => addresses?.includes(address.uid));
  }, [addressBook, addresses]);

  const isAddressListPresent = useMemo(() => addressList && addressList.length > 0, [addressList]);

  const handleReset = React.useCallback(() => {
    dispatch({ type: ActionTypesEnum.reset, payload: initialState });
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, initialState]);

  const handleEdit = useMemo(() => {
    return !isAvailableDS ? () => setSidebarMode(SidebarModesEnum.FixedAddress) : onEdit;
  }, [isAvailableDS, onEdit]);

  const handleSubmitReceiversAddress = useCallback(
    (addr: ICommonAddress) => {
      if (!isAvailableDS) {
        onSubmit({ is_receiver_address_fixed: isAddressFixed, receiver_address: addr }).then(() => {
          dispatch(changeFixedAddress(addr));
        });
      } else {
        dispatch(changeFixedAddress(addr));
      }
    },
    [dispatch, onSubmit, isAddressFixed, isAvailableDS],
  );

  useEffect(() => {
    reduxDispatch(fetchAddressBookRequest());
  }, [reduxDispatch]);

  return (
    <>
      <div className={cn(styles.container, styles.nested)}>
        <Loader className={styles.loader} isLoading={isLoading} />
        {isEditable && isReadOnly && (
          <LabelWithEditButton
            text={'Shipping'}
            isEditable={isEditable}
            onEdit={handleEdit}
            className={cn(styles.labelContainer, styles.title)}
          />
        )}
        <Toggle
          className={styles.toggle}
          sliderClassName={styles.toggleSlider}
          value={isDelayedShipping}
          checked={isDelayedShipping}
          defaultValue={false}
          disabled
        >
          Claim Link
        </Toggle>
        {isAvailableDS && (
          <Selector
            className={styles.selector}
            helperText="Address Options"
            options={options}
            value={selectedOption}
            onChange={(v) => handleSelectInputChange(v as ISelectorValue)}
            isClearable={false}
            isSearchable={false}
            closeMenuOnSelect
            readOnly={isReadOnly}
          />
        )}
        {!isReadOnly && !isFixedSelectorValue && isAvailableDS && (
          <ActionButton
            className={styles.addressBookBtn}
            outlined
            icon={<BookIcon className={styles.icon} />}
            title={actionButtonTitle}
            size={UISizeEnum.Normal}
            onClick={() => setSidebarMode(SidebarModesEnum.AddressBook)}
          />
        )}
        {isAddressListPresent && <AddressList addresses={addressList} />}
        <>
          <div className={styles.addressLabel}>
            {isAddressLabelShown && <h2>Pre-populated address</h2>}
            {!isReadOnly && !isRecipientDecidesFromListSelectorValue && (
              <LabelWithEditButton
                text=""
                isEditable={isEditable}
                onEdit={() => setSidebarMode(SidebarModesEnum.FixedAddress)}
                className={styles.labelContainer}
              />
            )}
          </div>
          {!isEmptyRecipientAddress && (
            <SingleAddress
              address={receiverAddress}
              onReset={!isReadOnly && !isAddressFixed ? handleResetSingleAddress : undefined}
            />
          )}
        </>
        <Message selectValue={selectorValue} addresses={addresses} />
        <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode === SidebarModesEnum.AddressBook}>
          <AddressBookSidebarContainer
            onSubmit={(addr: string[]) => dispatch(changeAB(addr))}
            onReset={() => dispatch(changeAB([]))}
            onClose={handleCloseSidebar}
            addressIds={addresses}
            isSidebarOpen={sidebarMode === SidebarModesEnum.AddressBook}
          />
        </AddressBookSidebar>
        <ReceiverFixedAddressSidebarContainer
          addressBook={addressBook}
          trigger={sidebarMode === SidebarModesEnum.FixedAddress}
          onClose={handleCloseSidebar}
          value={receiverAddress}
          onSubmit={handleSubmitReceiversAddress}
        />
        {!isReadOnly && (
          <div className={styles.controls}>
            <ActionButton
              title="Cancel"
              type={BUTTON_BUTTON}
              outlined
              className={styles.cancelButton}
              onClick={handleReset}
            />
            <ActionButton type={BUTTON_BUTTON} title="Save" onClick={handleSubmit} disabled={!isShippingValid} />
          </div>
        )}
      </div>
    </>
  );
};

export default ReportFlow;
