import cn from 'classnames';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { ReactComponent as IconUser } from '../../../assets/images/icon-user.svg';
import {
  RECEIVER_COMPANY_NAME,
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  TContactForm,
  TCRMForm,
} from '../../../constants/shipping';
import { HiddenInput, Input } from '../../index';

import styles from './ShippingForm.module.scss';

interface IProps {
  disabled?: boolean;
  hasCRM?: boolean;
  requiredFields: Record<string, boolean>;
  className?: string;
}

const ReceiverContactForm = ({ disabled, hasCRM, requiredFields, className }: IProps) => {
  type TForm = typeof hasCRM extends true ? TContactForm & TCRMForm : TContactForm;

  const form = useFormikContext<TForm>();

  const getFieldErrors = React.useCallback(
    (fieldName: keyof TForm) => {
      return fieldName && form.touched[fieldName] ? form.errors[fieldName] : null;
    },
    [form.errors, form.touched],
  );

  const crmFields = React.useMemo(() => {
    if (!hasCRM) {
      return null;
    }

    const values = form.values as TContactForm & TCRMForm;

    return (
      <>
        <HiddenInput name={RECEIVER_CRM_RECORD_ID} value={(values[RECEIVER_CRM_RECORD_ID] as string) ?? ''} />
        <HiddenInput name={RECEIVER_CRM_RECORD_TYPE} value={(values[RECEIVER_CRM_RECORD_TYPE] as string) ?? ''} />
        <HiddenInput name={RECEIVER_CRM_OPPORTUNITY_ID} value={(values[RECEIVER_CRM_OPPORTUNITY_ID] as string) ?? ''} />
      </>
    );
  }, [hasCRM, form.values]);

  return (
    <div role="form" className={cn(styles.container, styles.noBorder, className)}>
      <Input
        helperText="First name"
        name={RECEIVER_FIRST_NAME}
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getFieldErrors(RECEIVER_FIRST_NAME) })}
        placeholder="First name"
        disabled={disabled}
        required={requiredFields[RECEIVER_FIRST_NAME]}
        icon={<IconUser />}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values[RECEIVER_FIRST_NAME] ?? ''}
        error={getFieldErrors(RECEIVER_FIRST_NAME)}
      />
      <Input
        helperText="Last name"
        name={RECEIVER_LAST_NAME}
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getFieldErrors(RECEIVER_LAST_NAME) })}
        placeholder="Last name"
        disabled={disabled}
        required={requiredFields[RECEIVER_LAST_NAME]}
        icon={<IconUser />}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values[RECEIVER_LAST_NAME] ?? ''}
        error={getFieldErrors(RECEIVER_LAST_NAME)}
      />
      <Input
        helperText="Email"
        name={RECEIVER_EMAIL}
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getFieldErrors(RECEIVER_EMAIL) })}
        placeholder="Email"
        disabled={disabled}
        required={requiredFields[RECEIVER_EMAIL]}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        value={form.values[RECEIVER_EMAIL] ?? ''}
        error={getFieldErrors(RECEIVER_EMAIL)}
      />
      <Input
        helperText="Phone"
        name={RECEIVER_PHONE}
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getFieldErrors(RECEIVER_PHONE) })}
        placeholder="Phone"
        disabled={disabled}
        required={requiredFields[RECEIVER_PHONE]}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        value={form.values[RECEIVER_PHONE] ?? ''}
        error={getFieldErrors(RECEIVER_PHONE)}
      />
      <Input
        helperText="Company"
        name={RECEIVER_COMPANY_NAME}
        className={cn(styles.inputContainer, styles.fullWidth)}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getFieldErrors(RECEIVER_COMPANY_NAME) })}
        placeholder="Company"
        disabled={disabled}
        required={requiredFields[RECEIVER_COMPANY_NAME]}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        value={form.values[RECEIVER_COMPANY_NAME] ?? ''}
        error={getFieldErrors(RECEIVER_COMPANY_NAME)}
      />
      {crmFields}
    </div>
  );
};

export default ReceiverContactForm;
