import cn from 'classnames';
import { FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DELAYED_SHIPPING_EXPIRATION,
  INTERNAL_NOTES,
  IS_TOGGLE_ON_HOLD_ENABLED,
  SEND_ON_HOLD,
} from '../../../constants/bucket';
import { DateFormatsEnum } from '../../../constants/date';
import { DELAYED_SHIPPING_EXPIRATION_DATE_HINT } from '../../../constants/engagement';
import { BUSINESS_REASON_ID } from '../../../constants/reasons';
import { endpoints } from '../../../constants/routing';
import useFetch from '../../../hooks/useFetch';
import { addBucketValue } from '../../../store/actions/bucket';
import { selectIsBusinessReasonRequired } from '../../../store/selectors/bucket';
import { selectBusinessReasonById, selectBusinessReasons } from '../../../store/selectors/reasons';
import { IEditSendFieldRequestPayload, ISummaryFormFields } from '../../../types/bucket';
import { ISelectorValue, NotificationListEnum } from '../../../types/shell';
import { formatDate } from '../../../utils/date';
import notification from '../../../utils/notification';
import { ActionButton } from '../buttons';
import { ExpirationDateInput, HoldUntilDateInput, Selector, Textarea } from '../inputs';
import { LabelWithEditButton } from '../labels';

import styles from './AdditionalSummaryForm.module.scss';

interface IProps {
  className?: string;
  form: FormikProps<ISummaryFormFields>;
  onChange?: (name: keyof ISummaryFormFields, value: string | boolean) => void;
  readOnly?: boolean;
  onHoldIsAllowed?: boolean;
  isEditAllowed?: boolean;
  isOneLink?: boolean;
  sendId?: string;
  isDelayedShipping?: boolean;
}

const AdditionalSummaryForm = ({
  className,
  form,
  onChange,
  readOnly,
  onHoldIsAllowed,
  isEditAllowed,
  isOneLink,
  sendId,
  isDelayedShipping,
}: IProps) => {
  const dispatch = useDispatch();
  const reasons = useSelector(selectBusinessReasons);
  const businessReason = useSelector(selectBusinessReasonById(form ? form.values[BUSINESS_REASON_ID] : null));
  const isBusinessReasonRequired = useSelector(selectIsBusinessReasonRequired);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [tempBusinessReasonSelectedValue, setTempBusinessReasonSelectedValue] = React.useState<ISelectorValue | null>(
    null,
  );
  const [currentBusinessReasonSelectedValue, setCurrentBusinessReasonSelectedValue] = React.useState({
    label: businessReason?.name!,
    value: businessReason?.uid!,
  });

  const reasonsOptions = React.useMemo(() => {
    if (!reasons) {
      return [];
    }
    return reasons.map(({ name, uid }) => {
      return {
        label: name,
        value: uid,
      };
    });
  }, [reasons]);

  const businessReasonSelectedValue = React.useMemo(() => {
    return reasonsOptions.find(({ value }) => value === businessReason?.uid);
  }, [reasonsOptions, businessReason]);

  const { make: editSendFieldRequest, isLoading: isEditSendFieldLoading } = useFetch<
    IEditSendFieldRequestPayload,
    never
  >({
    endpoint: endpoints.editSendField,
    showToastOnError: true,
    method: 'POST',
  });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        onChange(name as keyof ISummaryFormFields, value);
      }
    },
    [onChange],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof ISummaryFormFields, selected: ISelectorValue) => {
      const { value } = selected || {};
      if (onChange) {
        return onChange(name, value);
      }
    },
    [onChange],
  );

  const handleChangeOnHoldDate = React.useCallback(
    (value: string) => {
      onChange?.(SEND_ON_HOLD, value ? new Date(value).toISOString() : value);
    },
    [onChange],
  );

  const handleChangeExpirationDate = React.useCallback(
    (value: string) => {
      onChange?.(DELAYED_SHIPPING_EXPIRATION, value ? formatDate(value, DateFormatsEnum.End) : value);
    },
    [onChange],
  );

  const handleToggleChange = React.useCallback(
    (value: boolean) => {
      dispatch(addBucketValue({ [IS_TOGGLE_ON_HOLD_ENABLED]: value }));
    },
    [dispatch],
  );

  const handleSelectorChange = React.useCallback(
    (value: ISelectorValue) => {
      if (!isEditMode) {
        handleSelectInputChange(BUSINESS_REASON_ID, value);
      } else {
        setTempBusinessReasonSelectedValue(value);
      }
    },
    [handleSelectInputChange, isEditMode],
  );

  const handleApplyChanges = React.useCallback(() => {
    const sendIdValue = isOneLink ? { one_link_id: sendId } : { engagement_id: sendId };

    if (tempBusinessReasonSelectedValue) {
      editSendFieldRequest({
        body: {
          ...sendIdValue,
          key: BUSINESS_REASON_ID,
          value: tempBusinessReasonSelectedValue?.value!,
        },
      }).then(() => {
        setTempBusinessReasonSelectedValue(tempBusinessReasonSelectedValue);
        setCurrentBusinessReasonSelectedValue(tempBusinessReasonSelectedValue);
        setIsEditMode(false);
        notification.success(NotificationListEnum.Success, { content: 'Business reason has been updated' });
      });
    }
  }, [isOneLink, sendId, tempBusinessReasonSelectedValue, editSendFieldRequest]);

  const isValueChanged = React.useMemo(() => {
    return tempBusinessReasonSelectedValue
      ? !isEqual(currentBusinessReasonSelectedValue, tempBusinessReasonSelectedValue)
      : false;
  }, [currentBusinessReasonSelectedValue, tempBusinessReasonSelectedValue]);

  const renderEditFieldButtons = React.useCallback(() => {
    return (
      <div className={styles.buttonsContainer}>
        {isValueChanged && (
          <ActionButton
            title="Apply Changes"
            disabled={!isValueChanged || isEditSendFieldLoading}
            onClick={handleApplyChanges}
            inline
          />
        )}
        <ActionButton
          title="Cancel"
          onClick={() => {
            setIsEditMode(false);
            setTempBusinessReasonSelectedValue(currentBusinessReasonSelectedValue || null);
          }}
          inline
        />
      </div>
    );
  }, [currentBusinessReasonSelectedValue, handleApplyChanges, isEditSendFieldLoading, isValueChanged]);

  return (
    <form className={cn(styles.additionalSummaryForm, className)} onSubmit={form.handleSubmit}>
      <div className={styles.wrapper}>
        {!readOnly && onHoldIsAllowed && (
          <HoldUntilDateInput
            readOnly={readOnly}
            value={form.values[SEND_ON_HOLD]}
            onChange={handleChangeOnHoldDate}
            onToggleChange={handleToggleChange}
          />
        )}
        {!readOnly && isDelayedShipping && (
          <ExpirationDateInput
            value={form.values[DELAYED_SHIPPING_EXPIRATION]}
            onChange={handleChangeExpirationDate}
            hint={DELAYED_SHIPPING_EXPIRATION_DATE_HINT}
          />
        )}
      </div>
      <div className={styles.businessReasonContainer}>
        <Selector
          name={BUSINESS_REASON_ID}
          isSearchable
          className={styles.input}
          closeMenuOnSelect
          value={businessReasonSelectedValue}
          tempValue={tempBusinessReasonSelectedValue}
          readOnly={readOnly}
          menuIsOpen={readOnly && !isEditMode ? false : undefined}
          onChange={(value) => handleSelectorChange(value as ISelectorValue)}
          options={reasonsOptions}
          placeholder="Select business reason"
          required={isBusinessReasonRequired}
          helperText={
            readOnly ? (
              <LabelWithEditButton
                isEditable={isEditAllowed}
                text="Business Reason"
                onEdit={() => setIsEditMode(true)}
                className={styles.labelContainer}
              />
            ) : (
              'Business Reason'
            )
          }
          editMode={isEditMode}
          isClearable={!isEditMode}
        />
        {isEditMode && renderEditFieldButtons()}
      </div>
      {(!readOnly || (readOnly && form.values[INTERNAL_NOTES])) && (
        <Textarea
          field={form.getFieldProps(INTERNAL_NOTES)}
          name={INTERNAL_NOTES}
          className={styles.textarea}
          onChange={handleChange}
          value={form.values[INTERNAL_NOTES]}
          helperText="Internal Notes"
          placeholder="Put your note here"
          readOnly={readOnly}
        />
      )}
    </form>
  );
};

export default AdditionalSummaryForm;
