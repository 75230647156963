import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as BookIcon } from '../../../assets/images/icon-book.svg';

import { ActionButton, AddressBookSidebar, Selector, Toggle } from '../../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { CUSTOM } from '../../../constants/campaigns';
import { fetchAddressBookRequest } from '../../../store/actions/addressBook';
import { AddressBookSidebarContainer } from '../../index';

import { ISelectorValue, UISizeEnum } from '../../../types/shell';
import { ICampaignShippingConfiguration } from '../../../types/shipping';
import { isObjectEmpty, isObjectsEqual } from '../../../utils/helpers';
import { AddressPreview, Message } from '../common';
import { getSelectOptions } from '../utils';

import { changeAB, changeDS, changeSelect, getInitialState, IState, reducer, reset, TAction } from './reducer';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  value?: Partial<ICampaignShippingConfiguration>;
  onChange?: (cfg?: Partial<ICampaignShippingConfiguration>) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const Campaign: React.FC<IProps> = ({ isDisabled, isReadOnly, value, onChange }) => {
  const reduxDispatch = useDispatch();
  const initialState = useMemo(() => getInitialState(value), [value]);

  const [{ isDelayedShipping, isAddressFixed, addresses, selectorValue }, dispatch] = React.useReducer<
    React.Reducer<IState, TAction>
  >(reducer, initialState);

  const [isSidebarShown, setSidebarShown] = React.useState(false);

  const currentValue = useMemo(() => {
    const v = {
      is_delayed_shipping: isDelayedShipping,
      is_receiver_address_fixed: isAddressFixed,
      org_address_ids: addresses,
    };

    return !isObjectEmpty(v) ? v : undefined;
  }, [isDelayedShipping, isAddressFixed, addresses]);

  // Sync effect - syncs reducer state and values outside via onChange
  React.useEffect(() => {
    switch (true) {
      case !value && !!currentValue:
        dispatch(reset());
        break;
      case !isObjectsEqual(value, currentValue) && !!currentValue:
        onChange?.(currentValue);
        break;
      default:
        break;
    }
  }, [value, currentValue, onChange]);

  const options = useMemo(() => getSelectOptions(!!isDelayedShipping), [isDelayedShipping]);

  const handleSelectInputChange = React.useCallback(({ value: selected }: ISelectorValue) => {
    dispatch(changeSelect(selected as ShippingFlowSelectorOptionsEnum));
  }, []);

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarShown(false);
  }, []);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectorValue),
    [options, selectorValue],
  );

  const addressesAdded = useMemo(() => addresses?.filter((a) => a !== CUSTOM), [addresses]);

  const fixedAddress = useMemo(() => selectorValue === ShippingFlowSelectorOptionsEnum.Fixed, [selectorValue]);

  useEffect(() => {
    reduxDispatch(fetchAddressBookRequest());
  }, [reduxDispatch]);

  return (
    <div className={cn(styles.container, { [styles.disabled]: isDisabled })}>
      <h2 className={styles.title}>Shipping</h2>
      <Toggle
        className={styles.toggle}
        sliderClassName={styles.toggleSlider}
        onChange={!isReadOnly ? (e) => dispatch(changeDS(e.currentTarget.checked)) : undefined}
        value={isDelayedShipping}
        checked={isDelayedShipping}
        defaultValue={false}
      >
        Claim Link
      </Toggle>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={selectedOption}
        onChange={(v) => handleSelectInputChange(v as ISelectorValue)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={isReadOnly}
      />
      {!isDisabled && !isReadOnly && (
        <ActionButton
          className={styles.addressBookBtn}
          icon={<BookIcon />}
          outlined
          title={`${addressesAdded?.length ? 'Edit' : 'Select'} Address Book Option${fixedAddress ? '' : 's'}`}
          size={UISizeEnum.Normal}
          onClick={() => setSidebarShown(true)}
        />
      )}
      {!isDisabled && (
        <>
          <AddressPreview addresses={addressesAdded} />
          <Message addresses={addresses} selectValue={selectorValue} />
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={isSidebarShown}>
        {isSidebarShown && (
          <AddressBookSidebarContainer
            {...(fixedAddress
              ? {
                  onSelect: (addrs: string[]) => {
                    dispatch(changeAB(addrs));
                    setSidebarShown(false);
                  },
                  onReset: () => dispatch(changeAB([CUSTOM])),
                }
              : {
                  onSubmit: (addr: string[]) => dispatch(changeAB(addr)),
                  onReset: () => dispatch(changeAB([])),
                })}
            onClose={handleCloseSidebar}
            addressIds={addressesAdded}
            isSidebarOpen={isSidebarShown}
          />
        )}
      </AddressBookSidebar>
    </div>
  );
};

export default Campaign;
