import { DELAYED_SHIPPING_EXPIRATION } from '../constants/bucket';
import { ENGAGEMENT_SOURCE_TYPE } from '../constants/reports';
import {
  DELAYED_SHIPPING_URL,
  DISABLE_EMAIL_NOTIFICATIONS,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_OPTION,
  SHIP_ORDER_STATUS,
  TRACKING_URL,
} from '../constants/shipping';
import { TEngagementCandidate } from './bucket';
import { ICampaignItem, ICampaignSummary, IFetchCampaignSummariesResponsePayload } from './campaigns';
import { IPromiseCallbacks } from './redux';
import { FilterTypesEnum, IApiError } from './shell';
import { ShippingOptionsEnum } from './shipping';

export enum ReportsRequestSearchTypes {
  orgSearch = 'org_search',
  deptSearch = 'dept_search',
}

export enum ReportTypesEnum {
  Regular = 'regular',
  Scheduled = 'scheduled',
}

export enum ReportStatusEnum {
  Canceled = 'canceled',
  Viewed = 'viewed',
  AwaitingAddress = 'awaiting_address',
  AwaitingShipment = 'awaiting_shipment',
  InFulfillment = 'in_fulfillment',
}

export enum EngagementSourceTypesEnum {
  Zapier = 'zapier',
  OneLink = 'one_link',
  DirectSend = 'direct_send',
  Empty = '',
}

export enum ReportsRequestTypesEnum {
  Campaign = 'campaign',
  Product = 'product',
}

export enum EditSendModeEnum {
  Details = 'edit-details',
  Items = 'edit-items',
  Shipping = 'edit-shipping',
}

export enum EngagementReminderIntervalsEnum {
  Weekly = 'weekly',
  Biweekly = 'bi-weekly',
  Custom = 'custom',
}

export const REMINDER_INTERVAL_LABELS = {
  [EngagementReminderIntervalsEnum.Weekly]: 'Once a week',
  [EngagementReminderIntervalsEnum.Biweekly]: 'Twice a week',
  [EngagementReminderIntervalsEnum.Custom]: 'Custom',
};

export enum EngagementReminderWeekdaysEnum {
  Sun = 'Sun',
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
}

export interface IReportEngagementItem extends ICampaignItem {
  digital_claim_date: string;
  digital_claim_status: string;
  digital_gift_expiration: string;
  digital_gift_url: string;
  rybbon_ref_code: string;
  // TODO: will it be here? Recheck in the future
  fulfillment_center_id: string;
}

export interface IReport
  extends Omit<Required<TEngagementCandidate>, 'items' | 'pick_campaigns' | 'ship_order_status'> {
  created_by: string;
  canceled_by: string;
  canceled_at: string;
  [ENGAGEMENT_SOURCE_TYPE]: EngagementSourceTypesEnum;
  on_hold_until: string;
  created_at: string;
  receiver_next_reminder_send_date: string;
  order_id: string;
  woocommerce_order_id?: number;
  org_id: string;
  [RECEIVER_FIRST_NAME]: string;
  [RECEIVER_LAST_NAME]: string;
  [RECEIVER_COMPANY_NAME]: string;
  [RECEIVER_PHONE]: string;
  [RECEIVER_ADDRESS1]: string;
  [RECEIVER_ADDRESS2]: string;
  [RECEIVER_STATE]: string;
  [RECEIVER_ZIP]: string;
  [RECEIVER_COUNTRY]: string;
  [RECEIVER_CITY]: string;
  [SHIPPING_OPTION]: ShippingOptionsEnum;
  [SHIP_ORDER_STATUS]: string;
  receiver_province: string;
  sender_address1: string;
  sender_address2: string;
  sender_city: string;
  sender_company_name: string;
  sender_country: string;
  sender_email: string;
  sender_first_name: string;
  sender_last_name: string;
  sender_phone: string;
  sender_province: string;
  sender_state: string;
  sender_zip: string;
  ship_carrier: string;
  ship_order_id: number;
  tracking_number: string;
  package_delivered_date: string;
  shipping_amount: number;
  package_shipped_date: string;
  shipping_cost?: number;
  packaging_cost?: number;
  [TRACKING_URL]: string;
  user_id: string;
  uid: string;
  delayed_shipping_key: string;
  [DELAYED_SHIPPING_URL]: string;
  [DELAYED_SHIPPING_EXPIRATION]: string;
  delayed_shipping_submission_date: string;
  delayed_shipping_page_view_date: string;
  department_id: string;
  status: ReportStatusEnum | '';
  date_to_send: string;
  pick_campaign_ids: string[];
  expiration_date: Date | null;
  items?: IReportEngagementItem[] | null;
  digital_status?: string;
  was_scheduled?: boolean;
  receiver_next_reminder_send_data?: string;
  ship_order_id_str?: string;
  is_receiver_address_fixed: boolean;
  [DISABLE_EMAIL_NOTIFICATIONS]?: boolean;
}

export interface IReportWithCampaignSummaries extends IReport {
  pick_campaigns: ICampaignSummary[];
  name: undefined;
  description: undefined;
}

export interface IReportsState {
  [ReportTypesEnum.Regular]: { [key: string]: IReportWithCampaignSummaries } | null;
  [ReportTypesEnum.Scheduled]: { [key: string]: IReportWithCampaignSummaries } | null;
  isLoading: boolean;
  isLoadingSingle: boolean;
  isLoadingEdit: boolean;
  error: any;
  isFinal: boolean;
  totalCount: number;
  download: {
    url: string | null;
    isLoading: boolean;
    error: IApiError | null;
  };
}

export interface IReportStatusMapper {
  [k: string]: {
    label: string;
    color: string;
  };
}

export interface IReportsSelectorMetadata {
  filter?: FilterTypesEnum;
  type: ReportTypesEnum;
}

export interface IReportsSelectorMetadataWithUserId extends IReportsSelectorMetadata {
  userId?: string;
}

//#region Request interfaces

export interface IReportsRequest {
  page: number;
  page_size: number;
  first_name?: string;
  company?: string;
  department_ids?: string[];
  date_range?: {
    from: string;
    to: string;
  };
}

export interface IFetchReportsResponse {
  engagements: IReport[];
  scheduled_engagements: IReport[];
  page: number;
  page_size: number;
  total_count: number;
}

export interface IFetchReportsResponsePayload extends IFetchReportsResponse {
  type: ReportTypesEnum;
}

export interface IFetchReportsRequestPayload {
  isOrgSearch?: boolean;
  isDeptSearch?: boolean;
  body: IReportsRequest;
  type: ReportTypesEnum;
}

export interface IFetchReportByIdRequestPayload extends IPromiseCallbacks {
  reportId: string;
  type: ReportTypesEnum;
}

export interface IFetchReportByIdResponsePayload {
  engagement: IReport;
  type: ReportTypesEnum;
}

export interface IPopulateReportsCampaignSummariesRequestPayload {
  campaign_ids: string[];
  reportId: string;
  type: ReportTypesEnum;
}

export interface IPopulateReportsCampaignSummariesResponsePayload extends IFetchCampaignSummariesResponsePayload {
  reportId: string;
  type: ReportTypesEnum;
}

export interface IDownloadReportsCSVRequestPayload extends Partial<IPromiseCallbacks> {
  type: ReportTypesEnum;
  isOrgSearch?: boolean;
  isDeptSearch?: boolean;
  department_ids?: string[];
  range: {
    from: string | null;
    to: string | null;
  };
  inventory_breakdown?: boolean;
}

export type UpdateReportValueRequestPayload<T extends IReport> = {
  [key in keyof Partial<T>]: T[key];
} & {
  type: ReportTypesEnum;
  id: string | undefined;
};

//#endregion
