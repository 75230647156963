import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ReactComponent as IconUser } from '../../../assets/images/icon-user.svg';
import { ActionButton, Input, Textarea } from '../../../components';
import {
  RECEIVER_COMPANY_NAME,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
} from '../../../constants/shipping';
import { MAX_BULK_NOTE_LENGTH, MAX_NOTE_LENGTH, MAX_ONE_LINK_NOTE_LENGTH, MESSAGE } from '../../../constants/templates';
import { IMaintenanceFormOutputData } from '../../../types/forms';
import { IReceiverInfoDetailsFormValues } from '../../../types/shipping';

import styles from './SendDetailsForm.module.scss';

export interface ISendSummaryFormProps extends IMaintenanceFormOutputData {
  onChange: (
    name: keyof IReceiverInfoDetailsFormValues,
    value: string | number | undefined | string[] | boolean,
  ) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  form: FormikProps<IReceiverInfoDetailsFormValues>;
  requiredFields: Record<string, boolean>;
  disabled?: boolean;
  className?: string;
  isOneLinkEngagement?: boolean;
  isBulkEngagement?: boolean;
  isEditingShipmentOptionsAllowed?: boolean;
  isNoteHidden?: boolean;
  isDirectSend?: boolean;
  isAddressFixed?: boolean;
  isDelayedShipping?: boolean;
}

interface IFormSectionTitleProps {
  title: string;
  titleClassName?: string;
}

interface IFormRowProps {
  children: React.ReactElement[] | React.ReactElement | string;
  rowClassName?: string;
}

const SendDetailsForm: React.FC<ISendSummaryFormProps> = ({
  onChange,
  onCancel,
  onSubmit,
  form,
  disabled,
  requiredFields,
  className,
  isOneLinkEngagement,
  isBulkEngagement,
  isNoteHidden,
  isDirectSend,
}) => {
  const FormSectionTitle = React.useMemo(
    () =>
      ({ title, titleClassName }: IFormSectionTitleProps) => {
        return <div className={cn(styles.sectionTitle, titleClassName)}>{title}</div>;
      },
    [],
  );

  const FormInputRow = React.useMemo(
    () =>
      ({ children, rowClassName }: IFormRowProps) => {
        return <div className={cn(styles.row, rowClassName)}>{children}</div>;
      },
    [],
  );

  const maxChars = React.useMemo(() => {
    switch (true) {
      case isBulkEngagement:
        return MAX_BULK_NOTE_LENGTH;
      case isOneLinkEngagement:
        return MAX_ONE_LINK_NOTE_LENGTH;

      default:
        return MAX_NOTE_LENGTH;
    }
  }, [isBulkEngagement, isOneLinkEngagement]);

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IReceiverInfoDetailsFormValues) => {
      return form.errors[fieldName];
    },
    [form.errors],
  );

  const handleFormFieldChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
      name: keyof IReceiverInfoDetailsFormValues,
    ) => {
      onChange(name, e.target.value);
    },
    [onChange],
  );

  return (
    <form onSubmit={form.handleSubmit} className={cn(styles.shippingForm, className)}>
      <FormSectionTitle title="Recipient's Details" />
      <FormInputRow>
        <Input
          helperText="First name"
          name={RECEIVER_FIRST_NAME}
          inputClassName={styles.inputField}
          placeholder="First name"
          disabled={disabled}
          required={requiredFields[RECEIVER_FIRST_NAME]}
          icon={<IconUser />}
          value={form.values[RECEIVER_FIRST_NAME]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_FIRST_NAME)}
          error={getFieldErrors(RECEIVER_FIRST_NAME)}
        />
        <Input
          helperText="Last name"
          name={RECEIVER_LAST_NAME}
          inputClassName={styles.inputField}
          placeholder="Last name"
          disabled={disabled}
          required={requiredFields[RECEIVER_LAST_NAME]}
          icon={<IconUser />}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_LAST_NAME)}
          value={form.values[RECEIVER_LAST_NAME]}
          error={getFieldErrors(RECEIVER_LAST_NAME)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="Company"
          name={RECEIVER_COMPANY_NAME}
          inputClassName={styles.inputField}
          placeholder="Company"
          disabled={disabled}
          required={requiredFields[RECEIVER_COMPANY_NAME]}
          value={form.values[RECEIVER_COMPANY_NAME]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_COMPANY_NAME)}
          error={getFieldErrors(RECEIVER_COMPANY_NAME)}
        />
        <Input
          helperText="Phone"
          name={RECEIVER_PHONE}
          inputClassName={styles.inputField}
          placeholder="Phone"
          disabled={disabled}
          required={requiredFields[RECEIVER_PHONE]}
          value={form.values[RECEIVER_PHONE]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_PHONE)}
          error={getFieldErrors(RECEIVER_PHONE)}
        />
      </FormInputRow>
      {!isDirectSend && !isNoteHidden && (
        <>
          <FormSectionTitle title="Custom Message" titleClassName={styles.secondaryTitle} />
          <FormInputRow>
            <Textarea
              name={MESSAGE}
              value={form.values[MESSAGE]}
              onChange={(e) => handleFormFieldChange(e, MESSAGE)}
              className={styles.inputField}
              inputClassName={styles.noteTextArea}
              placeholder="Message"
              showCounter={true}
              maxCharsCount={maxChars}
            />
          </FormInputRow>
        </>
      )}
      <FormInputRow rowClassName={styles.controlsContainer}>
        {/*TODO: To be done in ticket related to notifications
        <Checkbox
          text="Notify Sender"
          onChange={(e) => handleCheckboxChange(e, DISABLE_SENDER_EMAIL_NOTIFICATIONS)}
          checked={form.values[DISABLE_SENDER_EMAIL_NOTIFICATIONS]}
        />*/}
        <ActionButton className={styles.cancelButton} title="Cancel" onClick={onCancel} />
        <ActionButton
          type="submit"
          className={styles.continueButton}
          disabled={!form.dirty || !!Object.keys(form.errors).length}
          title="Save changes"
          onClick={onSubmit}
        />
      </FormInputRow>
    </form>
  );
};

export default SendDetailsForm;
