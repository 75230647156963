import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ReactComponent as BookIcon } from '../../../assets/images/icon-book.svg';

import { ActionButton, AddressBookSidebar, Selector } from '../../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { routes } from '../../../constants/routing';
import { fetchAddressBookRequest } from '../../../store/actions/addressBook';
import { updateBucketShippingDetails } from '../../../store/actions/bucket';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { selectOneLinkShippingDetails } from '../../../store/selectors/bucket';
import { selectOneLinkSummaryByIDShippingConfiguration } from '../../../store/selectors/oneLink';
import { AddressBookSidebarContainer, ReceiverFixedAddressSidebarContainer } from '../../index';

import { IOneLinkRouteParams } from '../../../types/routing';
import { ISelectorValue, UISizeEnum } from '../../../types/shell';
import { IOneLinkShippingDetails } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';
import { AddressPreview, Message } from '../common';
import { SidebarModesEnum } from '../types';
import { getInitialSelectorState, getSelectOptions } from '../utils';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const OneLink: React.FC<IProps> = ({ isDisabled, isReadOnly }) => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch<IOneLinkRouteParams>(routes.oneLinkManager.route);

  const { itemId, status } = React.useMemo(() => routeMatch?.params || ({} as IOneLinkRouteParams), [routeMatch]);

  const isEditable = React.useMemo(() => !(routeMatch || isReadOnly), [routeMatch, isReadOnly]);

  const [sidebarMode, setSidebarMode] = React.useState<SidebarModesEnum>(SidebarModesEnum.None);
  const addressBook = useSelector(selectAddressBook);
  const {
    org_address_ids,
    is_receiver_address_fixed = false,
    receiver_address,
  } = useSelector(
    itemId ? selectOneLinkSummaryByIDShippingConfiguration(itemId, status) : selectOneLinkShippingDetails,
  );

  const [selectValue, setSelectValue] = React.useState<ShippingFlowSelectorOptionsEnum>(
    getInitialSelectorState({ is_receiver_address_fixed, address: receiver_address, is_delayed_shipping: true }),
  );

  const handleChange = React.useCallback(
    (values: IOneLinkShippingDetails) => {
      dispatch(updateBucketShippingDetails(values));
    },
    [dispatch],
  );

  const handleSelectChange = React.useCallback(
    (v: ISelectorValue) => {
      const { value } = v;

      let updates: IOneLinkShippingDetails;
      switch (value) {
        case ShippingFlowSelectorOptionsEnum.Fixed: {
          if (org_address_ids?.length === 1) {
            const address = addressBook?.find((a) => a.uid === org_address_ids[0]);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: address,
            };
          } else {
            setSidebarMode(SidebarModesEnum.FixedAddress);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: undefined,
            };
          }
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecides: {
          updates = {
            is_receiver_address_fixed: false,
            receiver_address: undefined,
          };
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList: {
          updates = {
            is_receiver_address_fixed: true,
            receiver_address: undefined,
          };
          break;
        }
        default:
          return;
      }
      setSelectValue(value);
      handleChange(updates);
    },
    [handleChange, addressBook, org_address_ids],
  );

  const handleAddressBookSubmit = React.useCallback(
    (addressIds: string[]) => {
      handleChange({
        is_receiver_address_fixed,
        org_address_ids: addressIds,
        receiver_address: undefined,
      });
    },
    [handleChange, is_receiver_address_fixed],
  );

  const options = useMemo(() => getSelectOptions(true), []); // isDelayedShipping = always true

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const isEmptyRecipientFixedAddress = React.useMemo(() => isObjectEmpty(receiver_address), [receiver_address]);

  const isFixedSelectorValue = useMemo(() => {
    return selectValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectValue]);

  const actionButtonTitle = useMemo(() => {
    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientFixedAddress ? 'Edit' : 'Enter'} Fixed Address`;
      case org_address_ids && org_address_ids.length !== 0:
        return 'Edit Address Book Option';
      default:
        return 'Select Address Book Option';
    }
  }, [isFixedSelectorValue, org_address_ids, isEmptyRecipientFixedAddress]);

  const dynamicAddressProps = useMemo(
    () => ({
      addresses: org_address_ids,
      receiverAddress: isFixedSelectorValue ? receiver_address : undefined,
      forceAddressList: !isFixedSelectorValue,
      onReset: () => {
        handleChange({
          receiver_address: undefined,
          is_receiver_address_fixed,
          org_address_ids,
        });
      },
    }),
    [handleChange, is_receiver_address_fixed, isFixedSelectorValue, org_address_ids, receiver_address],
  );

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  return (
    <div className={cn(styles.container, styles.nested, { [styles.disabled]: isDisabled })}>
      <h2 className={styles.title}>Shipping</h2>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={options.find((option) => option.value === selectValue)}
        onChange={(v) => handleSelectChange(v as ISelectorValue)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={!isEditable}
      />
      {isEditable && !isDisabled && (
        <ActionButton
          className={styles.addressBookBtn}
          outlined
          icon={<BookIcon className={styles.icon} />}
          title={actionButtonTitle}
          size={UISizeEnum.Normal}
          onClick={() =>
            setSidebarMode(isFixedSelectorValue ? SidebarModesEnum.FixedAddress : SidebarModesEnum.AddressBook)
          }
        />
      )}
      {!isDisabled && (
        <>
          <AddressPreview {...dynamicAddressProps} />
          <Message selectValue={selectValue} addresses={org_address_ids} />
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode === SidebarModesEnum.AddressBook}>
        <AddressBookSidebarContainer
          onClose={handleCloseSidebar}
          addressIds={org_address_ids}
          onSubmit={handleAddressBookSubmit}
          isSidebarOpen={sidebarMode === SidebarModesEnum.AddressBook}
        />
      </AddressBookSidebar>
      <ReceiverFixedAddressSidebarContainer
        addressBook={addressBook}
        trigger={sidebarMode === SidebarModesEnum.FixedAddress}
        onClose={handleCloseSidebar}
      />
    </div>
  );
};

export default OneLink;
