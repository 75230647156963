import {
  RECEIVER_CRM_OPPORTUNITY_ID,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
} from '../constants/shipping';

export interface IShippingDigitalAddressClassProps {
  [RECEIVER_FIRST_NAME]: string;
  [RECEIVER_LAST_NAME]: string;
  [RECEIVER_EMAIL]: string;
  [RECEIVER_CRM_RECORD_ID]?: string;
  [RECEIVER_CRM_RECORD_TYPE]?: string;
  [RECEIVER_CRM_OPPORTUNITY_ID]?: string;
}

export class ShippingDigitalAddressCandidate implements Partial<IShippingDigitalAddressClassProps> {
  [RECEIVER_FIRST_NAME] = '';
  [RECEIVER_LAST_NAME] = '';
  [RECEIVER_EMAIL] = '';
  [RECEIVER_CRM_RECORD_ID]: string | undefined;
  [RECEIVER_CRM_RECORD_TYPE]: string | undefined;
  [RECEIVER_CRM_OPPORTUNITY_ID]: string | undefined;

  constructor(props: Partial<IShippingDigitalAddressClassProps>) {
    const {
      receiver_first_name,
      receiver_last_name,
      receiver_email,
      receiver_crm_record_id,
      receiver_crm_record_type,
      receiver_crm_opportunity_id,
    } = props as IShippingDigitalAddressClassProps;
    this[RECEIVER_FIRST_NAME] = receiver_first_name || '';
    this[RECEIVER_LAST_NAME] = receiver_last_name || '';
    this[RECEIVER_EMAIL] = receiver_email || '';
    this[RECEIVER_CRM_RECORD_ID] = receiver_crm_record_id;
    this[RECEIVER_CRM_RECORD_TYPE] = receiver_crm_record_type;
    this[RECEIVER_CRM_OPPORTUNITY_ID] = receiver_crm_opportunity_id;
  }
}
