import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RECEIVER_REMINDER_INTERVAL } from '../../../constants/bucket';
import {
  BOX_ID,
  BUSINESS_REASON_REQUIRED,
  DELAYED_SHIPPING_TEMPLATE_DEFAULT,
  DEPARTMENT_IDS,
  DESCRIPTION,
  DIGITAL_LINK_TEMPLATE_DEFAULT,
  DISABLE_HANDWRITTEN_NOTE_STEP,
  HANDWRITTEN_TEMPLATE_DEFAULT,
  ICON_URL,
  ITEMS,
  NAME,
  PRIORITY,
} from '../../../constants/campaigns';
import { Campaign as ShippingDetailsContainer } from '../../../containers';
import { useCurrency } from '../../../contexts/CurrencyProvider';
import { selectCurrentOrganizationDepartmentList } from '../../../store/selectors/organizations';
import {
  selectEmailDelayedShippingTemplates,
  selectEmailDigitalGiftTemplates,
  selectNoteTemplates,
} from '../../../store/selectors/templates';
import { ICampaignCandidate, ICampaignItem } from '../../../types/campaigns';
import { ICampaignFormProps } from '../../../types/forms';
import { IEmailTemplate, INoteTemplate } from '../../../types/templates';
import { Checkbox, InventoryViewItem, Price, ShippingPageCustomizationForm } from '../../index';
import { ImageUploadInput, Input, IntervalInput } from '../inputs';

import detailsStyles from '../CampaignDetailsForm/CampaignDetailsForm.module.scss';
import itemsStyles from '../CampaignItemsForm/CampaignItemsForm.module.scss';
import styles from './CampaignSummaryForm.module.scss';

const CampaignSummaryForm = ({ form, root }: ICampaignFormProps<Omit<ICampaignCandidate, typeof BOX_ID>>) => {
  const history = useHistory();
  const { getCampaignTotal } = useCurrency();

  const departments = useSelector(selectCurrentOrganizationDepartmentList);
  const handWrittenNotesTemplates = useSelector(selectNoteTemplates);
  const delayedShippingTemplates = useSelector(selectEmailDelayedShippingTemplates);
  const digitalGiftTemplates = useSelector(selectEmailDigitalGiftTemplates);

  React.useEffect(() => {
    if (!form.isValid) {
      history.push(root);
    }
  }, [form, root]);

  const getTemplateLabel = React.useCallback(
    (
      templates: (INoteTemplate | IEmailTemplate)[] | undefined | null,
      name: keyof Omit<ICampaignCandidate, typeof BOX_ID>,
    ) => {
      const template = templates?.find((t) => t.item_id === form.values[name]);

      return template ? (template as IEmailTemplate)?.subject || (template as INoteTemplate)?.title : 'No Template';
    },
    [form],
  );

  const departmentLabel = React.useMemo(() => {
    return form.values[DEPARTMENT_IDS]?.map((id: string) => {
      const dept = departments?.find((d) => d.uid === id);

      return dept?.name || null;
    })
      .filter(Boolean)
      .join(', ');
  }, [form.values[DEPARTMENT_IDS], departments]);

  const totalPrice = React.useMemo(
    () => getCampaignTotal({ items: form.values?.[ITEMS] }),
    [form.values[ITEMS], getCampaignTotal],
  );

  return (
    <div className={styles.container}>
      <div className={cn(detailsStyles.container)}>
        <div className={detailsStyles.formWrapper}>
          <div className={cn(detailsStyles.container)}>
            <div className={detailsStyles.inputContainer}>
              <span className={detailsStyles.label}>Department</span>
              <Input readOnly value={departmentLabel} />
            </div>
            <div className={detailsStyles.campaignInfo}>
              <ImageUploadInput
                className={cn(detailsStyles.iconInput, detailsStyles.uploaded, styles.icon)}
                value={form.values[ICON_URL]}
                readOnly={true}
              />
              <div className={detailsStyles.metadata}>
                <div className={detailsStyles.inputContainer}>
                  <span className={cn(detailsStyles.label, detailsStyles.name)}>Name</span>
                  <Input readOnly value={form.values[NAME]} />
                </div>
                <div className={detailsStyles.inputContainer}>
                  <span className={detailsStyles.label}>Description</span>
                  <Input readOnly value={form.values[DESCRIPTION]} />
                </div>
              </div>
            </div>
            <div className={styles.wrapper}>
              {form.values.shipping_configuration && (
                <div className={styles.shipping}>
                  <ShippingDetailsContainer isReadOnly value={form?.values?.shipping_configuration} />
                </div>
              )}
              <div className={styles.checkboxWrapper}>
                <div>
                  {(form.values[RECEIVER_REMINDER_INTERVAL] ||
                    form.values[DISABLE_HANDWRITTEN_NOTE_STEP] ||
                    form.values[BUSINESS_REASON_REQUIRED]) && (
                    <div className={detailsStyles.checkboxes_container}>
                      <IntervalInput readOnly value={form.values[RECEIVER_REMINDER_INTERVAL]} />
                      {form.values[DISABLE_HANDWRITTEN_NOTE_STEP] && (
                        <Checkbox
                          text="Disable Custom Message Step"
                          checked={form.values[DISABLE_HANDWRITTEN_NOTE_STEP]}
                          disabled
                        />
                      )}
                      {form.values[BUSINESS_REASON_REQUIRED] && (
                        <Checkbox
                          text="Require Business Reason"
                          checked={form.values[BUSINESS_REASON_REQUIRED]}
                          disabled
                        />
                      )}
                    </div>
                  )}
                  <div className={detailsStyles.templates}>
                    <div className={detailsStyles.inputContainer}>
                      <span className={detailsStyles.label}>Delayed Shipping Email</span>
                      <Input
                        readOnly
                        value={getTemplateLabel(delayedShippingTemplates, DELAYED_SHIPPING_TEMPLATE_DEFAULT)}
                      />
                    </div>
                    <div className={detailsStyles.inputContainer}>
                      <span className={detailsStyles.label}>Custom Message</span>
                      <Input
                        disabled={form.values[DISABLE_HANDWRITTEN_NOTE_STEP]}
                        readOnly
                        value={getTemplateLabel(handWrittenNotesTemplates, HANDWRITTEN_TEMPLATE_DEFAULT)}
                      />
                    </div>
                    <div className={detailsStyles.inputContainer}>
                      <span className={detailsStyles.label}>Digital Gift Email</span>
                      <Input readOnly value={getTemplateLabel(digitalGiftTemplates, DIGITAL_LINK_TEMPLATE_DEFAULT)} />
                    </div>
                    <div className={cn(detailsStyles.inputContainer, styles.priorityAndPrice)}>
                      <div className={cn(detailsStyles.inputContainer, styles.priority)}>
                        <span className={detailsStyles.label}>View Priority</span>
                        <Input readOnly value={form.values[PRIORITY]} />
                      </div>
                      <div className={cn(detailsStyles.inputContainer, styles.price)}>
                        <span className={detailsStyles.label}>Campaign Price</span>
                        <Price value={totalPrice} className={styles.amount} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cn(styles.items)}>
                  <label className={itemsStyles.label}>Added Items</label>
                  <div className={styles.content}>
                    {form.values[ITEMS]?.map((item: ICampaignItem) => {
                      return (
                        <InventoryViewItem
                          item={item}
                          key={item.item_id}
                          className={styles.item}
                          iconClassName={itemsStyles.iconInner}
                          showQuantity
                          showCount={false}
                          isOutlined
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.DSText}>
          <ShippingPageCustomizationForm
            showTitle
            requiredFields={{} as Record<string, boolean>}
            form={form}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignSummaryForm;
