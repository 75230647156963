import { FormikHelpers, useFormik } from 'formik';
import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionButton, AddressSelector, FixedAddressForm } from '../../components';
import { USA } from '../../constants/countries';
import {
  COUNTRY,
  getAddressValidationSchema,
  INITIAL_SHIPPING_ADDRESS_FORM_STATE,
  STATE,
} from '../../constants/shipping';
import { setReceiverAddress } from '../../store/actions/bucket';
import { selectReceiverAddress } from '../../store/selectors/bucket';
import { IOrgAddress } from '../../types/addressBook';
import { ICommonAddress } from '../../types/shipping';
import { getRequiredFields } from '../../utils/form';
import { mapOrgAddressToCommonAddress } from '../../utils/oneLink';
import { ReceiverFixedAddressSidebar } from '../index';

import styles from './ReceiverFixedAddressSidebarContainer.module.scss';

interface IProps {
  onClose: () => void | undefined;
  trigger: boolean;
  addressBook?: IOrgAddress[] | null;
  value?: ICommonAddress;
  onSubmit?: (address: ICommonAddress) => void;
}

const ReceiverFixedAddressSidebarContainer: React.FC<IProps> = ({ onClose, trigger, addressBook, value, onSubmit }) => {
  const dispatch = useDispatch();

  // value and onSubmit presence indicate that we are in the report mode
  const selectedAddress = useSelector(selectReceiverAddress);
  const fixedAddress = value && onSubmit ? value : selectedAddress;

  const validationSchema = React.useMemo(() => getAddressValidationSchema({ strict: true }), []);

  const initialValues = React.useMemo(() => {
    if (!fixedAddress || Object.keys(fixedAddress).length === 0) {
      return INITIAL_SHIPPING_ADDRESS_FORM_STATE;
    }

    return {
      ...INITIAL_SHIPPING_ADDRESS_FORM_STATE,
      ...fixedAddress,
    };
  }, [fixedAddress]);

  const handleSubmit = React.useCallback(
    (values: ICommonAddress, { resetForm }: FormikHelpers<ICommonAddress>) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      onSubmit ? onSubmit(values) : dispatch(setReceiverAddress(values));
      resetForm();
      onClose();
    },
    [dispatch, validationSchema, onClose],
  );

  const form = useFormik<ICommonAddress>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const requiredFields = React.useMemo(
    () => getRequiredFields(validationSchema, form.values[COUNTRY] === USA.two_digit_code ? [STATE] : []),
    [form.values[COUNTRY]],
  );

  const handleSelectAddress = useCallback(
    (v: IOrgAddress) => {
      const candidate = mapOrgAddressToCommonAddress(v);
      if (candidate) {
        form.setValues(candidate);
      }
    },
    [addressBook, form.setValues],
  );

  return (
    <ReceiverFixedAddressSidebar trigger={trigger} onClose={onClose}>
      <AddressSelector selectClassName={styles.addressSelect} value={form.values} onChange={handleSelectAddress} />
      <FixedAddressForm form={form} requiredFields={requiredFields} />
      <div className={styles.controls}>
        <ActionButton className={styles.cancelButton} onClick={onClose}>
          Cancel
        </ActionButton>
        <ActionButton disabled={form.isSubmitting} type="submit" title="Continue" onClick={form.handleSubmit} />
      </div>
    </ReceiverFixedAddressSidebar>
  );
};

export default ReceiverFixedAddressSidebarContainer;
