import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ContinueArrowIcon } from '../../assets/images/icon-next.svg';
import { IS_AI_SUGGEST_ENABLED } from '../../constants/ai';
import { CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID, EMPTY_CUSTOM_SEND } from '../../constants/bucket';
import { DEPARTMENT_IDS, MAX_CAMPAIGN_ITEMS_PER_PAGE } from '../../constants/campaigns';
import { FULFILLMENT_CENTER_ID } from '../../constants/inventories';
import { OUT_OF_DEPARTMENT_MONEY_MESSAGE, OUT_OF_MONEY_MESSAGE } from '../../constants/shell';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useCurrency } from '../../contexts/CurrencyProvider';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useFulfillmentCenterFilter from '../../hooks/useFulfillmentCentersFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { pickYourGiftBoxesConfirmed, preSelectedBoxConfirmed, setPYG, togglePYG } from '../../store/actions/bucket';
import { fetchCampaignsRequest } from '../../store/actions/campaigns';
import { selectAdminType, selectRemainingBudget } from '../../store/selectors/auth';
import { selectIsPYGEnabled, selectPYGCampaignsList } from '../../store/selectors/bucket';
import { selectCampaignsByType, selectCampaignsCount, selectIsFinalCampaign } from '../../store/selectors/campaigns';
import {
  selectCurrentDepartmentRemainingBudget,
  selectIsAIGiftSuggestionEnabled,
  selectIsDepartmentBudgetUnlimited,
} from '../../store/selectors/organizations';
import { selectIsShowDepartmentBudget } from '../../store/selectors/shell';
import { IFlowStepProps } from '../../types/bucket';
import { ICampaign, ICustomSend, IFetchCampaignsRequest } from '../../types/campaigns';

import BoxDetailsSidebar from '../../containers/BoxDetailsSidebar/BoxDetailsSidebar';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import { toggleUiElement } from '../../store/actions/shell';
import { BoxDetailsSidebarModeEnum, NotificationListEnum } from '../../types/shell';
import { isCampaignEligibleToOrder } from '../../utils/campaigns';
import { isSameFC } from '../../utils/inventories';
import notification from '../../utils/notification';
import { hasPermission } from '../../utils/users';
import BoxItem from '../BoxItem/BoxItem';
import { ActionButton, FloatingButton, PickYourGiftButton } from '../forms';
import { CustomSendItem } from '../index';
import PaginatedList, { IPaginatedListChangeEvent, IPaginatedListRef } from '../PaginatedList/PaginatedList';
import { OutOfMoneyToast } from '../toast';

import styles from './BoxList.module.scss';

const BoxList: React.FC<IFlowStepProps> = (flowProps) => {
  const { onBoxSelect, next: nextStep, isBulk, isSingle } = flowProps;

  const dispatch = useDispatch();
  const history = useHistory();
  const { setAdditionalData, setClassName: setHeaderClassName } = useHeaderDispatch();
  const { mobile } = useWindowSize();
  const { getCampaignTotal, getSendTotalPrice } = useCurrency();

  const [selectedBox, setSelectedBox] = useState<ICampaign | undefined>();

  const adminType = useSelector(selectAdminType);
  const isPYGEnabled = useSelector(selectIsPYGEnabled);
  const pygCampaignsList = useSelector(selectPYGCampaignsList);
  const hasDepartmentBudget = useSelector(selectIsShowDepartmentBudget);
  const isDepartmentBudgetUnlimited = useSelector(selectIsDepartmentBudgetUnlimited);
  const departmentBudget = useSelector(selectCurrentDepartmentRemainingBudget);
  const boxItems = useSelector(selectCampaignsByType());
  const boxItemsCount = useSelector(selectCampaignsCount);
  const isFinalCampaign = useSelector(selectIsFinalCampaign);
  const remainingUserBudget = useSelector(selectRemainingBudget);
  const isAIGiftSuggestionEnabled = useSelector(selectIsAIGiftSuggestionEnabled);

  const { SearchInput, query } = useSearchFilter([], [], {
    className: styles.searchByFilterContainer,
    placeholder: 'Lookup campaigns',
    hint: 'Look up specific campaigns by their name.',
  });

  const {
    departments: selectedDepartment,
    DeptFilter,
    filteredItems,
  } = useDeptFilter<ICampaign>((boxItems as ICampaign[]) || [], DEPARTMENT_IDS, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const {
    FulfillmentCenterFilter,
    filteredItems: filteredItemsByFC,
    fulfillmentCenters: selectedFulfillmentCenter,
  } = useFulfillmentCenterFilter<ICampaign>(filteredItems || [], FULFILLMENT_CENTER_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const paginatedListRef = React.useRef<IPaginatedListRef<ICampaign>>(null);

  const shouldShowContactAdminMessage = useMemo(
    () => !hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType),
    [adminType],
  );

  const getRequestInfo = useCallback(
    (payload: IFetchCampaignsRequest) => {
      return {
        ...(selectedDepartment && { department_id: selectedDepartment as string }),
        ...(query && { search_query: query }),
        ...(selectedFulfillmentCenter && { fulfillment_center_id: selectedFulfillmentCenter as string }),
        ...payload,
      };
    },
    [query, selectedFulfillmentCenter, selectedDepartment],
  );

  const fetchCampaigns = useCallback(
    (payload: IFetchCampaignsRequest) => {
      dispatch(fetchCampaignsRequest(getRequestInfo(payload)));
    },
    [dispatch, getRequestInfo],
  );

  const handleCloseSidebar = React.useCallback(() => {
    setSelectedBox(undefined);
    onBoxSelect?.(null);
  }, [onBoxSelect]);

  const setPYGValue = useCallback(
    (campaign: ICampaign | ICustomSend) => {
      if (campaign.box_id !== CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID) {
        dispatch(setPYG(campaign as ICampaign));
      }

      handleCloseSidebar();
    },
    [dispatch, handleCloseSidebar],
  );

  const campaignIsNotEligibleToOrder = React.useMemo(() => {
    return !isCampaignEligibleToOrder(selectedBox?.items);
  }, [selectedBox]);

  const handleTogglePYG = useCallback(() => {
    dispatch(togglePYG(!isPYGEnabled));
  }, [dispatch, isPYGEnabled]);

  const handleToggleSuggestGift = useCallback(() => {
    dispatch(toggleUiElement({ suggestGiftSidenavIsShown: true }));
  }, [dispatch]);

  const setHeaderAdditionalData = useCallback(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(
      <div className={cn(styles.headerAdditionalContent, { [styles.pygEnabled]: isPYGEnabled })}>
        {IS_AI_SUGGEST_ENABLED && isSingle && isAIGiftSuggestionEnabled && (
          <ActionButton
            className={styles.suggestGiftBtn}
            title="Suggest gifts with AI"
            onClick={handleToggleSuggestGift}
          />
        )}
        <PickYourGiftButton className={styles.pygEnableBtn} isEnabled={isPYGEnabled} onClick={handleTogglePYG} />
      </div>,
    );

    // Need to clean additional information after yourself
    return setAdditionalData;
  }, [setAdditionalData, isPYGEnabled, isSingle, handleToggleSuggestGift, handleTogglePYG, isAIGiftSuggestionEnabled]);

  const setMobileHeaderClassName = useCallback(() => {
    if (mobile) {
      setHeaderClassName?.(cn(styles.header, { [styles.enabled]: isPYGEnabled }));
    }

    return setHeaderClassName || undefined;
  }, [setHeaderClassName, mobile, isPYGEnabled]);

  const handleOpenSidebar = React.useCallback(
    (box: ICampaign) => {
      setSelectedBox(box);
      onBoxSelect?.(box);
    },
    [setSelectedBox, onBoxSelect],
  );

  /**
   * Taking price and triggering warning if it is
   * less than department budget or individual budget.
   * Department budget takes precedence of the individual budget
   * @param {number} price - price of chosen campaign or campaigns
   * @return {void}
   */
  const checkBudgets = useCallback(
    (price: number) => {
      if (!isBulk && hasDepartmentBudget && !isDepartmentBudgetUnlimited && price > departmentBudget.amount) {
        notification.outOfMoney(
          <OutOfMoneyToast showContactAdminMessage={shouldShowContactAdminMessage}>
            {OUT_OF_DEPARTMENT_MONEY_MESSAGE}
          </OutOfMoneyToast>,
        );
        return void 0;
      }
      if (remainingUserBudget < price && !isBulk) {
        notification.outOfMoney(
          <OutOfMoneyToast showContactAdminMessage={shouldShowContactAdminMessage}>
            {OUT_OF_MONEY_MESSAGE}
          </OutOfMoneyToast>,
        );
        return void 0;
      }

      return void 0;
    },
    [
      hasDepartmentBudget,
      departmentBudget,
      remainingUserBudget,
      isDepartmentBudgetUnlimited,
      shouldShowContactAdminMessage,
      isBulk,
    ],
  );

  const handleBoxSubmit = React.useCallback(
    (box: ICampaign | ICustomSend) => {
      dispatch(preSelectedBoxConfirmed(box, flowProps));
      checkBudgets(getCampaignTotal(box).price);

      if (nextStep) {
        history.push(nextStep);
        checkBudgets(getCampaignTotal(box).price);
      }
    },
    [dispatch, flowProps, checkBudgets, nextStep, history, getCampaignTotal],
  );

  const handlePYGSubmit = useCallback(() => {
    if (!pygCampaignsList || !pygCampaignsList.length) {
      notification.error(NotificationListEnum.PYGCampaignIsNotSelected, {
        content: 'Cannot proceed with "Pick your gift" option selected with no Campaigns picked.',
      });
      return;
    }

    if (pygCampaignsList.length > 1) {
      const totalCost = getSendTotalPrice({ pick_campaigns: pygCampaignsList });
      checkBudgets(totalCost.price);

      dispatch(pickYourGiftBoxesConfirmed(pygCampaignsList, flowProps));
      if (nextStep) {
        history.push(nextStep);
      }

      return;
    }

    handleBoxSubmit(pygCampaignsList[0]);
  }, [flowProps, pygCampaignsList, handleBoxSubmit, checkBudgets, dispatch, nextStep, history, getSendTotalPrice]);

  const handlePaginationChange = useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchCampaigns({ page, page_size: MAX_CAMPAIGN_ITEMS_PER_PAGE });
      // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
      // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
      setPage(page);
    },
    [fetchCampaigns],
  );

  const handleCustomizeCampaign = React.useCallback(
    ({ items, name, description }: ICampaign) => {
      dispatch(
        preSelectedBoxConfirmed({ name, description, items, box_id: CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID }, flowProps),
      );
      dispatch(toggleUiElement({ boxDetailsSidebarIsShown: true }));
    },
    [dispatch, flowProps],
  );

  const renderBoxItem = useCallback(
    (box: ICampaign) => {
      const { box_id: boxId, items } = box;
      const isBoxDisabled = !isSameFC(items) || (!isCampaignEligibleToOrder(items) && isPYGEnabled);

      return (
        <div className={styles.itemWrapper} key={boxId}>
          <BoxItem
            key={boxId}
            box={box}
            onClick={() => handleOpenSidebar(box)}
            onSelect={isPYGEnabled ? () => setPYGValue(box) : undefined}
            selected={pygCampaignsList?.some(({ box_id: pygCampaignBoxId }) => pygCampaignBoxId === boxId)}
            className={styles.item}
            iconClassName={styles.icon}
            withPreview
            withWarning
            isDisabled={isBoxDisabled}
          />
        </div>
      );
    },
    [isPYGEnabled, pygCampaignsList, handleOpenSidebar, setPYGValue],
  );

  const handleCustomBoxClick = React.useCallback(() => {
    dispatch(preSelectedBoxConfirmed(EMPTY_CUSTOM_SEND, flowProps));
  }, [dispatch, flowProps]);

  useEffect(() => {
    fetchCampaigns({ page: 1, page_size: MAX_CAMPAIGN_ITEMS_PER_PAGE });
  }, [fetchCampaigns]);

  useEffect(() => {
    return setHeaderAdditionalData();
  }, [setHeaderAdditionalData]);

  useEffect(() => {
    return setMobileHeaderClassName();
  }, [setMobileHeaderClassName]);

  const buttonTitle = React.useMemo(() => {
    if (campaignIsNotEligibleToOrder) {
      return 'Edit Send';
    }

    if (isPYGEnabled) {
      const isBoxSelected = pygCampaignsList?.some((box) => box.box_id === selectedBox?.box_id);
      return isBoxSelected ? 'Remove campaign' : 'Add campaign';
    }

    return 'Continue';
  }, [isPYGEnabled, pygCampaignsList, selectedBox, campaignIsNotEligibleToOrder]);

  const renderActionButtons = React.useMemo(() => {
    if (selectedBox) {
      const handleClick = (() => {
        if (campaignIsNotEligibleToOrder) {
          return handleCustomizeCampaign;
        }
        return isPYGEnabled ? setPYGValue : handleBoxSubmit;
      })();

      return [
        <ActionButton
          className={styles.continueButton}
          onClick={() => handleClick(selectedBox)}
          title={buttonTitle}
          key="continueButton"
          disabled={!Boolean(selectedBox && selectedBox.items && selectedBox.items.length)}
        />,
      ];
    }
  }, [
    selectedBox,
    isPYGEnabled,
    setPYGValue,
    handleBoxSubmit,
    buttonTitle,
    campaignIsNotEligibleToOrder,
    handleCustomizeCampaign,
  ]);

  return (
    <div className={styles.boxList}>
      <div className={styles.wrapper}>
        <div className={styles.filter}>
          {SearchInput}
          <div className={styles.commonFilters}>
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) ? DeptFilter({}) : null}
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN, DEPT_ADMIN], adminType)
              ? FulfillmentCenterFilter({ isFiltered: true })
              : null}
          </div>
        </div>
      </div>
      <PaginatedList<ICampaign>
        totalCount={boxItemsCount}
        ref={paginatedListRef}
        items={filteredItemsByFC || ([] as ICampaign[])}
        pageSize={MAX_CAMPAIGN_ITEMS_PER_PAGE}
        onChange={handlePaginationChange}
        isFinalItems={isFinalCampaign}
      >
        {({ items, pagination: Pagination }) => (
          <>
            <div className={styles.listRow}>
              {!isPYGEnabled && (
                <div className={styles.itemWrapper}>
                  <CustomSendItem className={styles.item} onClick={handleCustomBoxClick} />
                </div>
              )}
              {items && items.length ? items.map(renderBoxItem) : null}
            </div>
            <Pagination className={styles.pagination} />
          </>
        )}
      </PaginatedList>
      <FloatingButton
        className={styles.pygBtnWrapper}
        buttonClassName={styles.pygBtn}
        trigger={isPYGEnabled}
        disabled={!pygCampaignsList?.length}
        onClick={handlePYGSubmit}
      >
        <ContinueArrowIcon className={styles.btnIcon} />
        <span className={styles.btnText}>Continue</span>
      </FloatingButton>
      <BoxDetailsSidebar
        onSubmit={isPYGEnabled ? setPYGValue : handleBoxSubmit}
        onClose={handleCloseSidebar}
        isBoxSelected={pygCampaignsList?.some((box) => box.box_id === selectedBox?.box_id)}
        box={selectedBox}
        isPYGEnabled={isPYGEnabled}
        actionButtons={renderActionButtons}
        mode={BoxDetailsSidebarModeEnum.View}
        onCustomize={handleCustomizeCampaign}
      />
    </div>
  );
};

export default BoxList;
