import * as React from 'react';

import { IOrgAddress } from '../../types/addressBook';
import { ICommonAddress } from '../../types/shipping';
import { isObjectEmpty } from '../../utils/helpers';
import { CompactOrgAddressView } from '../index';

import styles from './SingleAddress.module.scss';

interface IProps {
  address?: IOrgAddress | ICommonAddress;
  onReset?: () => void;
}

export const SingleAddress: React.FC<IProps> = ({ address, onReset }) => {
  if (!address || isObjectEmpty(address)) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <CompactOrgAddressView
        row={address}
        addressClassName={styles.address}
        onReset={onReset}
        className={styles.addressContainer}
      />
    </div>
  );
};

export default SingleAddress;
