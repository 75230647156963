import cn from 'classnames';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BookIcon } from '../../../assets/images/icon-book.svg';

import {
  ActionButton,
  AddressBookSidebar,
  InputLabel,
  RecipientShippingForm,
  Selector,
  SingleAddress,
  Toggle,
} from '../../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { AWAITING_ADDRESS, INITIAL_SHIPPING_ADDRESS_FORM_STATE } from '../../../constants/shipping';
import { fetchAddressBookRequest } from '../../../store/actions/addressBook';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { ISelectorValue, UISizeEnum } from '../../../types/shell';
import { IShippingDetails } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';
import { mapOrgAddressToCommonAddress } from '../../../utils/oneLink';
import { AddressBookSidebarContainer } from '../../index';
import { AddressPreview, Message } from '../common';
import { SidebarModesEnum } from '../types';
import { getInitialSelectorState, getSelectOptions } from '../utils';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  className?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  requiredFields?: Record<string, boolean>;
  isForcedDS?: boolean;
  onDSChange?: (isDS: boolean) => void;
}

const SingleSendFlow = ({ className, isReadOnly, isDisabled, requiredFields, isForcedDS, onDSChange }: IProps) => {
  const form = useFormikContext<IShippingDetails>();

  const dispatch = useDispatch();

  const addressBook = useSelector(selectAddressBook);
  const { org_address_ids, is_receiver_address_fixed, receiver_address, ship_order_status } = useMemo(
    () => form.values,
    [form.values],
  );

  const isDelayedShipping = ship_order_status === AWAITING_ADDRESS;

  const [sidebarMode, setSidebarMode] = useState<SidebarModesEnum>(SidebarModesEnum.None);
  const [selectValue, setSelectValue] = useState<ShippingFlowSelectorOptionsEnum>(
    getInitialSelectorState({
      is_receiver_address_fixed,
      address: receiver_address,
      is_delayed_shipping: isDelayedShipping,
    }),
  );

  const isFixedSelectorValue = useMemo(() => {
    return selectValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectValue]);

  const isRecipientDecidesFromListSelectorValue = useMemo(
    () => selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList,
    [selectValue],
  );

  const isRecipientDecidesSelectorValue = useMemo(
    () => selectValue === ShippingFlowSelectorOptionsEnum.RecipientDecides,
    [selectValue],
  );

  const isEmptyRecipientAddress = useMemo(
    () => isObjectEmpty(receiver_address) || (receiver_address && isObjectEmpty(receiver_address)),
    [receiver_address],
  );

  const options = useMemo(
    () => getSelectOptions(isDelayedShipping, isDelayedShipping && !isForcedDS),
    [isDelayedShipping, isForcedDS],
  );

  const handleSelectChange = useCallback(
    async (newValue: ShippingFlowSelectorOptionsEnum, isDS?: boolean) => {
      const newShipOrderStatus = (() => {
        if (typeof isDS !== 'undefined') {
          return isDS ? AWAITING_ADDRESS : '';
        }

        return ship_order_status;
      })();

      let updates: IShippingDetails;
      switch (newValue) {
        case ShippingFlowSelectorOptionsEnum.Fixed: {
          if (org_address_ids?.length === 1) {
            const addressHit = addressBook?.find((a) => a.uid === org_address_ids[0]);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: mapOrgAddressToCommonAddress(addressHit),
              ship_order_status: newShipOrderStatus,
            };
          } else {
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address,
              ship_order_status: newShipOrderStatus,
            };
          }
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecides: {
          updates = {
            is_receiver_address_fixed: false,
            receiver_address,
            ship_order_status: newShipOrderStatus,
            org_address_ids,
          };
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList: {
          updates = {
            is_receiver_address_fixed: true,
            receiver_address: undefined,
            org_address_ids: !!org_address_ids?.length ? org_address_ids : [],
            ship_order_status: newShipOrderStatus,
          };
          break;
        }
        default:
          return;
      }
      setSelectValue(newValue);
      await form.setValues({ ...form.values, ...updates });
    },
    [form.setValues, form.values, addressBook, org_address_ids, ship_order_status, receiver_address],
  );

  const handleDSChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const isNextDelayedShipping = e.currentTarget.checked;

      await handleSelectChange(
        isNextDelayedShipping
          ? ShippingFlowSelectorOptionsEnum.RecipientDecides
          : ShippingFlowSelectorOptionsEnum.Fixed,
        isNextDelayedShipping,
      );
    },
    [handleSelectChange],
  );

  const handleCloseSidebar = useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const handleAddressBookSubmit = useCallback(
    async (addressIds?: string[]) => {
      await form.setValues({
        ...form.values,
        org_address_ids: addressIds,
      });
    },
    [form.setValues, form.values],
  );

  const handlePickSingleAddress = useCallback(
    async ([firstId]: string[]) => {
      const addressHit = mapOrgAddressToCommonAddress(addressBook?.find((a) => a.uid === firstId));

      if (addressHit) {
        await form.setFieldValue('receiver_address', addressHit);
      }

      handleCloseSidebar();
    },
    [addressBook, handleCloseSidebar],
  );

  const handleResetSingleAddress = () => {
    form.resetForm({
      values: {
        ...form.values,
        receiver_address: INITIAL_SHIPPING_ADDRESS_FORM_STATE,
      },
    });
  };

  const isAddressFormShown = useMemo(
    () =>
      [ShippingFlowSelectorOptionsEnum.Fixed, ShippingFlowSelectorOptionsEnum.RecipientDecides].includes(
        selectValue as ShippingFlowSelectorOptionsEnum,
      ),
    [selectValue],
  );

  const actionButtonTitle = useMemo(() => {
    const hasAddresses = org_address_ids && org_address_ids.length !== 0;

    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientAddress ? 'Edit' : 'Select'} Fixed Address`;
      case isRecipientDecidesFromListSelectorValue || isRecipientDecidesSelectorValue:
        return `${hasAddresses ? 'Edit' : 'Select'} Address Book Options`;
      default:
        return 'Select Address Book Option';
    }
  }, [
    isEmptyRecipientAddress,
    isRecipientDecidesSelectorValue,
    isFixedSelectorValue,
    isRecipientDecidesFromListSelectorValue,
    org_address_ids,
  ]);

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  useEffect(() => {
    if (onDSChange) {
      onDSChange(form.values.ship_order_status === AWAITING_ADDRESS);
    }
  }, [form.values.ship_order_status, onDSChange]);

  return (
    <div className={cn(styles.container, styles.nested, className)}>
      <h2 className={styles.title}>Shipping</h2>
      <Toggle
        className={styles.toggle}
        sliderClassName={styles.toggleSlider}
        onChange={!isReadOnly ? handleDSChange : undefined}
        value={isDelayedShipping}
        checked={isDelayedShipping}
        defaultValue={false}
        isReadOnly={isReadOnly}
        disabled={isDisabled || isForcedDS}
      >
        Claim Link
      </Toggle>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={options.find((option) => option.value === selectValue)}
        onChange={(v) => handleSelectChange((v as ISelectorValue).value as ShippingFlowSelectorOptionsEnum)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={isReadOnly}
        isDisabled={isDisabled}
      />
      {!isDisabled && !isReadOnly && (
        <ActionButton
          className={styles.addressBookBtn}
          outlined
          icon={<BookIcon className={styles.icon} />}
          title={actionButtonTitle}
          size={UISizeEnum.Normal}
          disabled={isDisabled || isReadOnly}
          onClick={() =>
            setSidebarMode(isFixedSelectorValue ? SidebarModesEnum.FixedAddress : SidebarModesEnum.AddressBook)
          }
        />
      )}
      <AddressPreview addresses={org_address_ids} forceAddressList />
      {isReadOnly
        ? !isEmptyRecipientAddress && (
            <>
              <InputLabel value="Pre-populated Address" />
              <SingleAddress address={form.values.receiver_address} />
            </>
          )
        : isAddressFormShown && (
            <>
              <p className={styles.formTitle}>
                {isRecipientDecidesSelectorValue
                  ? 'And/Or pre-populate an address:'
                  : 'Or enter delivery address below:'}
              </p>
              <RecipientShippingForm
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
                requiredFields={requiredFields}
                onReset={!isObjectEmpty(form.values.receiver_address) ? handleResetSingleAddress : undefined}
              />
            </>
          )}
      <Message selectValue={selectValue} addresses={org_address_ids} />
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode !== SidebarModesEnum.None}>
        <AddressBookSidebarContainer
          onClose={handleCloseSidebar}
          addressIds={org_address_ids}
          isSidebarOpen
          {...(sidebarMode === SidebarModesEnum.FixedAddress
            ? { onSelect: handlePickSingleAddress, onReset: handleResetSingleAddress }
            : { onSubmit: handleAddressBookSubmit, onReset: handleAddressBookSubmit })}
        />
      </AddressBookSidebar>
    </div>
  );
};

export default SingleSendFlow;
