import cn from 'classnames';
import { useField } from 'formik';
import { useCallback } from 'react';
import * as React from 'react';

import { ActionButton, CountrySelector } from '../../../components/index';
import { ISelectorValue } from '../../../types/shell';
import { Input } from '../inputs';

import styles from './ShippingForm.module.scss';

interface IProps {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  className?: string;
  requiredFields?: Record<string, boolean>;
  onReset?: () => void;
}

const RecipientShippingForm = ({ isDisabled, isReadOnly, className, requiredFields = {}, onReset }: IProps) => {
  const address1Field = useField('receiver_address.address1');
  const address2Field = useField('receiver_address.address2');
  const stateField = useField('receiver_address.state');
  const cityField = useField('receiver_address.city');
  const countryField = useField('receiver_address.country');
  const zipField = useField('receiver_address.zip');

  const getError = useCallback((field: ReturnType<typeof useField>): string | null => {
    const [_, { touched, error }] = field;
    return touched && error ? error : null;
  }, []);

  const getSelectInputProps = useCallback((field: ReturnType<typeof useField>) => {
    const [{ name, value, onChange, onBlur }] = field;

    return {
      name,
      value,
      onChange: (_: string, selected: ISelectorValue | null) => {
        const evt = { target: { name, value: selected ? selected.value : '' } } as React.ChangeEvent<HTMLInputElement>;

        onChange(evt);
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur({ target: { name, value: e.target.value } } as React.FocusEvent<HTMLInputElement>);
      },
    };
  }, []);

  const getRequiredField = useCallback(
    (field: ReturnType<typeof useField>) => {
      return requiredFields[field[0].name];
    },
    [requiredFields],
  );

  return (
    <div role="form" className={cn(styles.container, className)}>
      <Input
        field={address1Field[0]}
        helperText="Address 1"
        className={cn(styles.inputContainer, styles.fullWidth)}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getError(address1Field) })}
        placeholder="Address 1"
        disabled={isDisabled}
        required={getRequiredField(address1Field)}
        error={getError(address1Field)}
        readOnly={isReadOnly}
      />
      <Input
        field={address2Field[0]}
        helperText="Address 2"
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getError(address2Field) })}
        placeholder="Address 2"
        disabled={isDisabled}
        required={getRequiredField(address2Field)}
        error={getError(address2Field)}
        readOnly={isReadOnly}
      />
      <Input
        field={cityField[0]}
        helperText="City"
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getError(cityField) })}
        placeholder="City"
        disabled={isDisabled}
        required={getRequiredField(cityField)}
        error={getError(cityField)}
        readOnly={isReadOnly}
      />
      <Input
        field={stateField[0]}
        helperText="State"
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getError(stateField) })}
        placeholder="State"
        disabled={isDisabled}
        required={getRequiredField(stateField)}
        error={getError(stateField)}
        readOnly={isReadOnly}
      />
      <Input
        field={zipField[0]}
        helperText="ZIP Code"
        className={styles.inputContainer}
        inputClassName={cn(styles.input, { [styles.errorBorder]: !!getError(zipField) })}
        placeholder="ZIP Code"
        disabled={isDisabled}
        required={getRequiredField(zipField)}
        error={getError(zipField)}
        readOnly={isReadOnly}
      />
      <CountrySelector
        {...getSelectInputProps(countryField)}
        containerClassName={cn(styles.inputContainer, styles.inputWidth)}
        className={cn(styles.countrySelect, { [styles.errorBorder]: !!getError(countryField) })}
        label="Country"
        required={getRequiredField(countryField)}
        error={getError(countryField)}
        disabled={isDisabled}
        readOnly={isReadOnly}
      />
      {onReset && (
        <div className={styles.resetContainer}>
          <ActionButton title="Reset" inline className={styles.resetBtn} type="reset" onClick={onReset} />
        </div>
      )}
    </div>
  );
};

export default RecipientShippingForm;
