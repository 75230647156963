import { Formik } from 'formik';
import * as React from 'react';

import { TEngagementCandidate } from '../../../types/bucket';
import { IReportWithCampaignSummaries } from '../../../types/reports';
import { IShippingDetails } from '../../../types/shipping';
import SingleSendFlow from './SingleSendFlow';

interface IProps {
  instance: TEngagementCandidate | IReportWithCampaignSummaries;
}

const SummaryWidget = ({ instance }: IProps) => {
  const {
    is_receiver_address_fixed = false,
    org_address_ids,
    ship_order_status = '',
    receiver_address1: address1 = '',
    receiver_address2: address2,
    receiver_city: city = '',
    receiver_country: country = '',
    receiver_state: state = '',
    receiver_zip: zip = '',
  } = instance;

  const initialValues: IShippingDetails = {
    is_receiver_address_fixed,
    org_address_ids: org_address_ids as string[] | undefined,
    ship_order_status,
    receiver_address: {
      address1,
      address2,
      city,
      country,
      state,
      zip,
    },
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => void 0}>
      <SingleSendFlow isReadOnly />
    </Formik>
  );
};

export default SummaryWidget;
