import cn from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SearchIcon } from '../../../../assets/images/icon-search.svg';
import { INTEGRATION_WARNINGS_INFO } from '../../../../constants/integrationWarnings';
import { CRM_TYPE } from '../../../../constants/shipping';
import useModal from '../../../../hooks/useModal';
import {
  clearSearchItems,
  crmCheckStart,
  crmOAuthStart,
  searchLeadsAndContactsRequest,
} from '../../../../store/actions/integrations';
import {
  selectCRMIsLoading,
  selectCrmSearchItems,
  selectIsCRMAuthenticated,
  selectIsQueryInProgress,
} from '../../../../store/selectors/integrations';
import {
  selectCurrentOrganizationOptions,
  selectIsCurrentOrganizationSupportedAnyCrm,
} from '../../../../store/selectors/organizations';
import { ISearchItem } from '../../../../types/crm';
import { IApiError, NotificationListEnum } from '../../../../types/shell';
import notification from '../../../../utils/notification';
import { ActionButton, IntegrationWarningModal, LeadsAndContactsList, LookupSearch } from '../../../index';

import styles from './LookupCrm.module.scss';

interface IProps {
  className?: string;
  isRequired?: boolean;
  onWarningClose?: () => void;
  onSelect: (item: ISearchItem) => Promise<void>;
}

const LookupCrm = ({ className, isRequired, onWarningClose, onSelect }: IProps) => {
  const [query, setQuery] = React.useState('');
  const controllerRef = React.useRef<AbortController | null>(null);
  const dispatch = useDispatch();

  const { crm_currently_supports: crmType } = useSelector(selectCurrentOrganizationOptions) || {};
  const isCRMEnabled = useSelector(selectIsCurrentOrganizationSupportedAnyCrm);
  const isCRMFetching = useSelector(selectCRMIsLoading);
  const isQueryInProgress = useSelector(selectIsQueryInProgress);
  const isAuthenticatedToCRM = useSelector(selectIsCRMAuthenticated);
  const crmContacts = useSelector(selectCrmSearchItems);

  const {
    isOpen: isWarningModalOpen,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
    Modal: WarningModal,
  } = useModal({ required: true });

  const crmCheck = React.useCallback(() => {
    if (isRequired) {
      return;
    }
    dispatch(crmCheckStart());
  }, [dispatch, isRequired]);

  const searchLeadsAndContacts = React.useCallback(
    debounce((name: string) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
        controllerRef.current = null;
      }

      if (name && name.length >= 2) {
        controllerRef.current = new AbortController();
        return new Promise<ISearchItem[]>((resolve, reject) => {
          dispatch(
            searchLeadsAndContactsRequest({
              query: name,
              limit: 20,
              [CRM_TYPE]: crmType,
              signal: controllerRef.current?.signal,
              resolve,
              reject,
            }),
          );
        }).finally(() => {
          controllerRef.current = null;
        });
      }
    }, 1000),
    [dispatch, crmType],
  );
  const clearSearchResults = React.useCallback(() => {
    dispatch(clearSearchItems());
  }, [dispatch]);

  const handleWarningClose = React.useCallback(() => {
    if (!isRequired && onWarningClose) {
      onWarningClose();
    }

    closeWarningModal();
  }, [closeWarningModal, onWarningClose, isRequired]);

  const addCrmIntegration = React.useCallback(() => {
    const crmIntegrationPromise = new Promise<void>((resolve, reject) => {
      dispatch(crmOAuthStart({ resolve, reject }));
    });

    notification.promise<void | IApiError>(NotificationListEnum.AddIntegrationPending, {
      promise: crmIntegrationPromise,
      promiseParams: {
        pending: 'Integration process started. We’ll notify you when it is ready!',
        success: 'The integration was added successfully!',
        error: 'Something went wrong. An error has occurred.',
      },
    });
  }, [dispatch]);

  const warningModal = React.useMemo(() => {
    const { icon, description } = crmType
      ? INTEGRATION_WARNINGS_INFO[crmType]
      : { icon: null as unknown as React.FC<any>, description: null };

    const action = (
      <ActionButton
        title="Continue"
        onClick={() => {
          addCrmIntegration();
          handleWarningClose();
        }}
      />
    );

    return (
      <WarningModal className="integration-warning">
        {() => (
          <IntegrationWarningModal onClose={handleWarningClose} icon={icon} description={description} action={action} />
        )}
      </WarningModal>
    );
  }, [handleWarningClose, WarningModal]);

  const handleLookupInputClick = React.useCallback(() => {
    if (isRequired && !isAuthenticatedToCRM) {
      openWarningModal();
    }
  }, [isRequired, isAuthenticatedToCRM]);

  const handleSelect = React.useCallback(
    async (item: ISearchItem) => {
      await onSelect(item).then(clearSearchResults);
    },
    [onSelect, clearSearchResults],
  );

  React.useEffect(() => {
    if (isRequired) {
      return;
    }
    crmCheck();
  }, [crmCheck, isRequired]);

  React.useEffect(() => {
    if (
      !isWarningModalOpen &&
      isCRMEnabled &&
      isAuthenticatedToCRM !== undefined &&
      !isAuthenticatedToCRM &&
      !isRequired
    ) {
      openWarningModal();
    }
  }, [openWarningModal, isWarningModalOpen, isCRMEnabled, isAuthenticatedToCRM, isRequired]);

  React.useEffect(() => {
    searchLeadsAndContacts(query);

    return clearSearchResults;
  }, [query, searchLeadsAndContacts, clearSearchResults]);

  return isCRMEnabled ? (
    <>
      {warningModal}
      <LookupSearch
        onClick={handleLookupInputClick}
        containerClassName={cn(styles.lookUpSearch, className)}
        value={query}
        onFocus={(e, value) => setQuery(value)}
        onChange={(e, value) => setQuery(value)}
        placeholder="Lookup Contact"
        disabled={isCRMFetching || (isRequired && !isAuthenticatedToCRM)}
        isLoading={isQueryInProgress}
        icon={<SearchIcon />}
        result={() => <LeadsAndContactsList onSelect={handleSelect} items={crmContacts} />}
      />
    </>
  ) : null;
};

export default LookupCrm;
