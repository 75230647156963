import * as yup from 'yup';

import { AutomatedCampaignTypeEnum, AutomatedConnectionTypeEnum, AutomationStatusEnum } from '../types/automations';
import { ISelectorValue } from '../types/shell';

export const NAME = 'name';
export const TOKEN = 'token';
export const TYPE = 'type';
export const ANNIVERSARY_YEAR = 'anniversary_year';
export const CONNECTION_ID = 'connection_id';
export const CAMPAIGN_ID = 'campaign_id';
export const SENDER_ID = 'sender_id';
export const CAMPAIGN_IDS = 'campaign_ids';
export const UID = 'uid';
export const STATUS = 'status';
export const NEW_ID = 'new';

export const AUTOMATION_FORM_FIELDS_MAP: { [k: string]: string } = {
  [NAME]: 'Name',
  [TOKEN]: 'API Key',
  [TYPE]: 'Type',
  [CONNECTION_ID]: 'Connection',
  [SENDER_ID]: 'Sender',
  [CAMPAIGN_IDS]: 'Campaigns',
  [ANNIVERSARY_YEAR]: 'Anniversary Year',
};

export const AUTOMATED_CONNECTION_VALIDATION_SCHEMA = yup.object().shape({
  [NAME]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[NAME]).required(),
  [TYPE]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[TYPE]).required(),
  [TOKEN]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[TOKEN]).required(),
});

export const AUTOMATED_CAMPAIGNS_VALIDATION_SCHEMA = yup.object().shape({
  [NAME]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[NAME]).required(),
  [UID]: yup.string(),
  [TYPE]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[TYPE]).required(),
  [ANNIVERSARY_YEAR]: yup.number().when(TYPE, {
    is: AutomatedCampaignTypeEnum.Anniversary,
    then: (sc) => sc.label(AUTOMATION_FORM_FIELDS_MAP[ANNIVERSARY_YEAR]).required(),
    otherwise: (sc) => sc.label(AUTOMATION_FORM_FIELDS_MAP[ANNIVERSARY_YEAR]),
  }),
  [CONNECTION_ID]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[CONNECTION_ID]).required(),
  [SENDER_ID]: yup.string().label(AUTOMATION_FORM_FIELDS_MAP[SENDER_ID]).required(),
  [CAMPAIGN_IDS]: yup.array().of(yup.string()).label(AUTOMATION_FORM_FIELDS_MAP[CAMPAIGN_IDS]).required(),
});

export const AUTOMATED_CONNECTION_INITIAL_VALUES = {
  [NAME]: '',
  [TOKEN]: '',
  [TYPE]: undefined,
  [STATUS]: AutomationStatusEnum.Active,
};

export const AUTOMATED_CAMPAIGNS_INITIAL_VALUES = {
  [NAME]: '',
  [TYPE]: undefined,
  [ANNIVERSARY_YEAR]: 1,
  [CONNECTION_ID]: undefined,
  [SENDER_ID]: undefined,
  [CAMPAIGN_IDS]: [],
  [STATUS]: AutomationStatusEnum.Active,
};

export const CONNECTION_TYPES_SELECT_OPTIONS: ISelectorValue[] = [
  {
    label: 'HR',
    value: AutomatedConnectionTypeEnum.HR,
  },
  {
    label: 'Recruiting (coming soon)',
    value: AutomatedConnectionTypeEnum.Recruiting,
    disabled: true,
  },
];

export const CAMPAIGN_TYPES_SELECT_OPTIONS: ISelectorValue[] = [
  {
    label: 'New Hire',
    value: AutomatedCampaignTypeEnum.NewHire,
  },
  {
    label: 'Birthday',
    value: AutomatedCampaignTypeEnum.Birthday,
  },
  {
    label: 'Anniversary',
    value: AutomatedCampaignTypeEnum.Anniversary,
  },
];

export const CONNECTION_DEACTIVATE_TOOLTIP_TEXT: string =
  'This will temporarily deactivate the connection for as long as it is turned on. Turn off to resume the connection.\n\nAny campaigns that depend upon the connection will also temporarily be deactivated. Any scheduled sends will continue to send.';

export const CAMPAIGN_DEACTIVATE_TOOLTIP_TEXT =
  'This will temporarily deactivate the automated campaign for as long as it is turned on. Turn off to resume the automated campaign.';

export const CAMPAIGNS_TOOLTIP_TEXT = 'Select one or more campaigns to send for this type of automated campaign.';

export const CONNECTION_TOOLTIP_TEXT = 'Choose a connection that should be used to get employee information.';

export const SENDER_TOOLTIP_TEXT = 'Choose a user to be the campaign sender.';

export const ANNIVERSARY_YEAR_TOOLTIP_TEXT =
  'Anniversary year is available when the corresponding campaign type is selected.';

export const API_KEY_TOOLTIP_TEXT: string =
  'This will be a unique key generated from your merge.dev account and is required to create campaigns.';

export const AUTOMATED_CONNECTION_DELETE_TEXT: string[] = [
  'All campaigns related to the connection will automatically be deactivated.',
  'Scheduled sends will not be affected.',
];

export const AUTOMATED_CAMPAIGN_DELETE_TEXT: string[] = [
  'You will need to create a new campaign to continue automatic sends.',
  'Already scheduled sends will not be affected.',
];
