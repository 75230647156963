import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';

export enum ActionTypesEnum {
  changeDS = 'changeDS',
  changeSelect = 'changeSelect',
  changeAB = 'changeAB',
  reset = 'reset',
}

export interface IAction<T = undefined> {
  type: ActionTypesEnum;
  payload: T;
  metadata?: any;
}

type TChangeDSAction = IAction<boolean> & { type: ActionTypesEnum.changeDS };
type TChangeSelectAction = IAction<ShippingFlowSelectorOptionsEnum> & { type: ActionTypesEnum.changeSelect };
type TChangeABAction = IAction<string[]> & { type: ActionTypesEnum.changeAB };
type TResetAction = IAction<undefined> & { type: ActionTypesEnum.reset };

export type TAction = TChangeDSAction | TChangeSelectAction | TChangeABAction | TResetAction;

export const changeDS = (payload: boolean): TChangeDSAction => ({
  type: ActionTypesEnum.changeDS,
  payload,
});

export const changeSelect = (payload: ShippingFlowSelectorOptionsEnum): TChangeSelectAction => ({
  type: ActionTypesEnum.changeSelect,
  payload,
});

export const changeAB = (payload: string[]): TChangeABAction => ({
  type: ActionTypesEnum.changeAB,
  payload,
});

export const reset = (): TResetAction => ({
  type: ActionTypesEnum.reset,
  payload: undefined,
});
