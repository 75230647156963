import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { ReactComponent as PYGIcon } from '../../assets/images/icon-pick-your-gift.svg';
import { UserInformation } from '../../components';
import { routes, SupportedFlowsEnum, URL_VARS } from '../../constants/routing';
import { FIRST_NAME, LAST_NAME } from '../../constants/users';
import {
  AddBoxContainer,
  AddEmailContainer,
  AddNoteContainer,
  EngagementSummaryContainer,
  ShippingStepContainer,
  UploadCSVContainer,
} from '../../containers';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useFlowGuard from '../../hooks/useFlowGuard';
import useScrollTop from '../../hooks/useScrollTop';
import { flushBucket, startEngagementFlow } from '../../store/actions/bucket';
import { fetchUsersRequest } from '../../store/actions/users';
import { selectFirstName } from '../../store/selectors/auth';
import {
  selectBucketItems,
  selectIsPYGEnabled,
  selectIsSOBEnabled,
  selectPYGCampaignsList,
  selectShouldStartFromScratch,
  selectSOBUser,
} from '../../store/selectors/bucket';
import { selectIsUsersFetching, selectUsers } from '../../store/selectors/users';
import { checkPygHasMskuItem, hasCustomizableItem, hasMskuItem } from '../../utils/inventories';

import headerStyles from '../../components/Header/Header.module.scss';
import styles from './Engagement.module.scss';

const Engagement: React.FC = () => {
  const {
    flowId = SupportedFlowsEnum.Single,
    stepId,
    isSingle,
    isBulk,
    isOneLink,
    isDirectSend,
    isCustomSend,
    next,
    prev,
    onBoxSelect,
    onDelayedShippingChange,
  } = useFlowGuard(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const { setAction: setHeaderAction, setTitle } = useHeaderDispatch();
  const shouldStartFromScratch = useSelector(selectShouldStartFromScratch(flowId));
  const isSOBEnabled = useSelector(selectIsSOBEnabled);
  const users = useSelector(selectUsers);
  const SOBUser = useSelector(selectSOBUser);
  const isPYG = useSelector(selectIsPYGEnabled);
  const firstName = useSelector(selectFirstName);
  const isUsersLoading = useSelector(selectIsUsersFetching);

  const bucketItems = useSelector(selectBucketItems);
  const pygCampaigns = useSelector(selectPYGCampaignsList);

  const hasMsku = React.useMemo(() => {
    if (isPYG) {
      return checkPygHasMskuItem(pygCampaigns);
    }

    return hasMskuItem(bucketItems);
  }, [bucketItems, pygCampaigns, isPYG]);

  const hasCustomizableItems = React.useMemo(() => hasCustomizableItem(bucketItems), [bucketItems]);

  const rootElement = React.useRef<HTMLDivElement>(null);

  const SenderInfo = React.useMemo(() => {
    if (SOBUser) {
      return (
        <div className={styles.sendOnBehalf}>
          <span className={styles.label}>Sending on behalf of</span>
          <UserInformation
            wrapperClassName={styles.avatarContainer}
            name={`${SOBUser[FIRST_NAME]} ${SOBUser[LAST_NAME]}`}
            avatarImageURL={SOBUser?.picture}
          />
        </div>
      );
    }
    return null;
  }, [SOBUser]);

  const flowSteps = React.useMemo(
    () => ({
      [URL_VARS.BOX]: AddBoxContainer,
      [URL_VARS.SHIPPING]: ShippingStepContainer,
      [URL_VARS.NOTE]: AddNoteContainer,
      [URL_VARS.EMAIL]: AddEmailContainer,
      [URL_VARS.SUMMARY]: EngagementSummaryContainer,
      [URL_VARS.UPLOAD]: UploadCSVContainer,
    }),
    [],
  );

  const watchFlow = React.useCallback(() => {
    if (shouldStartFromScratch) {
      dispatch(flushBucket());
      dispatch(startEngagementFlow(flowId));
    }
  }, [dispatch, shouldStartFromScratch, flowId]);

  useScrollTop(stepId, rootElement);

  React.useEffect(() => {
    watchFlow();
  }, [watchFlow]);

  React.useEffect(() => {
    const excludeRules = [
      // 1. Action for the CustomBox flow is set in the CustomBoxContainer
      // because it's related to the container inner logic and not used anywhere else
      isCustomSend && stepId === URL_VARS.BOX,
    ];

    if (typeof setHeaderAction === 'function' && !excludeRules.every((rule) => rule)) {
      setHeaderAction(() => {
        if (prev) {
          history.push(prev);
        } else if (history.length) {
          history.goBack();
        } else {
          history.push(routes.dashboard);
        }
      });
    }
  }, [stepId, isCustomSend, prev, history, setHeaderAction]);

  React.useEffect(() => {
    if (typeof setTitle === 'function') {
      let title;
      const icon = isPYG && <PYGIcon key="pyg-icon" className={styles.pygIcon} />;

      switch (stepId) {
        case URL_VARS.BOX:
          title = isCustomSend
            ? [`Add items to the send${firstName ? `, ${firstName}` : ''}!`]
            : [`Pick your send${firstName ? `, ${firstName}` : ''}!`];
          break;
        case URL_VARS.SHIPPING:
          let heading = '';
          if (isSingle || isDirectSend) {
            heading = 'Shipment information';
          }
          if (isOneLink) {
            heading = 'Configurations';
          }
          title = [heading, icon];
          break;
        case URL_VARS.NOTE:
          title = [
            `${
              firstName ? `${firstName}, include a custom message (optional)` : 'Include a custom message (optional)'
            }`,
            icon,
          ];
          break;
        case URL_VARS.EMAIL:
          title = [`Write your email${firstName ? `, ${firstName}` : ''}!`, icon];
          break;
        case URL_VARS.UPLOAD:
          title = ['Upload CSV file', icon];
          break;
        case URL_VARS.SUMMARY:
          title = ['Send summary', icon];
          break;
        default:
          break;
      }
      setTitle(<h1 className={headerStyles.title}>{title}</h1>);
    }
  }, [stepId, setTitle, firstName, isCustomSend, isPYG, isSingle, isDirectSend, isOneLink]);

  React.useEffect(() => {
    if (isSOBEnabled && !users) {
      dispatch(fetchUsersRequest());
    }
  }, [isSOBEnabled, users, dispatch]);

  const Step = React.useMemo(() => {
    return stepId ? flowSteps[stepId] : null;
  }, [stepId, flowSteps]);

  const isFlowMetadataLoading = React.useMemo(() => isUsersLoading, [isUsersLoading]);

  if (Step) {
    return (
      <div ref={rootElement} className={styles.engagement}>
        <Step
          {...{
            isSingle,
            isBulk,
            isOneLink,
            isDirectSend,
            next,
            onBoxSelect,
            onDelayedShippingChange,
            prev,
            SenderInfo,
            isPYG,
            isFlowMetadataLoading,
            hasMsku,
            hasCustomizableItems,
            flowId,
            stepId,
            isCustomSend,
          }}
        />
      </div>
    );
  }

  if (isBulk) {
    return <Redirect to={routes.engagement.getBulkUrl(URL_VARS.BOX)} />;
  }
  if (isSingle) {
    return <Redirect to={routes.engagement.getSingleUrl(URL_VARS.BOX)} />;
  }
  if (isOneLink) {
    return <Redirect to={routes.engagement.getOneLinkUrl(URL_VARS.BOX)} />;
  }
  if (isDirectSend) {
    return <Redirect to={routes.engagement.getDirectSendUrl(URL_VARS.BOX)} />;
  }
  return <Redirect to={routes.dashboard} />;
};

export default Engagement;
