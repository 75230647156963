import reduce from 'lodash/reduce';
import set from 'lodash/set';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import { ObjectSchema, SchemaDescription } from 'yup';

/**
 * It's used to indicate what fields are required based on the validation schema
 * It based on the formik feature to work with nested objects.
 * @param {ObjectSchema<any>} validationSchema - A default schema of the form
 * @param {string[]} [requiredFieldList=[]] - A list of the 100% required fields, required statuses of which cannot be obtained using yup methods(such as when() method).
 */
export const getRequiredFields = <T, C>(validationSchema: ObjectSchema<T, C>, requiredFieldList: string[] = []) => {
  const rootFieldSchemaList = validationSchema.describe().fields;

  const isFieldsRequiredBasedOnSchema = (
    fieldSchemaList: typeof rootFieldSchemaList,
    // prevKey is used to create key like `shipping_address.name` for the nested fields
    prevFieldName: string = '',
  ): Record<string, boolean> => {
    return reduce(
      fieldSchemaList,
      (acc, fieldSchema, fieldName) => {
        const fullFieldName = `${prevFieldName ? `${prevFieldName}.` : ''}${fieldName}`;

        return {
          ...acc,
          [fullFieldName]:
            isFieldRequired(fieldSchema as SchemaDescription) ||
            requiredFieldList.includes(fieldName) ||
            requiredFieldList.includes(fullFieldName),

          // If fieldSchema has the separate field props, this field has nested fields
          // ...((fieldSchema as SchemaDescription).fields
          ...('fields' in fieldSchema ? isFieldsRequiredBasedOnSchema(fieldSchema.fields, fullFieldName) : {}),
        };
      },
      {},
    );
  };

  return isFieldsRequiredBasedOnSchema(rootFieldSchemaList);
};

// All validation rules could be found in tests array of each fieldSchema
export const isFieldRequired = (fieldSchema: SchemaDescription) =>
  fieldSchema.tests.findIndex(({ name }) => name === 'required') >= 0;

// Name could be like `name` or `shipping_address.name`
// It based on the lodash `set` feature to work with nested objects
//  - mutates the `object` (1st argument)
//  - by the defined `path` (2nd argument)
//  - with a given value (3rd argument)
export const handleComplexFieldChange = (name: string, value: unknown) => set({}, name, value);

export const getMaxErrorMessage = (max: number) => {
  return `The max length for this field is ${max} symbols.`;
};

export const getRequiredFieldErrorMessage = (fieldName?: string) => {
  return `${
    fieldName
      ? `${fieldName.charAt(0).toUpperCase()}${fieldName.replaceAll('_', ' ').slice(1)} is a required field.`
      : 'Field is required.'
  }`;
};

export const getFirstErrorMessage = (errors: Record<string, string>): string | undefined => {
  return Object.entries(errors)[0]?.[1];
};

// export const phoneNumberTest: TestOptions = {
//   name: 'is-phone-number',
//   message({ label, path }) {
//     return `${label || path} must be a valid phone number.`;
//   },
//   test(value: unknown) {
//     // tslint:disable-next-line:no-invalid-this
//     const isRequired = isFieldRequired(this.schema.describe());
//     if (!isRequired) {
//       return !value || (typeof value === 'string' && isValidPhoneNumber(value));
//     }
//
//     return typeof value === 'string' && isValidPhoneNumber(value);
//   },
// };
