import cn from 'classnames';
import * as React from 'react';
import { ReactElement } from 'react';

import {
  ActionButton,
  BoxSidebarMessage,
  Drawer,
  HelpTooltip,
  Loader,
  SendItemsForm,
  WarningMessage,
} from '../../components';
import {
  CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING,
  INVENTORY_OUT_OF_STOCK_WARNING,
  MSKU_HAS_ITEM_WITH_NO_PREORDER_MESSAGE,
  PREORDER_IS_ENABLED_FOR_CAMPAIGN_MESSAGE,
} from '../../constants/inventories';
import useWindowSize from '../../hooks/useWindowSize';
import { IChangeBucketItemPayload } from '../../types/bucket';
import { ICampaign, ICampaignItem, ICustomSend } from '../../types/campaigns';
import { IOnDemandItem } from '../../types/inventories';
import { IReportWithCampaignSummaries } from '../../types/reports';
import {
  BoxDetailsSidebarModeEnum,
  DrawerAnimationDirectionEnum,
  PositionStylesEnum,
  UISizeEnum,
} from '../../types/shell';
import { isCampaignEligibleToOrder } from '../../utils/campaigns';
import { hasMskuAnyOutOfStockOptions, hasPreorderAllowed, isItemDigital, isSameFC } from '../../utils/inventories';

import { ReactComponent as PreorderIcon } from '../../assets/images/icon-preorder-allowed.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/icon-warning-red.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning.svg';

import variables from '../../_variables.scss';
import styles from './BoxDetailsSidebar.module.scss';

interface IProps {
  onClose: () => void;
  onSubmit?: (box: ICampaign | ICustomSend) => void;
  onChangeItem?: (itemData: IChangeBucketItemPayload) => void;
  onRemoveItem?: (item: ICampaignItem | IOnDemandItem) => void;
  onCustomize?: (item: ICampaign) => void;
  onReset?: () => void;
  isBoxSelected?: boolean;
  isLoading?: boolean;
  isPYGEnabled?: boolean;
  box?: ICampaign | ICustomSend | IReportWithCampaignSummaries;
  mode?: BoxDetailsSidebarModeEnum;
  actionButtons?: ReactElement[];
  title?: string;
  isDirectSend?: boolean;
}

const BoxDetailsSidebar: React.FC<IProps> = ({
  onClose,
  box,
  isPYGEnabled,
  isLoading,
  mode,
  onChangeItem,
  onRemoveItem,
  onCustomize,
  onReset,
  actionButtons,
  title,
  isDirectSend,
}: IProps) => {
  const { mobile, HD } = useWindowSize();
  const {
    items = [],
    name = title || '',
    description = '',
  } = React.useMemo(() => box || ({} as ICampaign | ICustomSend | IReportWithCampaignSummaries), [box]);
  const isDigital = React.useMemo(() => items?.every(isItemDigital), [items]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const from = React.useMemo(() => (mobile ? variables.boxSidebarMinHeight : variables.boxSidebarMinWidth), [mobile]);

  const to = React.useMemo(() => {
    if (mobile) {
      return variables.boxSidebarMaxHeight;
    }

    if (HD) {
      return variables.boxSidebarMaxWidthHD;
    }

    return variables.boxSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  const trigger = React.useMemo(() => !!box, [box]);

  const handleEditSendClick = React.useCallback(() => {
    if (box) {
      onCustomize?.(box as ICampaign);
    }
  }, [box, onCustomize]);

  const campaignIsNotEligibleToOrder = React.useMemo(
    () => !isCampaignEligibleToOrder(items as ICampaignItem[]),
    [items],
  );

  const hasItemsFromDifferentFC = React.useMemo(() => (items ? !isSameFC(items) : false), [items]);

  const isEditSendButtonVisible = React.useMemo(
    () => (onCustomize && !isDigital && !isPYGEnabled) || (campaignIsNotEligibleToOrder && !onReset),
    [onCustomize, isDigital, isPYGEnabled, campaignIsNotEligibleToOrder, onReset],
  );

  const sidebarMessages = React.useMemo(() => {
    if (campaignIsNotEligibleToOrder) {
      return (
        <BoxSidebarMessage
          key="outOfStock"
          icon={<ErrorIcon />}
          text={INVENTORY_OUT_OF_STOCK_WARNING}
          className={styles.error}
        />
      );
    }

    if (hasMskuAnyOutOfStockOptions(items)) {
      return (
        <BoxSidebarMessage
          key="mskuDisabled"
          icon={<WarningIcon />}
          text={MSKU_HAS_ITEM_WITH_NO_PREORDER_MESSAGE}
          className={styles.warning}
        />
      );
    }

    return null;
  }, [items, campaignIsNotEligibleToOrder]);

  const heading = React.useMemo(() => {
    return name ? name : title;
  }, [name, title]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={handleClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      {trigger && (
        <div className={styles.detailsContainer}>
          <Loader isLoading={isLoading} />
          <div className={styles.closeButtonContainer}>
            <button className={styles.closeButton} onClick={handleClose} />
          </div>
          <div className={styles.header}>
            <div className={styles.headerGroup}>
              {heading && (
                <div className={styles.heading}>
                  <h3 className={styles.headingTitle}>{heading}</h3>
                  {description && <p className={styles.headingDescription}>{description}</p>}
                  {hasItemsFromDifferentFC && (
                    <WarningMessage
                      className={styles.warning}
                      mode="error"
                      message={CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING}
                    />
                  )}
                </div>
              )}
              {isEditSendButtonVisible && (
                <ActionButton
                  key="editSendButton"
                  className={styles.headerBtn}
                  size={UISizeEnum.Small}
                  title="Edit send"
                  outlined
                  onClick={handleEditSendClick}
                />
              )}
              {onReset && !isDirectSend && (
                <ActionButton
                  className={cn(styles.headerBtn, styles.resetBtn)}
                  size={UISizeEnum.Small}
                  title="Back to previous step"
                  outlined
                  onClick={onReset}
                />
              )}
            </div>
            {sidebarMessages}
          </div>
          <SendItemsForm mode={mode} items={items} onChange={onChangeItem} onRemove={onRemoveItem}>
            <div className={styles.actionButtonsContainer}>
              {hasPreorderAllowed(items) && (
                <HelpTooltip
                  className={styles.preorderIcon}
                  contentClassName={styles.preorderTooltip}
                  icon={PreorderIcon}
                  id="preorder-campaign-info"
                  {...(!mobile ? { place: PositionStylesEnum.Left } : {})}
                >
                  {PREORDER_IS_ENABLED_FOR_CAMPAIGN_MESSAGE}
                </HelpTooltip>
              )}
              {actionButtons}
            </div>
          </SendItemsForm>
        </div>
      )}
    </Drawer>
  );
};

export default BoxDetailsSidebar;
