import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID } from '../constants/bucket';
import { DISABLE_HANDWRITTEN_NOTE_STEP } from '../constants/campaigns';

import {
  BULK_FLOW_SCHEMA,
  DIRECT_FLOW_SCHEMA,
  ONE_LINK_FLOW_SCHEMA,
  SINGLE_FLOW_SCHEMA,
} from '../constants/engagement';
import { routes, SupportedFlowsEnum, URL_VARS } from '../constants/routing';
import {
  selectFlowValidation,
  selectIsDigitalBucket,
  selectIsDisabledNotecard,
  selectIsPYGCampaignsDigital,
  selectIsPYGEnabled,
  selectPreCreatedBoxId,
} from '../store/selectors/bucket';
import { IFlowStep, IFlowValidatedStep, TFlowStepSlug } from '../types/bucket';
import { ICampaign } from '../types/campaigns';
import { isItemDigital } from '../utils/inventories';

interface IFlowRouteProps {
  flowId?: SupportedFlowsEnum;
  stepId?: string;
}

const useFlowGuard = (shouldValidate: boolean = true) => {
  // ShouldValidate flag should be used carefully because we cannot ensure that this guard is used as a singleton.
  // Each instance with true as a value will run useEffect, which will validate the flow step.
  // And if such an instance is used to read the state, it should have shouldValidate: false.
  const history = useHistory();
  const isDigitalBucket = useSelector(selectIsDigitalBucket);
  const isPYGCampaignsDigital = useSelector(selectIsPYGCampaignsDigital);
  const isCustomSend = useSelector(selectPreCreatedBoxId) === CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID;
  const isCampaignDisabledNotecard = useSelector(selectIsDisabledNotecard);
  const isPYGFlow = useSelector(selectIsPYGEnabled);

  // We need to use inner state to be able to change it before the box was added to the bucket
  const [preSelectedBox, setSelectedBox] = React.useState<ICampaign | null>(null);
  const [isDelayedShipping, setisDSFlow] = React.useState<boolean>(false);

  const isDigital = React.useMemo(
    () => isDigitalBucket || (preSelectedBox && preSelectedBox.items.every(isItemDigital)),
    [preSelectedBox, isDigitalBucket],
  );

  const isSkippedNoteStepBasedOnCampaign = React.useMemo(
    () => preSelectedBox?.[DISABLE_HANDWRITTEN_NOTE_STEP] || isCampaignDisabledNotecard,
    [preSelectedBox, isCampaignDisabledNotecard],
  );

  const routeMatch = useRouteMatch<IFlowRouteProps>(routes.engagement.route);

  const { flowId, stepId } = React.useMemo(() => (routeMatch && routeMatch.params) || {}, [routeMatch]);

  const isBulk = React.useMemo(() => flowId === URL_VARS.BULK, [flowId]);
  const isSingle = React.useMemo(() => flowId === URL_VARS.SINGLE, [flowId]);
  const isOneLink = React.useMemo(() => flowId === URL_VARS.ONE_LINK, [flowId]);
  const isDirectSend = React.useMemo(() => flowId === URL_VARS.DIRECT_SEND, [flowId]);

  const validationState = useSelector(selectFlowValidation(isBulk));

  const filterEmail = React.useCallback(
    (step: IFlowStep) => {
      const shouldPassEmail =
        isBulk || (isSingle && (isDelayedShipping || isDigital)) || (isOneLink && isDigital && !isPYGFlow);

      return step.slug !== URL_VARS.EMAIL || shouldPassEmail;
    },
    [isSingle, isBulk, isOneLink, isDigital, isDelayedShipping, isPYGFlow],
  );

  const filterNote = React.useCallback(
    (step: IFlowStep) => {
      const hasAllDigitalItems = isPYGFlow ? isPYGCampaignsDigital : isDigital;

      const shouldPassNote = !hasAllDigitalItems && !isSkippedNoteStepBasedOnCampaign;

      return step.slug !== URL_VARS.NOTE || shouldPassNote;
    },
    [isSkippedNoteStepBasedOnCampaign, isDigital, isPYGCampaignsDigital, isPYGFlow],
  );

  const flowSchema: IFlowValidatedStep[] = React.useMemo(() => {
    // determining the schema type,
    const schema = (() => {
      switch (true) {
        case isBulk:
          return BULK_FLOW_SCHEMA;
        case isSingle:
          return SINGLE_FLOW_SCHEMA;
        case isOneLink:
          return ONE_LINK_FLOW_SCHEMA;
        case isDirectSend:
          return DIRECT_FLOW_SCHEMA;
        default:
          return SINGLE_FLOW_SCHEMA;
      }
    })();

    return (
      schema
        // filtering schema steps with the needed conditions
        .filter(filterEmail)
        .filter(filterNote)
        // Extending the schema with validation
        .map((item) => {
          const valid = validationState[item.slug as TFlowStepSlug]
            ? validationState[item.slug as TFlowStepSlug]
            : false;
          return { ...item, valid };
        })
    );
  }, [isBulk, isSingle, isOneLink, isDirectSend, validationState, filterEmail, filterNote]);

  const getStepUrl = React.useMemo(() => {
    switch (true) {
      case isBulk:
        return routes.engagement.getBulkUrl;
      case isOneLink:
        return routes.engagement.getOneLinkUrl;
      case isSingle:
        return routes.engagement.getSingleUrl;
      case isDirectSend:
        return routes.engagement.getDirectSendUrl;
      default:
        return routes.engagement.getSingleUrl;
    }
  }, [isBulk, isSingle, isOneLink, isDirectSend]);

  const currentStepIndex = React.useMemo(
    () => flowSchema.findIndex((item: IFlowValidatedStep) => item.slug === stepId),
    [flowSchema, stepId],
  );

  // calculating previous and next step on the fly
  // Warning! `prev` and `next` which are set in the schema constants have higher priority
  const { prev, next } = React.useMemo(() => {
    // const getUrl = isBulk ? routes.engagement.getBulkUrl : routes.engagement.getSingleUrl;

    const getRoute = (type: 'next' | 'prev') => {
      let route;
      if (flowSchema?.[currentStepIndex]?.[type]) {
        route = flowSchema[currentStepIndex]?.[type];
      } else {
        const index = currentStepIndex + (type === 'next' ? 1 : -1);
        const item = flowSchema?.[index];
        route = item ? getStepUrl(item.slug) : undefined;
      }

      return route;
    };

    const [prevRoute, nextRoute] = [getRoute('prev'), getRoute('next')];

    return {
      prev: prevRoute,
      next: nextRoute,
    };
  }, [getStepUrl, currentStepIndex, flowSchema]);

  // This effect checks the steps before the current one to see if there's enough info to proceed with current step
  React.useEffect(() => {
    let redirectTo = '';
    if (shouldValidate && stepId) {
      if (currentStepIndex >= 0) {
        for (let i = currentStepIndex; i >= 0; i--) {
          const curr = flowSchema[i];
          if (!curr.valid) {
            redirectTo = curr.slug;
          }
        }
      }
      if (redirectTo && stepId !== redirectTo) {
        history.push(getStepUrl(redirectTo));
      }
    } else if (shouldValidate && flowId === URL_VARS.SUMMARY && !validationState.summary) {
      const summaryStep = flowSchema.find((item) => item.slug === URL_VARS.SUMMARY);
      if (summaryStep && summaryStep.prev) {
        history.push(summaryStep.prev);
      }
    }
  }, [shouldValidate, currentStepIndex, history, getStepUrl, flowSchema, stepId]);

  return routeMatch
    ? {
        isBulk,
        isSingle,
        isOneLink,
        isDirectSend,
        flowId,
        stepId,
        prev,
        next,
        onBoxSelect: setSelectedBox,
        onDelayedShippingChange: setisDSFlow,
        isCustomSend,
      }
    : {};
};

export default useFlowGuard;
