import { ShippingFlowSelectorOptionsEnum } from '../../constants/addressBook';
import { isObjectEmpty } from '../../utils/helpers';

export const getSelectOptions = (isDelayedShipping: boolean, isFixedDisabled: boolean = false) => [
  ...(isDelayedShipping
    ? [
        {
          value: ShippingFlowSelectorOptionsEnum.RecipientDecides,
          label: 'Recipient decides',
        },
        {
          value: ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList,
          label: 'Recipient decides from list only',
        },
      ]
    : []),
  {
    value: ShippingFlowSelectorOptionsEnum.Fixed,
    label: 'Fixed',
    disabled: isFixedDisabled,
  },
];

export const getInitialSelectorState = <T extends object | undefined>({
  is_receiver_address_fixed,
  is_delayed_shipping,
  address,
}: {
  is_receiver_address_fixed: boolean;
  is_delayed_shipping: boolean;
  address: T;
}) => {
  if (!is_delayed_shipping) {
    return ShippingFlowSelectorOptionsEnum.Fixed;
  }

  if (!is_receiver_address_fixed) {
    return ShippingFlowSelectorOptionsEnum.RecipientDecides;
  }

  return typeof address === 'undefined' || isObjectEmpty(address)
    ? ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList
    : ShippingFlowSelectorOptionsEnum.Fixed;
};
