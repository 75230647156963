import { FocusError } from 'focus-formik-error';
import { Form, Formik, FormikHelpers } from 'formik';
import { Persist } from 'formik-persist';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ActionButton,
  AddressSelector,
  LookupCRM,
  OpportunitiesModal,
  OutOfMoneyToast,
  ReceiverContactForm,
  RecipientShippingForm,
  shippingFormStyles,
} from '../../components';

import { USA } from '../../constants/countries';
import { HUBSPOT } from '../../constants/integrations';
import { SOB_OUT_OF_MONEY_MESSAGE } from '../../constants/shell';
import {
  AWAITING_ADDRESS,
  CONTACT,
  CRM_TYPE,
  INITIAL_SHIPPING_ADDRESS_FORM_STATE,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_PHONE,
  STATE,
  TShippingFormValues,
} from '../../constants/shipping';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useCurrency } from '../../contexts/CurrencyProvider';
import useModal from '../../hooks/useModal';
import { addShippingInformation } from '../../store/actions/bucket';
import { getOpportunitiesRequest } from '../../store/actions/integrations';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectEngagementCandidate,
  selectIsDigitalBucket,
  selectIsDigitalPYGBucket,
  selectIsSkipCrmEnabledForCurrentUserInOrg,
  selectShippingInitialValues,
  selectShippingValidationSchema,
  selectSOBUserRemainingBudget,
} from '../../store/selectors/bucket';
import {
  selectCurrentOrganizationOptions,
  selectIsCurrentOrganizationSupportedAnyCrm,
} from '../../store/selectors/organizations';
import { IOrgAddress } from '../../types/addressBook';
import { IFlowStepProps, TEngagementCandidate } from '../../types/bucket';
import { IOpportunityResponse, ISearchItem } from '../../types/crm';
import { ICommonAddress } from '../../types/shipping';
import { getRequiredFields } from '../../utils/form';
import notification from '../../utils/notification';
import { mapOrgAddressToCommonAddress } from '../../utils/oneLink';
import { hasPermission } from '../../utils/users';
import { SendShippingDetailsContainer } from '../ShippingDetailsContainer';

import styles from './SendShippingContainer.module.scss';

interface IProps extends IFlowStepProps {
  className?: string;
}

const SendShippingContainer = (flowProps: IProps) => {
  // used by formik-persist
  // should be cleared after the form is submitted because it will be populated with new formik values on mount
  const LOCAL_STORAGE_PERSIST_KEY = 'SendShippingContainer_form';
  // timeout in milliseconds to clear the form localStorage key once the component is unmounted
  const LOCAL_STORAGE_CLEAR_TIMEOUT = 350;

  const { prev, next, isDirectSend, isPYG, hasMsku, onDelayedShippingChange } = flowProps;

  const dispatch = useDispatch();
  const history = useHistory();
  const { getSendTotalPrice } = useCurrency();

  const {
    openModal: openOpportunitiesModal,
    closeModal: closeOpportunitiesModal,
    Modal: ChooseOpportunitiesModal,
  } = useModal({ required: true });

  const engagementCandidate = useSelector(selectEngagementCandidate) as TEngagementCandidate;
  const adminType = useSelector(selectAdminType);
  const SOBUserRemainingBudget = useSelector(selectSOBUserRemainingBudget);
  const isRegularDigital = useSelector(selectIsDigitalBucket);
  const isDigitalPYG = useSelector(selectIsDigitalPYGBucket);
  const hasCRM = useSelector(selectIsCurrentOrganizationSupportedAnyCrm) ?? false;
  const isSkipCrmEnabledForCurrentUser = useSelector(selectIsSkipCrmEnabledForCurrentUserInOrg);
  const { crm_currently_supports: crmType } = useSelector(selectCurrentOrganizationOptions) || {};
  const validationSchema = useSelector(selectShippingValidationSchema);
  const initialValues = useSelector(selectShippingInitialValues(Boolean(hasMsku || isPYG)));

  const isDigital = useMemo(() => isRegularDigital || isDigitalPYG, [isRegularDigital, isDigitalPYG]);

  const shouldShowContactAdminMessage = useMemo(
    () => !hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType),
    [adminType],
  );

  const isSkipCrmEnabledOnStep = React.useMemo(
    () => isDirectSend || isSkipCrmEnabledForCurrentUser,
    [isSkipCrmEnabledForCurrentUser, isDirectSend],
  );

  const handleSubmit = React.useCallback(
    (values: TForm, { resetForm, setSubmitting }: FormikHelpers<TForm>) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      const successfulSubmit = () => {
        dispatch(addShippingInformation(values, flowProps));
        setSubmitting(false);
        resetForm();
        if (next) {
          history.push(next);
        }

        if (SOBUserRemainingBudget !== null && SOBUserRemainingBudget < getSendTotalPrice(engagementCandidate).price) {
          notification.outOfMoney(
            <OutOfMoneyToast showContactAdminMessage={shouldShowContactAdminMessage}>
              {SOB_OUT_OF_MONEY_MESSAGE}
            </OutOfMoneyToast>,
          );
        }
      };

      // Hubspot doesn't have the concept of Leads or Contacts,
      // so considering all the people from Hubspot as Contacts
      // and checking for the opportunities each time.
      if (
        hasCRM &&
        RECEIVER_CRM_RECORD_ID in values &&
        values[RECEIVER_CRM_RECORD_ID] &&
        RECEIVER_CRM_RECORD_TYPE in values &&
        (values[RECEIVER_CRM_RECORD_TYPE] === CONTACT || crmType === HUBSPOT)
      ) {
        new Promise<IOpportunityResponse>((resolve, reject) => {
          dispatch(
            getOpportunitiesRequest({ cid: values[RECEIVER_CRM_RECORD_ID], [CRM_TYPE]: crmType }, { resolve, reject }),
          );
        }).then((response) => {
          const { totalSize } = response;

          if (totalSize > 0) {
            openOpportunitiesModal({ onChoose: successfulSubmit });
          } else {
            successfulSubmit();
          }
        });
      } else {
        successfulSubmit();
      }
    },
    [
      hasCRM,
      dispatch,
      validationSchema,
      SOBUserRemainingBudget,
      engagementCandidate,
      shouldShowContactAdminMessage,
      openOpportunitiesModal,
      history,
      flowProps,
      next,
      crmType,
      getSendTotalPrice,
    ],
  );

  type TForm = TShippingFormValues<typeof isDigital, typeof hasCRM>;

  const chooseOpportunitiesModal = React.useMemo(
    () => (
      <ChooseOpportunitiesModal>
        {(props: { onChoose: () => void }) => <OpportunitiesModal onClose={closeOpportunitiesModal} {...props} />}
      </ChooseOpportunitiesModal>
    ),
    [closeOpportunitiesModal, ChooseOpportunitiesModal],
  );

  const getConditionalRequiredFields = useCallback(
    (values: TForm) => {
      const isDS = 'ship_order_status' in values && values.ship_order_status === AWAITING_ADDRESS;

      const isReceiverAddressFixed = 'is_receiver_address_fixed' in values && values.is_receiver_address_fixed;

      const requiredAddressFields = [
        'receiver_address.address1',
        'receiver_address.city',
        'receiver_address.country',
        'receiver_address.zip',
      ];

      const requiredContactFields = [RECEIVER_EMAIL, RECEIVER_PHONE];

      const requiredStateField =
        'receiver_address' in values && (values.receiver_address as ICommonAddress)?.country === USA.two_digit_code
          ? [STATE]
          : [];

      return getRequiredFields(validationSchema, [
        ...(!isDS ? [...requiredAddressFields, ...requiredStateField, ...requiredContactFields] : []),
        ...(isDS && isReceiverAddressFixed ? [...requiredAddressFields, ...requiredStateField] : []),
      ]);
    },
    [validationSchema],
  );

  useEffect(() => {
    return () => {
      setTimeout(() => {
        // Since formik-persist updates localStorage with the default debounce value of 300 ms, we should clear it after this
        window.localStorage.removeItem(LOCAL_STORAGE_PERSIST_KEY);
      }, LOCAL_STORAGE_CLEAR_TIMEOUT);
    };
  }, []);

  return (
    <Formik<TForm>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => {
        const { values, setValues } = formik;

        const isDisabledByCRM =
          hasCRM && RECEIVER_CRM_RECORD_ID in values && !values[RECEIVER_CRM_RECORD_ID] && !isSkipCrmEnabledOnStep;

        const requiredFields = getConditionalRequiredFields(values);

        return (
          <Form className={styles.container} noValidate>
            <FocusError formik={formik} />
            {hasCRM && (
              <>
                {chooseOpportunitiesModal}
                <LookupCRM
                  isRequired={isSkipCrmEnabledOnStep}
                  onWarningClose={() => {
                    if (prev) {
                      history.push(prev);
                    }
                  }}
                  onSelect={async (item: ISearchItem) => {
                    await setValues({
                      ...values,
                      receiver_crm_record_id: item.Id,
                      receiver_crm_record_type: item.attributes.type,
                      receiver_first_name: item.FirstName,
                      receiver_last_name: item.LastName,
                      receiver_email: item.Email,
                      receiver_phone: item.Phone,
                      receiver_company_name: item.Company,
                    });
                  }}
                />
              </>
            )}
            <ReceiverContactForm disabled={isDisabledByCRM} requiredFields={requiredFields} />
            {!isDigital && (
              <>
                {isDirectSend ? (
                  <>
                    <AddressSelector
                      label="Select Address Book Option"
                      value={
                        'receiver_address' in values
                          ? (values.receiver_address as ICommonAddress)
                          : INITIAL_SHIPPING_ADDRESS_FORM_STATE
                      }
                      onChange={async (address: IOrgAddress) => {
                        await setValues({
                          ...values,
                          receiver_address: mapOrgAddressToCommonAddress(address),
                        });
                      }}
                    />
                    <RecipientShippingForm
                      className={shippingFormStyles.noBorder}
                      isDisabled={isDisabledByCRM}
                      requiredFields={requiredFields}
                    />
                  </>
                ) : (
                  <SendShippingDetailsContainer
                    requiredFields={requiredFields}
                    isDisabled={isDisabledByCRM}
                    isForcedDS={hasMsku || isPYG}
                    onDSChange={onDelayedShippingChange}
                  />
                )}
              </>
            )}
            <ActionButton disabled={isDisabledByCRM} type="submit" title="Continue" />
            <Persist name={LOCAL_STORAGE_PERSIST_KEY} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SendShippingContainer;
