import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { INITIAL_SHIPPING_ADDRESS_FORM_STATE } from '../../../constants/shipping';
import { ICommonAddress, IShippingDetails } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';
import { ActionTypesEnum, TAction } from './actions';

export interface IState {
  isDelayedShipping?: boolean;
  isAddressFixed?: boolean;
  addresses?: string[];
  receiverAddress?: ICommonAddress;
  selectorValue: ShippingFlowSelectorOptionsEnum;
}

export const getInitialState = (value?: IShippingDetails): IState => {
  const { ship_order_status, is_receiver_address_fixed, org_address_ids, receiver_address } = value || {};

  return {
    isDelayedShipping: !!ship_order_status,
    isAddressFixed: is_receiver_address_fixed,
    addresses: org_address_ids,
    receiverAddress: receiver_address,
    selectorValue: (() => {
      const isRecipientDecides = ship_order_status && !is_receiver_address_fixed;
      const isRecipientDecidesFromList =
        ship_order_status && is_receiver_address_fixed && !!(org_address_ids?.length ?? 0 > 1);
      const isFixed =
        !ship_order_status || (ship_order_status && is_receiver_address_fixed && org_address_ids?.length === 1);

      switch (true) {
        case isRecipientDecides:
          return ShippingFlowSelectorOptionsEnum.RecipientDecides;
        case isFixed:
          return ShippingFlowSelectorOptionsEnum.Fixed;
        case isRecipientDecidesFromList:
          return ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList;
        default:
          return ShippingFlowSelectorOptionsEnum.Fixed;
      }
    })(),
  };
};

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case ActionTypesEnum.changeSelect:
      const isNewFixed = action.payload === ShippingFlowSelectorOptionsEnum.Fixed;
      const isNewRecipientDecides = action.payload === ShippingFlowSelectorOptionsEnum.RecipientDecides;
      const isNewRecipientDecidesFromList = action.payload === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList;
      const hasPrevReceiverAddress = !isObjectEmpty(state.receiverAddress);

      return {
        ...state,
        selectorValue: action.payload,
        ...(isNewRecipientDecides
          ? {
              isAddressFixed: false,
              receiverAddress: hasPrevReceiverAddress ? state.receiverAddress : undefined,
            }
          : {
              isAddressFixed: true,
              addresses: (() => {
                if (isNewFixed) {
                  return undefined;
                }
                // Unreachable area
                console.warn("Unreachable area in 'changeSelect' reducer");
                return state.addresses;
              })(),
              receiverAddress: (() => {
                if (isNewFixed) {
                  return hasPrevReceiverAddress ? state.receiverAddress : undefined;
                }

                if (isNewRecipientDecidesFromList) {
                  return INITIAL_SHIPPING_ADDRESS_FORM_STATE;
                }

                // Unreachable area
                console.warn("Unreachable area in 'changeSelect' reducer");
                return state.receiverAddress;
              })(),
            }),
      };

    case ActionTypesEnum.changeFixedAddress:
      return {
        ...state,
        receiverAddress: action.payload,
      };
    case ActionTypesEnum.changeAB:
      return {
        ...state,
        addresses: action.payload,
      };
    case ActionTypesEnum.reset:
      return { ...action.payload };
    default:
      return state;
  }
};

export * from './actions';
