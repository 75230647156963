import cn from 'classnames';
import { useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue, Options, SingleValue } from 'react-select';

import { RECEIVER_REMINDER_INTERVAL } from '../../../constants/bucket';
import {
  BOX_ID,
  BUSINESS_REASON_REQUIRED,
  CAMPAIGN_SHIPPING_CONFIGURATION_DEFAULT,
  DELAYED_SHIPPING_TEMPLATE_DEFAULT,
  DEPARTMENT_IDS,
  DESCRIPTION,
  DIGITAL_LINK_TEMPLATE_DEFAULT,
  DISABLE_HANDWRITTEN_NOTE_STEP,
  HANDWRITTEN_TEMPLATE_DEFAULT,
  ICON_URL,
  NAME,
  PRIORITY,
} from '../../../constants/campaigns';
import { NO_DEPARTMENT } from '../../../constants/organizations';
import { endpoints } from '../../../constants/routing';
import { Campaign as ShippingDetailsContainer } from '../../../containers';
import useFetch from '../../../hooks/useFetch';
import useModal from '../../../hooks/useModal';
import { updateShippingConfig } from '../../../store/actions/campaigns';
import { fetchEmailsTemplatesRequest, fetchNotesTemplatesRequest } from '../../../store/actions/templates';
import {
  selectEmailDelayedShippingTemplates,
  selectEmailDigitalGiftTemplates,
  selectNoteTemplates,
} from '../../../store/selectors/templates';
import { ICampaign, IFetchCampaignIconsResponse } from '../../../types/campaigns';
import { CHECKBOX, ICampaignFormProps } from '../../../types/forms';
import { ISelectorValue } from '../../../types/shell';
import { ICampaignShippingConfiguration } from '../../../types/shipping';
import { IEmailTemplate, INoteTemplate } from '../../../types/templates';
import ImageLibraryModal from '../../modals/ImageLibraryModal/ImageLibraryModal';
import { Checkbox, DepartmentSelect, ImageUploadInput, Input, IntervalInput, NumericInput, Selector } from '../inputs';

import styles from './CampaignDetailsForm.module.scss';

const CampaignDetailsForm = ({ form, readOnly, onChange }: ICampaignFormProps<Omit<ICampaign, typeof BOX_ID>>) => {
  const dispatch = useDispatch();
  const [isPreviewShown /*, showPreview */] = React.useState(false); // TODO Will be implemented in a separate story

  const handWrittenNotesTemplates = useSelector(selectNoteTemplates);
  const delayedShippingTemplates = useSelector(selectEmailDelayedShippingTemplates);
  const digitalGiftTemplates = useSelector(selectEmailDigitalGiftTemplates);

  const { openModal: openChooseImageModal, closeModal: closeChooseImageModal, Modal: ChooseImageModal } = useModal();
  const { make: fetchCampaignIcons } = useFetch<never, IFetchCampaignIconsResponse>({
    endpoint: endpoints.getCampaignIconsList,
  });

  React.useEffect(() => {
    if (!handWrittenNotesTemplates) {
      dispatch(fetchNotesTemplatesRequest());
    }
    if (!delayedShippingTemplates || !digitalGiftTemplates) {
      dispatch(fetchEmailsTemplatesRequest());
    }
  }, [dispatch]);

  const handleEnableShippingDetails = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked ? CAMPAIGN_SHIPPING_CONFIGURATION_DEFAULT : undefined;
      dispatch(updateShippingConfig(value));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value, type } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }
      if (typeof onChange === 'function') {
        if (type === CHECKBOX) {
          return onChange(
            name as keyof Omit<ICampaign, 'box_id'>,
            (e as React.ChangeEvent<HTMLInputElement>).currentTarget.checked,
          );
        }

        onChange(name as keyof Omit<ICampaign, 'box_id'>, value);
      }
    },
    [onChange],
  );

  const formatTemplatesOptions = React.useCallback(
    (templates: (INoteTemplate | IEmailTemplate)[] | undefined | null): { value: string; label: string }[] =>
      templates && Array.isArray(templates)
        ? templates.map(({ item_id: value, ...template }: INoteTemplate | IEmailTemplate) => {
            const label = (template as INoteTemplate).title || (template as IEmailTemplate).subject;
            return {
              value,
              label,
            };
          })
        : [],
    [],
  );

  const formatTemplateValue = React.useCallback(
    (
      templates: (INoteTemplate | IEmailTemplate)[] | undefined | null,
      name: keyof Pick<
        ICampaign,
        'delayed_shipping_template_default' | 'handwritten_template_default' | 'digital_link_template_default'
      >,
    ): Options<ISelectorValue> | SingleValue<ISelectorValue> | MultiValue<ISelectorValue> | undefined => {
      const template = templates?.find((t) => t.item_id === form.values[name]);

      return form.values[name]
        ? ({
            label: (template as IEmailTemplate)?.subject || (template as INoteTemplate)?.title,
            value: form.values[name],
          } as ISelectorValue)
        : undefined;
    },
    [form.values],
  );

  const handleImageChoose = React.useCallback(
    (url: string) => {
      onChange?.(ICON_URL, url);
    },
    [onChange],
  );

  const handleSelectInputChange = React.useCallback(
    (name: keyof Omit<ICampaign, typeof BOX_ID>, selected: ISelectorValue) => {
      const { value } = selected || {};
      return handleChange({ currentTarget: { name, value } } as unknown as
        | React.ChangeEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>);
    },
    [handleChange],
  );

  const handleChangeReminderInterval = React.useCallback(
    (interval: string | null) => {
      if (typeof onChange === 'function') {
        onChange(RECEIVER_REMINDER_INTERVAL, interval || undefined);
      }
    },
    [onChange],
  );

  const chooseImageModal = React.useMemo(
    () => (
      <ChooseImageModal className="image-library">
        {() => (
          <ImageLibraryModal
            onFetch={({ resolve, reject }) => {
              fetchCampaignIcons()
                .then(({ images }) => resolve(images))
                .catch(reject);
            }}
            onChoose={({ public_url }) => {
              handleImageChoose(public_url);
              closeChooseImageModal();
            }}
            onClose={closeChooseImageModal}
          />
        )}
      </ChooseImageModal>
    ),
    [closeChooseImageModal, ChooseImageModal, handleImageChoose, fetchCampaignIcons],
  );

  const handleShippingConfigurationUpdate = React.useCallback(
    (cfg?: Partial<ICampaignShippingConfiguration>) => {
      dispatch(updateShippingConfig(cfg));
    },
    [dispatch],
  );

  const isShippingConfigEnabled = useMemo(() => {
    return !!form.values.shipping_configuration;
  }, [form.values.shipping_configuration]);

  return (
    <div className={styles.formWrapper}>
      <form className={cn(styles.container)} onSubmit={form.handleSubmit}>
        <div className={styles.inputContainer}>
          <span className={styles.label}>Choose Department</span>
          <DepartmentSelect
            isMulti
            value={form.values[DEPARTMENT_IDS]}
            defaultValue={NO_DEPARTMENT}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[DEPARTMENT_IDS] ? form.errors[DEPARTMENT_IDS] : undefined}
            className={styles.input}
            name={DEPARTMENT_IDS}
          />
        </div>
        <div className={styles.campaignInfo}>
          <ImageUploadInput
            message="Click to select picture"
            className={cn(styles.iconInput, { [styles.uploaded]: !!form.values[ICON_URL] })}
            onClick={openChooseImageModal}
            onImageDelete={() => handleImageChoose('')}
            value={form.values[ICON_URL]}
            error={form.touched[ICON_URL] ? form.errors[ICON_URL] : undefined}
          />
          <div className={styles.metadata}>
            <div className={styles.inputContainer}>
              <span className={cn(styles.label, styles.name)}>Name</span>
              <Input
                value={form.values[NAME]}
                name={NAME}
                onChange={handleChange}
                onBlur={form.handleBlur}
                error={form.touched[NAME] ? form.errors[NAME] : undefined}
                className={styles.input}
              />
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Description</span>
              <Input
                value={form.values[DESCRIPTION]}
                name={DESCRIPTION}
                onChange={handleChange}
                onBlur={form.handleBlur}
                error={form.touched[DESCRIPTION] ? form.errors[DESCRIPTION] : undefined}
                className={styles.input}
              />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.shippingInfo}>
            <Checkbox
              text="Enable default address settings for campaigns"
              checked={isShippingConfigEnabled}
              onChange={handleEnableShippingDetails}
              className={styles.enableShippingConfig}
            />
            <ShippingDetailsContainer
              value={isShippingConfigEnabled ? form.values.shipping_configuration : undefined}
              onChange={handleShippingConfigurationUpdate}
              isDisabled={!isShippingConfigEnabled}
            />
          </div>
          <div className={styles.optionsInfo}>
            <div className={styles.checkboxes_container}>
              <IntervalInput
                label="Set a reminder"
                className={styles.intervalContainer}
                value={form.values[RECEIVER_REMINDER_INTERVAL]}
                onChange={handleChangeReminderInterval}
              />
              <Checkbox
                containerClassName={styles.skip_note_step}
                text="Disable Custom Message Step"
                id={DISABLE_HANDWRITTEN_NOTE_STEP}
                checked={form.values[DISABLE_HANDWRITTEN_NOTE_STEP]}
                onChange={handleChange}
              />
              <Checkbox
                text="Require Business Reason"
                id={BUSINESS_REASON_REQUIRED}
                checked={form.values[BUSINESS_REASON_REQUIRED]}
                onChange={handleChange}
              />
            </div>
            <div className={styles.templates}>
              <div className={styles.inputContainer}>
                <span className={styles.label}>Delayed Shipping Email</span>
                <Selector
                  onBlur={form.handleBlur}
                  isMulti={false}
                  readOnly={readOnly}
                  isSearchable={false}
                  closeMenuOnSelect
                  placeholder="Select Template"
                  className={styles.input}
                  value={formatTemplateValue(delayedShippingTemplates, DELAYED_SHIPPING_TEMPLATE_DEFAULT)}
                  options={formatTemplatesOptions(delayedShippingTemplates)}
                  name={DELAYED_SHIPPING_TEMPLATE_DEFAULT}
                  onChange={(value: any) => handleSelectInputChange(DELAYED_SHIPPING_TEMPLATE_DEFAULT, value)}
                />
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.label}>Custom Message</span>
                <Selector
                  isMulti={false}
                  isSearchable={false}
                  readOnly={readOnly}
                  closeMenuOnSelect
                  placeholder="Select Template"
                  className={styles.input}
                  onBlur={form.handleBlur}
                  value={formatTemplateValue(handWrittenNotesTemplates, HANDWRITTEN_TEMPLATE_DEFAULT)}
                  options={formatTemplatesOptions(handWrittenNotesTemplates)}
                  name={HANDWRITTEN_TEMPLATE_DEFAULT}
                  onChange={(value: any) => handleSelectInputChange(HANDWRITTEN_TEMPLATE_DEFAULT, value)}
                />
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.label}>Digital Gift Email</span>
                <Selector
                  isMulti={false}
                  isSearchable={false}
                  readOnly={readOnly}
                  closeMenuOnSelect
                  placeholder="Select Template"
                  className={styles.input}
                  value={formatTemplateValue(digitalGiftTemplates, DIGITAL_LINK_TEMPLATE_DEFAULT)}
                  options={formatTemplatesOptions(digitalGiftTemplates)}
                  name={DIGITAL_LINK_TEMPLATE_DEFAULT}
                  onBlur={form.handleBlur}
                  onChange={(value: any) => handleSelectInputChange(DIGITAL_LINK_TEMPLATE_DEFAULT, value)}
                />
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.label}>Set View Priority</span>
                <NumericInput
                  value={form.values[PRIORITY]}
                  name={PRIORITY}
                  onChange={handleChange}
                  onBlur={form.handleBlur}
                  error={form.touched[PRIORITY] ? form.errors[PRIORITY] : undefined}
                  className={cn(styles.input, styles.priority)}
                  decimalScale={0}
                  errorClassName={styles.error}
                />
              </div>
            </div>
          </div>
        </div>
        {chooseImageModal}
      </form>
      {isPreviewShown && (
        <div className={styles.preview}>
          <React.Fragment>
            <span className={styles.label}>Preview Zone</span>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};

export default CampaignDetailsForm;
