import { ISearchItem } from '../types/crm';

export const getSearchItemAddressDescription = ({ MailingAddress, Address }: ISearchItem) => {
  const { city, country, postalCode, state, street } = MailingAddress || Address || {};
  return `
    ${street ? `${street}` : ''}
    ${city ? ` ${city} ` : ''}
    ${state ? ` ${state} ` : ''}
    ${postalCode ? ` ${postalCode} ` : ''}
    ${country ? ` ${country}` : ''}
  `;
};
