import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { ReactComponent as AlertIcon } from '../../assets/images/icon-warning-red-outline.svg';

import { DeleteAllWarning, Loader } from '../../components';
import { EMAIL } from '../../constants/users';
import { TwoFASidebar } from '../index';

import {
  ALLOWED_RECIPIENTS_TABLE_HEADERS,
  EMAIL_ADDRESS,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
} from '../../constants/oneLink';
import { endpoints, queryParams } from '../../constants/routing';
import useFetch from '../../hooks/useFetch';
import {
  addAllowedRecipient,
  deleteAllAllowedRecipients,
  deleteAllowedRecipient,
  editAllowedRecipient,
} from '../../store/actions/bucket';
import {
  addOneLinkRecipientsSuccess,
  deleteAllOneLinkRecipientsSuccess,
  deleteOneLinkRecipientsSuccess,
  fetchOneLinkByIdSuccess,
  setOneLinkRecipients,
  updateOneLinkRecipientsSuccess,
} from '../../store/actions/oneLink';
import { toggleUiElement } from '../../store/actions/shell';
import { select2FARecipientsList } from '../../store/selectors/bucket';
import { selectOneLinkRecipients } from '../../store/selectors/oneLink';
import { selectIs2FADrawerShown } from '../../store/selectors/shell';
import { BUTTON_BUTTON, EditableTableModes } from '../../types/forms';
import {
  IAddRecipientsPayload,
  IFetchOneLinkByIdResponsePayload,
  IOneLinkRecipient,
  IOneLinkRecipientWithId,
  OneLinkStatusEnum,
  TOneLinkRecipient,
} from '../../types/oneLink';
import { TwoFASidebarModesEnum, UISizeEnum } from '../../types/shell';
import { ITableConfig } from '../../types/tables';
import notification from '../../utils/notification';
import { validateRecipients } from '../../utils/oneLink';
import { handleApiError } from '../../utils/ui';

import 'react-phone-number-input/style.css';
import styles from './TwoFASidebarContainer.module.scss';

interface IProps {
  oneLinkId?: string;
  status?: OneLinkStatusEnum;
}

const TwoFASidebarContainer = ({ oneLinkId, status }: IProps) => {
  const dispatch = useDispatch();

  const [deleteControl, setDeleteControl] = React.useState<string | null>(null);
  const [activeAllowedRecipient, setActiveAllowedRecipient] = React.useState<any>(null);
  const [error, setError] = React.useState('');
  const [isDeleteAllWarningShown, setIsDeleteAllWarningShown] = React.useState(false);

  const isShown = useSelector(selectIs2FADrawerShown);

  const candidateRecipients = useSelector(select2FARecipientsList); // 2fa recipients from bucket reducer - rename
  const existingRecipients = useSelector(selectOneLinkRecipients); // 2fa recipients from oneLink reducer - rename

  const { make: fetchOneLink } = useFetch<never, IFetchOneLinkByIdResponsePayload>({
    endpoint: endpoints.getOneLinkSendById,
  });

  const { isLoading: isFetching, make: fetchOneLinkRecipients } = useFetch<never, IOneLinkRecipient[]>({
    endpoint: endpoints.getOneLinkRecipients,
  });

  const { isLoading: isDeleting, make: deleteOneLinkRecipient } = useFetch<never, never>({
    endpoint: endpoints.deleteOneLinkRecipient,
    method: 'DELETE',
  });

  const { isLoading: isUpdating, make: updateOneLinkRecipient } = useFetch<TOneLinkRecipient, IOneLinkRecipientWithId>({
    endpoint: endpoints.updateOneLinkRecipient,
    method: 'PUT',
  });

  const { isLoading: isCreating, make: createOneLinkRecipients } = useFetch<TOneLinkRecipient[], IAddRecipientsPayload>(
    {
      endpoint: endpoints.createOneLinkRecipients,
      method: 'POST',
    },
  );

  const mode = React.useMemo(
    () => (oneLinkId ? TwoFASidebarModesEnum.Edit : TwoFASidebarModesEnum.Create),
    [oneLinkId],
  );

  const isLoading = React.useMemo(
    () => isFetching || (isDeleting && isDeleteAllWarningShown),
    [isDeleteAllWarningShown, isFetching, isDeleting],
  );

  const isCreateMode = React.useMemo(() => mode === TwoFASidebarModesEnum.Create, [mode]);
  const isEditMode = React.useMemo(() => mode === TwoFASidebarModesEnum.Edit, [mode]);

  const tableMode = React.useMemo(() => {
    const { uid, id, email, phone } = activeAllowedRecipient || {};
    if ((uid || id) && (typeof phone !== 'undefined' || typeof email !== 'undefined')) {
      return EditableTableModes.Edit;
    }
    if (!uid && !id && (typeof phone !== 'undefined' || typeof email !== 'undefined')) {
      return EditableTableModes.Add;
    }
    return undefined;
  }, [activeAllowedRecipient]);

  const twoFARecipients = React.useMemo(() => {
    return isCreateMode ? candidateRecipients?.successfulRecipients! : existingRecipients;
  }, [existingRecipients, isCreateMode, candidateRecipients?.successfulRecipients]);

  React.useEffect(() => {
    if (isShown && oneLinkId && !isLoading && isEditMode && !existingRecipients) {
      fetchOneLinkRecipients({
        query: { [queryParams.oneLinkId]: oneLinkId },
      })
        .then((response) => {
          dispatch(setOneLinkRecipients(response || []));
        })
        .catch(handleApiError(`Something bad happened during the one link retrieval.`));
    }
  }, [isShown, oneLinkId, isEditMode, fetchOneLinkRecipients, dispatch, isLoading, existingRecipients]);

  const handleClose = useCallback(() => {
    dispatch(toggleUiElement({ twoFASidenavIsShown: false }));
    dispatch(setOneLinkRecipients(null));
    setActiveAllowedRecipient(null);
    setIsDeleteAllWarningShown(false);
  }, [dispatch]);

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.currentTarget;

    const trimmedValue = name === EMAIL ? value.trim() : value;

    setActiveAllowedRecipient((prevValue: TOneLinkRecipient) => ({
      ...(prevValue ? prevValue : {}),
      [name]: trimmedValue,
    }));

    setError('');
  }, []);

  const handleNewRecipientClick = React.useCallback(() => {
    setError('');
    setActiveAllowedRecipient({ [FIRST_NAME]: '', [LAST_NAME]: '', [EMAIL_ADDRESS]: '', [PHONE_NUMBER]: '' });
  }, []);

  const handleAddAllowedRecipient = React.useCallback(() => {
    return new Promise<void>(() => {
      const validatedRecipients = validateRecipients([activeAllowedRecipient], twoFARecipients, activeAllowedRecipient);
      if (validatedRecipients.failedRecipients.length === 0) {
        activeAllowedRecipient.id = uuid();
        if (isEditMode && oneLinkId) {
          createOneLinkRecipients({
            query: {
              [queryParams.oneLinkId]: oneLinkId,
            },
            body: validatedRecipients.successfulRecipients,
          })
            .then((response) => {
              if (response.valid_recipients) {
                dispatch(addOneLinkRecipientsSuccess({ ...response.valid_recipients[0] }));
              }
              setActiveAllowedRecipient(null);
            })
            .then(() =>
              fetchOneLink({
                query: { [queryParams.uid]: oneLinkId },
              }).then((response) => {
                if (status) {
                  dispatch(fetchOneLinkByIdSuccess({ status, ...response }));
                }
              }),
            );
        }
        if (isCreateMode) {
          dispatch(addAllowedRecipient(activeAllowedRecipient));
          setActiveAllowedRecipient(null);
        }
      } else {
        notification.error(activeAllowedRecipient?.error, { content: activeAllowedRecipient?.error });
        setError(activeAllowedRecipient?.error);
      }
    });
  }, [activeAllowedRecipient, oneLinkId, dispatch, isCreateMode, isEditMode, twoFARecipients, status]);

  const handleEditAllowedRecipient = React.useCallback(() => {
    return new Promise<void>(() => {
      const validatedRecipients = validateRecipients([activeAllowedRecipient], twoFARecipients, activeAllowedRecipient);
      if (validatedRecipients.failedRecipients.length === 0) {
        delete activeAllowedRecipient.error;

        if (isEditMode && oneLinkId) {
          updateOneLinkRecipient({
            query: {
              [queryParams.oneLinkId]: oneLinkId,
            },
            body: validatedRecipients.successfulRecipients[0],
          })
            .then((response) => {
              dispatch(updateOneLinkRecipientsSuccess(response));
            })
            .then(() => {
              setActiveAllowedRecipient(null);
            });
        }
        if (isCreateMode) {
          dispatch(editAllowedRecipient(activeAllowedRecipient));
          setActiveAllowedRecipient(null);
        }
      } else {
        notification.error(activeAllowedRecipient?.error, { content: activeAllowedRecipient?.error });
        setError(activeAllowedRecipient?.error);
      }
    });
  }, [activeAllowedRecipient, oneLinkId, dispatch, isCreateMode, isEditMode, twoFARecipients, status]);

  const handleDeleteAllowedRecipient = React.useCallback(
    ({ id, uid }: TOneLinkRecipient) => {
      if (isCreateMode && id) {
        dispatch(deleteAllowedRecipient(id));
      }

      if (isEditMode && oneLinkId && uid) {
        deleteOneLinkRecipient({
          query: {
            [queryParams.oneLinkId]: oneLinkId,
            [queryParams.uid]: uid,
          },
        })
          .then(() => {
            dispatch(deleteOneLinkRecipientsSuccess({ uid }));
          })
          .then(() =>
            fetchOneLink({
              query: { [queryParams.uid]: oneLinkId },
            }).then((response) => {
              if (status) {
                dispatch(fetchOneLinkByIdSuccess({ status, ...response }));
              }
            }),
          );
      }
    },
    [oneLinkId, deleteOneLinkRecipient, dispatch, isCreateMode, isEditMode, fetchOneLink, status],
  );

  const handleDeleteAllAllowedRecipients = useCallback(() => {
    if (isCreateMode) {
      dispatch(deleteAllAllowedRecipients());
      setIsDeleteAllWarningShown(false);
    }

    if (isEditMode && oneLinkId) {
      deleteOneLinkRecipient({
        query: {
          [queryParams.oneLinkId]: oneLinkId,
          [queryParams.all]: true,
        },
      })
        .then(() => {
          dispatch(deleteAllOneLinkRecipientsSuccess());
        })
        .then(() => {
          setIsDeleteAllWarningShown(false);
        })
        .then(() =>
          fetchOneLink({
            query: { [queryParams.uid]: oneLinkId },
          }).then((response) => {
            if (status) {
              dispatch(fetchOneLinkByIdSuccess({ status, ...response }));
            }
          }),
        );
    }
  }, [oneLinkId, deleteOneLinkRecipient, dispatch, isCreateMode, isEditMode, status]);

  const handleDeleteClick = useCallback((recipient: TOneLinkRecipient) => {
    if (recipient.uid) {
      setDeleteControl(recipient.uid);
      return;
    }

    if (recipient.id) {
      setDeleteControl(recipient.id);
      return;
    }
  }, []);

  const handleSubmit = React.useMemo(() => {
    setError('');
    switch (true) {
      case tableMode === EditableTableModes.Add:
        return handleAddAllowedRecipient;
      case tableMode === EditableTableModes.Edit:
        return handleEditAllowedRecipient;
      default:
        return;
    }
  }, [tableMode, handleAddAllowedRecipient, handleEditAllowedRecipient]);

  const originalAllowedRecipient = React.useMemo(
    () => twoFARecipients?.find((recipient: TOneLinkRecipient) => recipient.id === activeAllowedRecipient?.id),
    [twoFARecipients, activeAllowedRecipient?.id],
  );

  const numberOfRecipientsAllowedToDelete = React.useMemo(() => {
    if (!twoFARecipients || !twoFARecipients.length) {
      return 0;
    }

    const filteredRecipients = twoFARecipients.filter((recipient) => recipient.is_submitted);

    if (!filteredRecipients.length) {
      return twoFARecipients.length;
    }

    return twoFARecipients.length - filteredRecipients.length;
  }, [twoFARecipients]);

  const isChanged = React.useMemo(
    () => !isEqual(originalAllowedRecipient, activeAllowedRecipient),
    [originalAllowedRecipient, activeAllowedRecipient],
  );

  const renderDeleteConfirmation = React.useCallback(
    (recipient: TOneLinkRecipient) => (
      <div key={`${deleteControl}-delete-control`} className={styles.deletePlaceholder}>
        {isDeleting && <Loader isLoading size={UISizeEnum.Small} />}
        <AlertIcon className={styles.alertIcon} />
        <p className={styles.deleteText}>Are you sure?</p>
        <button
          type={BUTTON_BUTTON}
          key="submit"
          onClick={() => {
            handleDeleteAllowedRecipient(recipient);
          }}
          className={styles.deleteButton}
        >
          Delete recipient
        </button>
        <button
          type={BUTTON_BUTTON}
          key="cancel"
          onClick={() => setDeleteControl(null)}
          className={styles.cancelButton}
        >
          Cancel
        </button>
      </div>
    ),
    // handleDeleteAllowedRecipient is removed from the dependencies because it causes some unknown focus issues
    // EX-3903
    [deleteControl, isDeleting],
  );

  const allowedRecipientsTableConfig: ITableConfig<IOneLinkRecipient> = React.useMemo(() => {
    return {
      columns: {
        headers: ALLOWED_RECIPIENTS_TABLE_HEADERS,
        visible: [FIRST_NAME, LAST_NAME, EMAIL_ADDRESS, PHONE_NUMBER],
      },
      rows: deleteControl
        ? [
            {
              id: deleteControl,
              predicateFn: (recipient: TOneLinkRecipient) =>
                deleteControl === recipient.uid || deleteControl === recipient.id,
              render: (row) => {
                return renderDeleteConfirmation(row);
              },
            },
          ]
        : undefined,
      cells: {
        inputs: {
          [FIRST_NAME]: {
            props: () => ({
              placeholder: `First name`,
              className: styles.editableCell,
            }),
          },
          [LAST_NAME]: {
            props: () => ({
              placeholder: `Last name`,
              className: styles.editableCell,
            }),
          },
          [EMAIL_ADDRESS]: {
            props: () => ({
              placeholder: `Email address`,
              className: cn(styles.editableCell, { [styles.error]: error?.toLowerCase()?.includes('email') }),
            }),
          },
          [PHONE_NUMBER]: {
            render: ({ inline, autoFocus, ...props }) => {
              return (
                <PhoneInput
                  {...props}
                  countrySelectProps={{ className: styles.countrySelect }}
                  className={cn(styles.editableCell, {
                    [styles.error]: error?.toLowerCase()?.includes('phone'),
                  })}
                  onChange={(value) => {
                    setError('');
                    setActiveAllowedRecipient((prevValue: TOneLinkRecipient) => ({
                      ...(prevValue ? prevValue : {}),
                      [PHONE_NUMBER]: value,
                    }));
                  }}
                  placeholder="Phone number"
                  international
                />
              );
            },
          },
        },
      },
      controls: {
        container: {
          className: styles.controls,
        },
        edit: { className: styles.edit },
        save: { className: styles.save },
        delete: { className: styles.delete },
        cancel: { className: styles.cancel },
      },
    };
  }, [error, deleteControl, renderDeleteConfirmation]);

  return (
    <TwoFASidebar
      trigger={isShown}
      onClose={handleClose}
      uploadedRecipients={twoFARecipients}
      tableConfig={allowedRecipientsTableConfig}
      onChange={handleChange}
      onAdd={handleNewRecipientClick}
      onDeleteAll={() => setIsDeleteAllWarningShown(true)}
      tableMode={tableMode}
      isLoading={isUpdating || isDeleting || isCreating}
      mode={mode}
      activeAllowedRecipient={activeAllowedRecipient}
      setActiveAllowedRecipient={setActiveAllowedRecipient}
      isChanged={isChanged}
      onSave={handleSubmit}
      onDelete={handleDeleteClick}
      oneLinkId={oneLinkId}
      status={status}
    >
      {
        // we should pass children only if we need to substitute the standard layout of the TwoFASidebar
        (isLoading && <Loader isLoading />) ||
          (isDeleteAllWarningShown && (
            <DeleteAllWarning
              onCancel={() => setIsDeleteAllWarningShown(false)}
              onSubmit={handleDeleteAllAllowedRecipients}
              message={
                <p>
                  {`${numberOfRecipientsAllowedToDelete} recipient${
                    numberOfRecipientsAllowedToDelete === 1 ? '' : 's'
                  } will be removed.`}
                </p>
              }
            />
          ))
      }
    </TwoFASidebar>
  );
};

export default TwoFASidebarContainer;
