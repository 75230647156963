import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { ICommonAddress } from '../../../types/shipping';
import { IState } from './reducer';

export enum ActionTypesEnum {
  changeSelect = 'changeSelect',
  changeAB = 'changeAB',
  changeFixedAddress = 'changeFixedAddress',
  reset = 'reset',
}

export interface IAction<T = undefined> {
  type: ActionTypesEnum;
  payload: T;
  metadata?: any;
}

type TChangeSelectAction = IAction<ShippingFlowSelectorOptionsEnum> & { type: ActionTypesEnum.changeSelect };
type TChangeABAction = IAction<string[]> & { type: ActionTypesEnum.changeAB };
type TChangeFixedAddressAction = IAction<ICommonAddress> & { type: ActionTypesEnum.changeFixedAddress };
type TResetAction = IAction<IState> & { type: ActionTypesEnum.reset; payload: IState };

export type TAction = TChangeSelectAction | TChangeABAction | TChangeFixedAddressAction | TResetAction;

export const changeSelect = (payload: ShippingFlowSelectorOptionsEnum): TChangeSelectAction => ({
  type: ActionTypesEnum.changeSelect,
  payload,
});

export const changeAB = (payload: string[]): TChangeABAction => ({
  type: ActionTypesEnum.changeAB,
  payload,
});

export const changeFixedAddress = (payload: ICommonAddress): TChangeFixedAddressAction => ({
  type: ActionTypesEnum.changeFixedAddress,
  payload,
});

export const reset = (initialState: IState): TResetAction => ({
  type: ActionTypesEnum.reset,
  payload: initialState,
});
