import { FormikValues } from 'formik';
import * as React from 'react';

import { countryList } from '../../../../constants/countries';
import { ISelectorValue } from '../../../../types/shell';
import { Selector } from '../../index';

interface ICountrySelectorProps extends FormikValues {
  label?: string;
  className?: string;
  containerClassName?: string;
  error?: string | string[] | null | undefined;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
}

const CountrySelector: React.FC<ICountrySelectorProps> = ({
  label,
  className,
  containerClassName,
  required,
  error,
  value,
  onChange,
  name,
  disabled,
  readOnly,
  onFocus,
  onBlur,
}) => {
  const countryOptions = React.useMemo(() => {
    return countryList.map((country) => {
      return {
        value: country.two_digit_code,
        label: country.name,
      };
    });
  }, []);

  const handleChange = React.useCallback(
    (selectedValue: ISelectorValue) => {
      if (onChange) {
        onChange(name, selectedValue);
      }
    },
    [onChange, name],
  );

  const selectedCountry = React.useMemo(() => {
    return countryOptions.find((option) => option.value === value) || null;
  }, [value, countryOptions]);

  return (
    <Selector
      helperText={label}
      isClearable
      isSearchable
      name={name}
      className={className}
      containerClassName={containerClassName}
      value={selectedCountry}
      closeMenuOnSelect
      placeholder="Search a country"
      onChange={(v) => handleChange(v as ISelectorValue)}
      options={countryOptions || []}
      required={required}
      error={error}
      isDisabled={disabled}
      readOnly={readOnly}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default CountrySelector;
