import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Drawer, Loader, UnsavedChangesModal } from '../../components';
import useModal from '../../hooks/useModal';
import useWindowSize from '../../hooks/useWindowSize';
import { editEngagementRequest } from '../../store/actions/engagement';
import { selectIsEditEngagementFetching } from '../../store/selectors/reports';
import { TEditEngagementPayload } from '../../types/bucket';
import { EditSendModeEnum, IReportEngagementItem, IReportWithCampaignSummaries } from '../../types/reports';
import { IReportsRouteParams } from '../../types/routing';
import { DrawerAnimationDirectionEnum, IApiError, NotificationListEnum } from '../../types/shell';
import { IReceiverInfoDetailsFormValues } from '../../types/shipping';
import { isObjectsEqual } from '../../utils/helpers';
import notification from '../../utils/notification';
import { getEditableSendFields } from '../../utils/reports';
import EditEngagementDetailsContainer, {
  IEditDetailsRef,
} from './EditEngagementDetailsContainer/EditEngagementDetailsContainer';
import EditEngagementItemsContainer, {
  IEditItemsRef,
} from './EditEngagementItemsContainer/EditEngagementItemsContainer';

import variables from '../../_variables.scss';
import styles from './EditSendSidebar.module.scss';

interface IProps {
  onClose: () => void;
  mode?: EditSendModeEnum;
  report?: IReportWithCampaignSummaries | null;
  onSave?: () => void;
}

const EditSendSidebar = ({ mode, report, onSave, onClose }: IProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsEditEngagementFetching);

  const detailsRef = React.useRef<IEditDetailsRef>(null);
  const itemsRef = React.useRef<IEditItemsRef>(null);

  const { reportId = '', type } = useParams<IReportsRouteParams>();

  const { openModal: openSaveChangesModal, closeModal: closeSaveChangesModal, Modal: SaveChangesModal } = useModal();

  const reportDetails = React.useMemo(() => getEditableSendFields(report), [report]);
  const reportItems = React.useMemo(() => report?.items, [report]);

  const handleSubmit = React.useCallback(
    (candidate: TEditEngagementPayload) => {
      const successfulSubmit = () => {
        onClose();
        if (onSave) {
          // timeout is a workaround to wait for some time for the data is updated in DB
          setTimeout(onSave, 500);
        }
      };

      return new Promise<IApiError | void>((resolve, reject) => {
        dispatch(
          editEngagementRequest({
            engagement: { ...candidate, type, engagement_id: reportId },
            resolve,
            reject,
          }),
        );
      })
        .then((response) => {
          const { message } = (response as IApiError) || {};
          if (message && message?.length > 0) {
            notification.warning(NotificationListEnum.EditEngagement, {
              content: message,
            });
          } else {
            notification.success(NotificationListEnum.EditEngagement, {
              content: 'The send was updated successfully!',
            });
          }
        })
        .then(successfulSubmit)
        .catch((error) =>
          notification.error(NotificationListEnum.EditEngagement, {
            content: error?.message ? error.message : 'Something bad has happened. The send edits were not applied.',
          }),
        );
    },
    [dispatch, type, reportId, onSave, onClose],
  );

  const handleSubmitDetails = React.useCallback(
    async (details: IReceiverInfoDetailsFormValues) => {
      await handleSubmit({ receiver_info: details });
    },
    [handleSubmit, reportItems],
  );

  const handleSubmitItems = React.useCallback(
    async (items: IReportEngagementItem[]) => {
      await handleSubmit({ items_info: items });
    },
    [handleSubmit, reportDetails],
  );

  const handleSaveAndCloseModal = React.useCallback(async () => {
    const items = itemsRef.current?.getValues();
    const details = detailsRef.current?.getValues();
    switch (mode) {
      case EditSendModeEnum.Items:
        if (items) {
          await handleSubmitItems(items);
        }
        break;
      case EditSendModeEnum.Details:
        if (details) {
          await handleSubmitDetails(details);
        }
        break;
      default:
        onClose();
    }
  }, [handleSubmitItems, handleSubmitDetails, mode, onClose]);

  const saveChangesModalActions = React.useMemo(() => {
    return [
      { title: 'Save and close', onClick: handleSaveAndCloseModal },
      {
        title: 'Discard changes',
        onClick: () => {
          if (onClose) {
            onClose();
          }
        },
        isOutlined: true,
      },
    ];
  }, [onClose, handleSaveAndCloseModal]);

  const saveChangesModal = React.useMemo(
    () => (
      <SaveChangesModal
        overlayClassName={styles.saveChangesModalOverlay}
        className={cn(styles.saveChangesModal, 'common-modal')}
      >
        {() => <UnsavedChangesModal actions={saveChangesModalActions} onClose={closeSaveChangesModal} />}
      </SaveChangesModal>
    ),
    [closeSaveChangesModal, saveChangesModalActions, SaveChangesModal],
  );

  const handleCloseSidebar = React.useCallback(() => {
    if (
      mode === EditSendModeEnum.Details &&
      detailsRef.current &&
      !isObjectsEqual(detailsRef.current?.getValues(), reportDetails)
    ) {
      return openSaveChangesModal();
    }

    if (
      mode === EditSendModeEnum.Items &&
      itemsRef.current?.getValues() &&
      !isEqual(itemsRef.current?.getValues(), reportItems)
    ) {
      return openSaveChangesModal();
    }

    if (typeof onClose === 'function') {
      return onClose();
    }
  }, [openSaveChangesModal, onClose, reportDetails, reportItems, mode]);

  const { mobile, HD } = useWindowSize();

  const from = React.useMemo(() => (mobile ? variables.boxSidebarMinHeight : variables.boxSidebarMinWidth), [mobile]);
  const to = React.useMemo(() => {
    if (mobile) {
      return variables.boxSidebarMaxHeight;
    }

    if (HD) {
      return variables.boxSidebarMaxWidthHD;
    }

    return variables.boxSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  return (
    <React.Fragment>
      <Drawer
        className={cn(styles.container)}
        direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
        from={from}
        to={to}
        trigger={!!mode}
        onClose={handleCloseSidebar}
        withOverlay
        overlayClassName={styles.overlay}
      >
        <Loader isLoading={isLoading && !!mode} />
        {mode && (
          <div className={styles.contentContainer}>
            <div className={styles.closeButtonContainer}>
              <button className={styles.closeButton} onClick={handleCloseSidebar} />
            </div>
            <div className={styles.formContainer}>
              {mode === EditSendModeEnum.Details && (
                <EditEngagementDetailsContainer
                  ref={detailsRef}
                  report={report}
                  onSubmit={handleSubmitDetails}
                  onCancel={onClose}
                />
              )}
              {mode === EditSendModeEnum.Items && (
                <EditEngagementItemsContainer ref={itemsRef} report={report} onSubmit={handleSubmitItems} />
              )}
            </div>
          </div>
        )}
      </Drawer>
      {saveChangesModal}
    </React.Fragment>
  );
};

export default EditSendSidebar;
