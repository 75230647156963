import cn from 'classnames';
import React from 'react';

import { ICommonAddress } from '../../types/shipping';

import styles from './CompactOrgAddressView.module.scss';

interface IProps {
  row: ICommonAddress & { label?: string };
  className?: string;
  addressClassName?: string;
  onReset?: () => void;
}

function CompactOrgAddressView({ row, className, addressClassName, onReset }: IProps) {
  return (
    <div className={cn(styles.container, className)}>
      {row.label && <div className={styles.truncated}>{row.label}</div>}
      <div className={styles.resetContainer}>
        <div className={cn(styles.address, styles.truncated, addressClassName)}>
          <span>{`${row.address1}\u00A0`}</span>
          <span>{row.address2 ? `${row.address2}, \u00A0` : ''}</span>
          <span>{`${row.city},\u00A0`}</span>
          <span>{row.state ? `${row.state},\u00A0` : ''}</span>
          <span>{`${row.zip},\u00A0`}</span>
          <span>{row.country || ''}</span>
        </div>
        {onReset && (
          <button type="button" className={styles.reset} onClick={onReset}>
            Reset
          </button>
        )}
      </div>
    </div>
  );
}

export default CompactOrgAddressView;
