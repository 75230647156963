import cn from 'classnames';
import { useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as MailIcon } from '../../assets/images/icon-input-mail.svg';
import { ReactComponent as PYGIcon } from '../../assets/images/icon-pick-your-gift.svg';
import {
  DELAYED_SHIPPING_EXPIRATION,
  PRE_CREATED_ENGAGEMENT_ID,
  RECEIVER_REMINDER_INTERVAL,
} from '../../constants/bucket';
import { DateFormatsEnum } from '../../constants/date';
import { DELAYED_SHIPPING_EXPIRATION_DATE_HINT } from '../../constants/engagement';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import { endpoints } from '../../constants/routing';
import { DISABLE_EMAIL_NOTIFICATIONS, DISABLE_SENDER_EMAIL_NOTIFICATIONS } from '../../constants/shipping';
import { EMAIL_MESSAGE, EMAIL_SUBJECT, MESSAGE, RECEIVER_EMAIL } from '../../constants/templates';
import { SUPER_ADMIN, USER } from '../../constants/users';
import {
  AdditionalSummaryContainer,
  ReportsShippingDetailsContainer,
  SendShippingDetailsSummaryForm,
} from '../../containers';
import useFetch from '../../hooks/useFetch';
import { addBucketValue } from '../../store/actions/bucket';
import { editEngagementRequest } from '../../store/actions/engagement';
import { updateReportValue } from '../../store/actions/reports';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectIsBusinessReasonRequired,
  selectIsDisabledSenderNotifications,
  selectIsReceiverReminderSettingEnabled,
  selectReminderInterval,
} from '../../store/selectors/bucket';
import { selectFulfillmentCenterById } from '../../store/selectors/fulfillmentCenters';
import { IEditSendFieldRequestPayload, TEngagementCandidate } from '../../types/bucket';
import { EditSendModeEnum, IReportWithCampaignSummaries, ReportStatusEnum, ReportTypesEnum } from '../../types/reports';
import { IReportsRouteParams } from '../../types/routing';
import {
  CopyableLinkTypesEnum,
  IApiError,
  InventorySidebarTypeEnum,
  IResultPriceWithCurrency,
  NotificationListEnum,
} from '../../types/shell';
import { IBulkPayloadProps } from '../../types/shipping';
import { formatDate } from '../../utils/date';
import { hasMskuItem, isItemOnDemand } from '../../utils/inventories';
import notification from '../../utils/notification';
import {
  getReportCopyableInfo,
  getReportStatusAndDate,
  isAwaitingAddress,
  isAwaitingShipment,
  prepareShippingRequestData,
  transformReportAddressToCommonAddress,
} from '../../utils/reports';
import { hasPermission } from '../../utils/users';
import {
  ActionButton,
  CopyableButton,
  ExpirationDateInput,
  Input,
  InputLabel,
  IntervalInput,
  LabelWithEditButton,
  ReportsCopyableButton,
  Textarea,
} from '../forms';
import { DisableEmailNotifications, HelpTooltip, HtmlView, Price, ReportsStatus } from '../index';
import InventorySidebar from '../InventorySidebar/InventorySidebar';
import RecipientDetails from './RecipientDetails/RecipientDetails';

import styles from './SummaryComponent.module.scss';

interface ICommonProps {
  className?: string;
  instance?: TEngagementCandidate | IReportWithCampaignSummaries | null;
  showNote: boolean;
  showEmail: boolean;
  total: IResultPriceWithCurrency;
  isDigital: boolean;
  reportMode: boolean;
  isPYG?: boolean;
  showQuantity?: boolean;
  isEditable?: boolean;
  onEdit?: (mode: EditSendModeEnum) => void;
}

interface IEngagementFlowProps extends ICommonProps {
  SenderInfo: React.ReactNode;
  onSubmit: (...args: any[]) => void;
  type?: never;
}

interface IReportProps extends ICommonProps {
  SenderInfo?: never;
  onSubmit?: never;
  type: ReportTypesEnum;
}

export type TSendSummaryProps = IEngagementFlowProps | IReportProps;

const SendSummaryComponent: React.FC<TSendSummaryProps> = ({
  className,
  instance,
  showNote,
  showEmail,
  total,
  isDigital,
  SenderInfo,
  reportMode,
  isPYG,
  isEditable,
  showQuantity,
  onSubmit,
  onEdit,
  type = ReportTypesEnum.Regular,
}) => {
  const dispatch = useDispatch();

  const { reportId } = useParams<IReportsRouteParams>();

  const receiverReminderInterval = useSelector(selectReminderInterval);
  const isReceiverReminderSettingEnabled = useSelector(selectIsReceiverReminderSettingEnabled);
  const isBusinessReasonRequired = useSelector(selectIsBusinessReasonRequired);
  const isDisabledEmailNotifications = useSelector(selectIsDisabledSenderNotifications);

  const isDisabled = isBusinessReasonRequired && !instance?.[BUSINESS_REASON_ID];
  const adminType = useSelector(selectAdminType);

  const [isShippingReadOnly, setIsShippingReadOnly] = React.useState<boolean>(true);
  const [isShippingDetailsLoading, setIsShippingDetailsLoading] = React.useState<boolean>(false);

  const isDelayedShipping = React.useMemo(() => {
    return isAwaitingAddress(instance as IReportWithCampaignSummaries);
  }, [instance]);

  const shipOrderStatus = useMemo(() => {
    return isDelayedShipping || instance?.delayed_shipping_url ? ReportStatusEnum.AwaitingAddress : '';
  }, [isDelayedShipping, instance?.delayed_shipping_url]);

  const initialShippingValues = useMemo(() => {
    if (!instance) {
      return null;
    }

    return {
      ship_order_status: shipOrderStatus,
      is_receiver_address_fixed: instance.is_receiver_address_fixed,
      org_address_ids: instance.org_address_ids,
      receiver_address: transformReportAddressToCommonAddress(instance),
    };
  }, [instance, shipOrderStatus]);

  const { make: editSendFieldRequest, isLoading: isExpirationDataChanging } = useFetch<
    IEditSendFieldRequestPayload,
    never
  >({
    endpoint: endpoints.editSendField,
    showToastOnError: true,
    method: 'POST',
  });

  const fulfillmentCenterId = React.useMemo(() => instance?.items?.[0]?.fulfillment_center_id, [instance]);
  const fulfillmentCenter = useSelector(selectFulfillmentCenterById(fulfillmentCenterId));

  const isItemsEditDisabledByFC = React.useMemo(() => {
    return fulfillmentCenter?.disable_send_items_edit;
  }, [fulfillmentCenter]);

  const hasMsku = React.useMemo(() => hasMskuItem(instance?.items), [instance]);

  const isSuperAdmin = React.useMemo(() => hasPermission([SUPER_ADMIN], adminType), [adminType]);

  const shipOrderId = React.useMemo(
    () =>
      (instance as IReportWithCampaignSummaries)?.ship_order_id ||
      (instance as IReportWithCampaignSummaries)?.ship_order_id_str,
    [instance],
  );

  const {
    date: dateByStatus,
    label: status,
    color: statusColor,
    dateLabel,
    createdAtDate,
    nextReminderDate,
    onHoldUntilDate,
  } = React.useMemo(() => {
    if (reportMode && instance) {
      return getReportStatusAndDate(instance as IReportWithCampaignSummaries);
    }
    return {
      date: '',
      label: '',
      color: '',
      dateLabel: '',
      createdAtDate: '',
      nextReminderDate: '',
      onHoldUntilDate: '',
      delayedShippingExpirationDate: '',
    };
  }, [instance, reportMode]);

  const additionalForm = React.useMemo(() => {
    return (
      <AdditionalSummaryContainer
        viewMode={reportMode}
        engagement={instance}
        isEditAllowed={!hasPermission([USER], adminType)}
        sendId={reportId}
      />
    );
  }, [reportMode, instance, adminType, reportId]);

  const emailInfo = React.useMemo(() => {
    if (!(instance && showEmail)) {
      return null;
    }

    return (
      <div className={styles.emailInfo}>
        <div className={styles.emailTop}>
          <Input
            className={styles.emailInput}
            helperText="Email"
            icon={<MailIcon />}
            value={instance[RECEIVER_EMAIL]}
            readOnly
          />
          <Input className={styles.emailInput} helperText="Subject" value={instance[EMAIL_SUBJECT]} readOnly />
        </div>
        <div className={styles.emailBottom}>
          <InputLabel value="Email text" />
          <HtmlView
            containerClassname={cn(styles.textareaContainer, styles.htmlViewContainer)}
            html={instance[EMAIL_MESSAGE]!}
          />
        </div>
      </div>
    );
  }, [showEmail, instance]);

  const noteArea = React.useMemo(() => {
    if (!(instance && showNote)) {
      return null;
    }
    return (
      <Textarea
        helperText={
          <LabelWithEditButton
            isEditable={isEditable}
            text="Custom message text"
            onEdit={() => onEdit?.(EditSendModeEnum.Details)}
          />
        }
        className={styles.textareaContainer}
        inputClassName={styles.noteTextarea}
        value={instance[MESSAGE]}
        readOnly
      />
    );
  }, [showNote, instance, isEditable, onEdit]);

  const orderIdsBar = React.useMemo(() => {
    return reportMode ? (
      <div className={styles.sectionBar}>
        <div className={styles.infoBar}>
          <InputLabel value="Order ID" />
          <CopyableButton
            inline
            value={(instance as IReportWithCampaignSummaries)?.order_id}
            className={styles.orderId}
          >
            {(instance as IReportWithCampaignSummaries)?.order_id}
          </CopyableButton>
        </div>
        {(instance as IReportWithCampaignSummaries)?.woocommerce_order_id ? (
          <div className={styles.infoBar}>
            <InputLabel value="WooCommerce ID" containerClassName={styles.idLabel} />
            <CopyableButton
              inline
              value={(instance as IReportWithCampaignSummaries).woocommerce_order_id || ''}
              className={styles.copyableButton}
            >
              {(instance as IReportWithCampaignSummaries)?.woocommerce_order_id}
            </CopyableButton>
          </div>
        ) : null}
        {isSuperAdmin && shipOrderId ? (
          <div className={styles.infoBar}>
            <InputLabel value="Shipment ID" containerClassName={styles.idLabel} />
            <CopyableButton inline value={shipOrderId || ''} className={styles.copyableButton}>
              {shipOrderId}
            </CopyableButton>
          </div>
        ) : null}
      </div>
    ) : null;
  }, [instance, reportMode, isSuperAdmin, shipOrderId]);

  const statusBar = React.useMemo(() => {
    if (!instance || !(type === ReportTypesEnum.Regular || onHoldUntilDate)) {
      return null;
    }
    return (
      <div className={styles.sectionBar}>
        {type === ReportTypesEnum.Regular && (
          <div className={styles.infoBar}>
            <InputLabel value="Status" />
            <ReportsStatus className={styles.status} status={status} backgroundColor={statusColor} />
          </div>
        )}
        {onHoldUntilDate && (
          <div className={styles.infoBar}>
            <InputLabel value="On Hold Until" />
            <span className={styles.date}>{onHoldUntilDate}</span>
          </div>
        )}
      </div>
    );
  }, [instance, status, statusColor, onHoldUntilDate, type]);

  const handleSenderNotificationsChange = React.useCallback(
    (value: boolean | undefined) => {
      dispatch(addBucketValue({ [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: value }));
    },
    [dispatch],
  );

  const handleChangeExpirationDate = React.useCallback(
    (value: string) => {
      if (reportId && value) {
        const report = instance as IReportWithCampaignSummaries;
        editSendFieldRequest({
          body: {
            engagement_id: report?.uid,
            key: DELAYED_SHIPPING_EXPIRATION,
            value: formatDate(value, DateFormatsEnum.End),
          },
        })
          .then(() => {
            dispatch(
              updateReportValue({
                [DELAYED_SHIPPING_EXPIRATION]: formatDate(value, DateFormatsEnum.End),
                id: reportId,
                type,
              }),
            );
          })
          .then(() =>
            notification.success(NotificationListEnum.Success, { content: 'Expiration Date has been updated' }),
          );
      }
    },
    [instance, editSendFieldRequest, type, dispatch, reportId],
  );

  const handleShippingInfoSubmit = React.useCallback(
    (shipping: IBulkPayloadProps) => {
      const preparedShipping = prepareShippingRequestData(shipping);

      return new Promise<IApiError | void>((resolve, reject) => {
        setIsShippingDetailsLoading(true);
        dispatch(
          editEngagementRequest({
            engagement: { shipping_info: preparedShipping, type, engagement_id: reportId! },
            resolve,
            reject,
          }),
        );
      })
        .then((response) => {
          const { message } = (response as IApiError) || {};
          if (message && message?.length > 0) {
            notification.warning(NotificationListEnum.EditEngagement, {
              content: message,
            });
          } else {
            notification.success(NotificationListEnum.EditEngagement, {
              content: 'The send was updated successfully!',
            });
          }
        })
        .catch((error) => {
          notification.error(NotificationListEnum.EditEngagement, {
            content: error?.message ? error.message : 'Something bad has happened. The send edits were not applied.',
          });
        })
        .finally(() => {
          setIsShippingReadOnly(true);
          setIsShippingDetailsLoading(false);
        });
    },
    [dispatch, type, reportId],
  );

  const datesBar = React.useMemo(() => {
    return (
      <div className={styles.sectionBar}>
        <div className={styles.dateItem}>
          <InputLabel value="Created Date" />
          <span className={styles.date}>{createdAtDate || 'n/a'}</span>
        </div>
        {dateByStatus ? (
          <div className={styles.dateItem}>
            <InputLabel value={dateLabel} />
            <span className={styles.date}>{dateByStatus}</span>
          </div>
        ) : null}
        {isDelayedShipping ? (
          <ExpirationDateInput
            className={styles.dateItem}
            onChange={handleChangeExpirationDate}
            value={instance?.delayed_shipping_expiration}
            hint={DELAYED_SHIPPING_EXPIRATION_DATE_HINT}
            isLoading={isExpirationDataChanging}
          />
        ) : null}
      </div>
    );
  }, [
    instance?.delayed_shipping_expiration,
    dateByStatus,
    dateLabel,
    createdAtDate,
    handleChangeExpirationDate,
    isExpirationDataChanging,
    isDelayedShipping,
  ]);

  const reminderDateBar = React.useMemo(() => {
    return nextReminderDate ? (
      <div className={styles.sectionBar}>
        <div className={styles.dateItem}>
          <InputLabel value="Next Reminder Date" />
          <span className={styles.date}>{nextReminderDate}</span>
        </div>
      </div>
    ) : null;
  }, [nextReminderDate]);

  const linksList = React.useMemo(() => {
    if (!instance) {
      return null;
    }

    const copyableInfo = getReportCopyableInfo(instance as IReportWithCampaignSummaries);

    if (!copyableInfo || !copyableInfo.length) {
      return null;
    }

    const EngagementLink: React.FC<{ title: string; url: string }> = ({ title, url, ...restProp }) => {
      return (
        <div className={styles.link} {...restProp}>
          {title && <span className={styles.linkCopyTitle}>{title}</span>}
          <ReportsCopyableButton className={cn(styles.linkCopyButton)} value={url} />
        </div>
      );
    };

    return (
      <div className={styles.infoBar}>
        <InputLabel value="Links" />
        <div className={styles.linksContainer}>
          {copyableInfo.reverse().map(({ data, type: reportLinkType }) => {
            if (!reportMode && reportLinkType === CopyableLinkTypesEnum.DigitalGift) {
              return;
            }

            return Array.isArray(data) ? (
              data.map(({ label, url }) => <EngagementLink key={url} url={url} title={label} />)
            ) : (
              <EngagementLink key={data.url} url={data.url} title={data.label} />
            );
          })}
        </div>
      </div>
    );
  }, [instance, reportMode]);

  const handleChangeReminderInterval = React.useCallback(
    (interval: string | null) => {
      dispatch(addBucketValue({ [RECEIVER_REMINDER_INTERVAL]: interval }));
    },
    [dispatch],
  );

  const isOnDemandItemExist = instance?.items?.some(isItemOnDemand);

  const isForcedDS = useMemo(() => !!(isPYG || hasMsku), [isPYG, hasMsku]);

  const isPYGSidebar = React.useMemo(
    () => isPYG && !instance?.pre_created_engagement_id,
    [isPYG, instance?.pre_created_engagement_id],
  );

  const isInProduction = useMemo(() => isAwaitingShipment(instance as IReportWithCampaignSummaries), [instance]);

  return (
    <div className={cn(styles.summary, className)}>
      <div className={styles.mainContainer}>
        {SenderInfo && <div className={styles.topContainer}>{SenderInfo}</div>}
        {instance && (
          <div className={styles.mainInfo}>
            <RecipientDetails
              instance={instance as IReportWithCampaignSummaries | TEngagementCandidate}
              isDigital={isDigital}
              isEditable={isEditable}
              onEdit={() => onEdit?.(EditSendModeEnum.Details)}
            />
          </div>
        )}
        {!isDigital && instance && (
          <div className={cn(styles.shipping, styles.mainInfo)}>
            {reportMode ? (
              <ReportsShippingDetailsContainer
                value={initialShippingValues}
                isEditable={isEditable}
                isReadOnly={isShippingReadOnly}
                onSubmit={handleShippingInfoSubmit}
                onEdit={() => setIsShippingReadOnly(false)}
                isForcedDS={isForcedDS}
                onCancel={() => setIsShippingReadOnly(true)}
                isLoading={isShippingDetailsLoading}
                isAvailableDS={!isInProduction}
              />
            ) : (
              <SendShippingDetailsSummaryForm instance={instance} />
            )}
          </div>
        )}
        {emailInfo}
        {noteArea}
      </div>
      {instance && (
        <div className={styles.sideContainer}>
          {isReceiverReminderSettingEnabled && !reportMode && (
            <IntervalInput
              value={receiverReminderInterval}
              onChange={handleChangeReminderInterval}
              className={styles.intervalInputContainer}
            />
          )}
          <DisableEmailNotifications
            value={
              reportMode
                ? (instance as IReportWithCampaignSummaries)[DISABLE_EMAIL_NOTIFICATIONS]
                : isDisabledEmailNotifications
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSenderNotificationsChange(e.target.checked)}
            isReadOnly={reportMode}
          />
          {additionalForm}
          {reportMode && (
            <React.Fragment>
              {orderIdsBar}
              {statusBar}
              {datesBar}
              {reminderDateBar}
              {linksList}
            </React.Fragment>
          )}
          <InventorySidebar
            isEditable={isEditable && !isOnDemandItemExist && !isItemsEditDisabledByFC}
            onEdit={() => onEdit?.(EditSendModeEnum.Items)}
            showCount={!reportMode && !showQuantity}
            showQuantity={showQuantity}
            label={
              reportMode ? (
                <div className={styles.pygInfo}>
                  <InputLabel className={styles.pygLabel} value="Send Pack" />
                  {isPYG && <PYGIcon className={styles.pygIcon} />}
                </div>
              ) : (
                <InputLabel value="Your Send" />
              )
            }
            reportMode={reportMode}
            selected={instance[PRE_CREATED_ENGAGEMENT_ID]}
            className={styles.inventorySidebar}
            items={isPYGSidebar ? instance.pick_campaigns : instance.items}
            type={isPYGSidebar ? InventorySidebarTypeEnum.Campaign : InventorySidebarTypeEnum.InventoryItem}
          />
          <div className={styles.pricesBar}>
            <div className={styles.infoBar}>
              <InputLabel
                value={
                  !reportMode && isPYG ? (
                    <React.Fragment>
                      <span>Send cost</span>
                      <HelpTooltip
                        id="instance-cost-tooltip"
                        className={styles.tooltip}
                        contentClassName={styles.tooltipText}
                      >
                        You will be charged for the biggest price and when the user picks his gift the money will be
                        returned.
                      </HelpTooltip>
                    </React.Fragment>
                  ) : (
                    'Item(s) Cost'
                  )
                }
              />
              <Price className={styles.price} value={total} />
            </div>
          </div>
          {!reportMode && (
            <ActionButton
              className={styles.actionButton}
              onClick={onSubmit}
              title="Create Send"
              disabled={isDisabled}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SendSummaryComponent;
