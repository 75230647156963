import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { MAX_REPORTS_PER_PAGE, UID } from '../../constants/reports';
import { IEditEngagementSuccessPayload } from '../../types/bucket';

import {
  IFetchReportByIdResponsePayload,
  IFetchReportsResponsePayload,
  IPopulateReportsCampaignSummariesResponsePayload,
  IReport,
  IReportsState,
  ReportTypesEnum,
} from '../../types/reports';
import { IEngagementOrderIdActionPayload } from '../../types/tools';
import { convertArrayToObject } from '../../utils/helpers';
import { populateReportItemsWithId } from '../../utils/reports';
import * as AuthActions from '../actions/auth';
import * as EngagementActions from '../actions/engagement';
import * as ReportsActions from '../actions/reports';
import * as ToolsActions from '../actions/tools';

export const initialState: IReportsState = {
  [ReportTypesEnum.Regular]: null,
  [ReportTypesEnum.Scheduled]: null,
  isLoading: false,
  isLoadingSingle: false,
  isLoadingEdit: false,
  error: null,
  isFinal: false,
  totalCount: 0,
  download: {
    url: '',
    isLoading: false,
    error: null,
  },
};

const reducer = (state: IReportsState = initialState, action: any): IReportsState => {
  switch (action.type) {
    case ReportsActions.FETCH_REPORTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        isFinal: false,
      };
    }
    case ReportsActions.FETCH_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        [ReportTypesEnum.Regular]: null,
        [ReportTypesEnum.Scheduled]: null,
      };
    }

    case ReportsActions.FETCH_REPORT_BY_ID_FAILURE: {
      return {
        ...state,
        isLoadingSingle: false,
        error: action.payload,
        [ReportTypesEnum.Regular]: null,
        [ReportTypesEnum.Scheduled]: null,
      };
    }

    case ReportsActions.FETCH_REPORTS_SUCCESS: {
      const {
        engagements,
        scheduled_engagements,
        type,
        total_count: totalCount,
      } = (action.payload as IFetchReportsResponsePayload) || {};

      const responseItems = type === ReportTypesEnum.Scheduled ? scheduled_engagements : engagements;
      const isFinal = !responseItems || (Boolean(responseItems.length) && responseItems.length < MAX_REPORTS_PER_PAGE);

      return {
        ...state,
        isLoading: false,
        error: null,
        totalCount,
        [type]:
          responseItems && responseItems.length
            ? convertArrayToObject<IReport, typeof UID>(responseItems.map(populateReportItemsWithId), UID)
            : {},
        isFinal,
      };
    }
    case ReportsActions.FETCH_REPORT_BY_ID_REQUEST: {
      return {
        ...state,
        isLoadingSingle: true,
        error: null,
        isFinal: false,
      };
    }
    case ReportsActions.FETCH_REPORT_BY_ID_SUCCESS: {
      const { engagement, type } = (action.payload as IFetchReportByIdResponsePayload) || {};

      return {
        ...state,
        isLoadingSingle: false,
        error: null,
        [type]: {
          ...(state[type] ? state[type] : {}),
          ...(engagement && engagement[UID] ? { [engagement[UID]]: populateReportItemsWithId(engagement) } : {}),
        },
        isFinal: true,
      };
    }
    case ReportsActions.DOWNLOAD_REPORTS_CSV_REQUEST: {
      return {
        ...state,
        download: {
          url: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case ReportsActions.DOWNLOAD_REPORTS_CSV_SUCCESS: {
      return {
        ...state,
        download: {
          ...state.download,
          url: action.payload.url,
          isLoading: false,
        },
      };
    }
    case ReportsActions.DOWNLOAD_REPORTS_CSV_FAILURE: {
      return {
        ...state,
        download: {
          ...state.download,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case ToolsActions.CANCEL_ENGAGEMENT_BY_ORDER_ID_SUCCESS: {
      const { orderId, type } = (action.payload as IEngagementOrderIdActionPayload) || {};

      const uid = Object.values(state[type] || {}).find((report) => report.order_id === orderId)?.uid || '';

      const { [uid]: excluded, ...included } = state[type] || {};

      if (type === ReportTypesEnum.Scheduled) {
        // Deleting item only if it's a scheduled report
        return {
          ...state,
          [type]: included,
        };
      }

      return state;
    }
    case ToolsActions.DELETE_ENGAGEMENT_BY_ORDER_ID_SUCCESS: {
      // Since we are not overwriting reports during fetches, we should clean deleted reports manually
      const { orderId, type } = (action.payload as IEngagementOrderIdActionPayload) || {};

      const uid = Object.values(state[type] || {}).find((report) => report.order_id === orderId)?.uid || '';

      const { [uid]: excluded, ...included } = state[type] || {};

      return {
        ...state,
        [type]: included,
      };
    }
    case ReportsActions.POPULATE_REPORT_CAMPAIGN_SUMMARIES_REQUEST: {
      return {
        ...state,
        isLoadingSingle: true,
        error: null,
      };
    }
    case ReportsActions.POPULATE_REPORT_CAMPAIGN_SUMMARIES_SUCCESS: {
      const { campaign_summaries, type, reportId } = action.payload as IPopulateReportsCampaignSummariesResponsePayload;

      return {
        ...state,
        isLoadingSingle: false,
        error: null,
        [type]: {
          ...(state[type] ? state[type] : {}),
          ...(state[type]?.[reportId]
            ? {
                [reportId]: {
                  ...state[type]?.[reportId],
                  pick_campaigns: campaign_summaries,
                },
              }
            : {}),
        },
        isFinal: true,
      };
    }
    case ReportsActions.POPULATE_REPORT_CAMPAIGN_SUMMARIES_FAILURE: {
      return {
        ...state,
        isLoadingSingle: false,
        error: action.payload,
      };
    }
    case ReportsActions.UPDATE_REPORT_VALUE: {
      const { type, id, ...value } = action.payload;

      const reportType = type as ReportTypesEnum;

      return {
        ...state,
        [reportType]: {
          ...state[reportType],
          [id]: {
            ...state[reportType]?.[id],
            ...value,
          },
        },
      };
    }
    case EngagementActions.EDIT_ENGAGEMENT_REQUEST: {
      return {
        ...state,
        isLoadingEdit: true,
        error: null,
      };
    }
    case EngagementActions.EDIT_ENGAGEMENT_SUCCESS: {
      const {
        engagement: { type, engagement_id, shipping_info, receiver_info, items_info },
      } = action.payload as IEditEngagementSuccessPayload;

      const updatedEngagement = {
        ...state[type]?.[engagement_id],
        ...shipping_info,
        ...receiver_info,
        ...(items_info ? { items: items_info } : {}),
      };

      return {
        ...state,
        [type]: {
          ...(state[type] ? state[type] : {}),
          [engagement_id]: {
            ...updatedEngagement,
          },
        },
        isLoadingEdit: false,
        error: null,
      };
    }
    case EngagementActions.EDIT_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        isLoadingEdit: false,
        error: action.payload,
      };
    }

    case FirebaseActions.LOGOUT:
    case AuthActions.ADMIN_ROLE_CHANGED:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
