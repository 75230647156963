import { EmailLogoTypesEnum } from '../types/organizations';

export const EDITABLE_TABLE_MAX_ROWS_COUNT = 8;

export const DASHBOARD_ANIMATION_TIME_MS = 125;

export const DIGITAL_BALANCE_LIMIT_DEFAULT_VALUE = 200;
export const DIGITAL_BUDGET_WARNING_RATE = process.env.REACT_APP_DIGITAL_BUDGET_WARNING_RATE
  ? +process.env.REACT_APP_DIGITAL_BUDGET_WARNING_RATE
  : 0;

export const DEPARTMENT_BUDGET_WARNING_RATE = process.env.REACT_APP_DEPARTMENT_BUDGET_WARNING_RATE
  ? +process.env.REACT_APP_DEPARTMENT_BUDGET_WARNING_RATE
  : 0;

export const REQUIRED_FIELD_MESSAGE = 'This field cannot be empty';

export const OUT_OF_DEPARTMENT_MONEY_MESSAGE = 'Insufficient funds on your department account to pick this send.';
export const OUT_OF_MONEY_MESSAGE = 'Insufficient funds on your individual account balance to pick this send.';
export const SOB_OUT_OF_MONEY_MESSAGE =
  'Insufficient funds on selected user individual account balance to pick this send.';
export const CONTACT_ADMIN_MESSAGE = 'Please contact admin for more info.';
export const NO_RESULTS_FOUND_MESSAGE = 'No results found';
export const DIGITAL_BALANCE_HELPER_TEXT =
  "If the customer's digital balance drops to this limit or lower, we will send him an email notification every 3 days.";

export const CONTROLS = 'controls';

export const EMAIL_VALIDATION_REGEX = /^[\w!#$%&'*+-/?^_{|}~]+@[\w.\-]+\.[A-Za-z]{2,60}$/;

export const SQUARE_BRACKETS_EXTRACTOR_REGEX = /\[(.*?)\]/gm;
//#region Shell Theme Constants

export const SHIPPING_PAGE_DEFAULT_HEADER_COLOR = '#212121';
export const SHIPPING_PAGE_DEFAULT_BUTTON_COLOR = '#5f11ea';
export const EMAIL_DEFAULT_LOGO_TYPE = EmailLogoTypesEnum.Rectangular;
export const EMAIL_DEFAULT_MAIN_COLOR = '#212121';
export const EMAIL_DEFAULT_ACCENT_COLOR = '#5f11ea';
export const EMAIL_DEFAULT_USE_ORG_LOGO = false;
export const THEME_DEFAULT_SIDEBAR_COLOR = '#ffffff';
export const THEME_DEFAULT_SIDEBAR_TEXT_COLOR = '#212121';
export const THEME_DEFAULT_ACCENT_COLOR = '#5f11ea';
export const THEME_DEFAULT_USE_ORG_LOGO = false;
export const THEME_DEFAULT_USE_COBRANDING_ORG_LOGO = false;
export const SHIPPING_PAGE_DEFAULT_TITLE = '';
export const SHIPPING_PAGE_DEFAULT_BODY = '';
export const DS_PAGE_DEFAULT_TITLE = 'Hi [Sender.FirstName]! A package is coming your way!';
export const DS_PAGE_DEFAULT_BODY = `<div>We've found almost everyone is working from home, and want to make sure you actually get to enjoy this!<br /><br />Please confirm your preferred ship-to address and your gift will be on its way!<br /><br />[Sender.FirstName] with [Sender.Organization]</div>`;
export const PICK_YOUR_GIFT_DEFAULT_TITLE = '';
export const PICK_YOUR_GIFT_DEFAULT_BODY = '';
export const MSKU_PAGE_DEFAULT_TITLE = '';
export const MSKU_PAGE_DEFAULT_BODY = '';
export const CUSTOMIZE_ITEMS_PAGE_DEFAULT_TITLE = '';
export const CUSTOMIZE_ITEMS_PAGE_DEFAULT_BODY = '';
export const EMAIL_CUSTOM_SENDER_DEFAULT = '';
export const DEFAULT_SQUARE_LOGO_PATH = '/static/images/ie_square_logo.svg';
export const DEFAULT_RECT_LOGO_PATH = '/static/images/ie_rect_logo.svg';

//#endregion

export const BLUEBIRD_SETTINGS_FIRESTORE_COLLECTION = 'bluebird_settings';
export const EMERGENCY_NOTIFICATION_FIRESTORE_DOC_ID = 'emergency_notification';
export const CURRENCY_SETTINGS_FIRESTORE_DOC_ID = 'currency_settings';

export const AI_EXTENSION_DOWNLOAD_URL = process.env.REACT_APP_AI_EXTENSION_URL || 'https://example.com';

export const DEFAULT_TEXT_SLIDER_INTERVAL_MS = 2000;

export const FALLBACK_CURRENCY_CODE = 'USD';

export const NO_CURRENCY = {
  value: '',
  label: 'None',
};
