import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { CUSTOM } from '../../../constants/campaigns';
import { ICampaignShippingConfiguration } from '../../../types/shipping';
import { ActionTypesEnum, TAction } from './actions';

export interface IState {
  isDelayedShipping?: boolean;
  isAddressFixed?: boolean;
  addresses?: string[];
  selectorValue: ShippingFlowSelectorOptionsEnum;
}

export const initialState: IState = {
  selectorValue: ShippingFlowSelectorOptionsEnum.Fixed,
};

export const getInitialState = (value?: Partial<ICampaignShippingConfiguration>): IState => {
  const { is_delayed_shipping, is_receiver_address_fixed, org_address_ids } = value || {};

  return {
    isDelayedShipping: is_delayed_shipping,
    isAddressFixed: is_receiver_address_fixed,
    addresses: org_address_ids,
    selectorValue: (() => {
      const isRecipientDecides = is_delayed_shipping && !is_receiver_address_fixed;
      const isRecipientDecidesFromList =
        is_delayed_shipping && is_receiver_address_fixed && !!(org_address_ids?.length ?? 0 > 1);
      const isFixed =
        !is_delayed_shipping || (is_delayed_shipping && is_receiver_address_fixed && org_address_ids?.length === 1);

      switch (true) {
        case isRecipientDecides:
          return ShippingFlowSelectorOptionsEnum.RecipientDecides;
        case isFixed:
          return ShippingFlowSelectorOptionsEnum.Fixed;
        case isRecipientDecidesFromList:
          return ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList;
        default:
          return ShippingFlowSelectorOptionsEnum.Fixed;
      }
    })(),
  };
};

export const reducer = (state: IState = initialState, action: TAction): IState => {
  switch (action.type) {
    case ActionTypesEnum.changeDS:
      const isNextDelayedShipping = action.payload;
      const isPrevRecipientDecides = state.selectorValue === ShippingFlowSelectorOptionsEnum.RecipientDecides;
      return {
        ...state,
        isDelayedShipping: isNextDelayedShipping,

        ...(isNextDelayedShipping
          ? // validate DS state
            {
              selectorValue: state.selectorValue,
              isAddressFixed: !isPrevRecipientDecides,
              addresses: state.addresses ?? [CUSTOM],
            }
          : // validate !DS flags
            {
              selectorValue: ShippingFlowSelectorOptionsEnum.Fixed,
              isAddressFixed: true,
              addresses: state.addresses?.length === 1 ? state.addresses : [CUSTOM],
            }),
      };
    case ActionTypesEnum.changeSelect:
      const isNewFixed = action.payload === ShippingFlowSelectorOptionsEnum.Fixed;
      const isNewRecipientDecides = action.payload === ShippingFlowSelectorOptionsEnum.RecipientDecides;
      const isNewRecipientDecidesFromList = action.payload === ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList;
      const hasPrevCustomAddress = state.addresses?.includes(CUSTOM);

      return {
        ...state,
        selectorValue: action.payload,
        ...(isNewRecipientDecides
          ? {
              isAddressFixed: false,
              addresses: hasPrevCustomAddress ? undefined : state.addresses,
            }
          : {
              isAddressFixed: true,
              addresses: (() => {
                if (isNewFixed) {
                  return state.addresses?.length === 1 ? state.addresses : [CUSTOM];
                }
                if (isNewRecipientDecidesFromList) {
                  return hasPrevCustomAddress ? undefined : state.addresses;
                }
                // unreachable area
                console.warn("Unreachable area in 'changeSelect' reducer");
                return state.addresses;
              })(),
            }),
      };
    case ActionTypesEnum.changeAB:
      return {
        ...state,
        addresses: action.payload,
      };
    case ActionTypesEnum.reset:
      return initialState;
    default:
      return state;
  }
};

export * from './actions';
